import { useDispatch, useSelector } from "react-redux";

import { set, remove } from "../slice/currentUser";

const useCurrentUser = () => {

    const currentUser = useSelector((state) => state.currentUserStore)

    const dispatch = useDispatch()

    const setCurrentUser = (toSetUser) => {
        dispatch(set(toSetUser))
    }

    const removeCurrentUser = () => {
        dispatch(remove())
    }

    return { currentUser, setCurrentUser, removeCurrentUser }

}

export default useCurrentUser;