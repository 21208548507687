import PropTypes from "prop-types";
import "./FrameComponent.css";

const FrameComponent = ({ className = "" }) => {
  return (
    <div className={`testimonials-section ${className}`}>
      <div className="heading3">
        <img className="review-icon" alt="" src="/review-icon.svg" />
        <div className="what-students-say-about-us-parent">
          <b className="what-students-say">What students say about us</b>
          <img className="seperator-icon" alt="" src="/seperator.svg" />
        </div>
      </div>
      <div className="testimonials SSCTest">
        <div className="testimonial-1">
          <div className="jorem-ipsum-dolor1">
            {`Custom Test feature is the best! Isne meri preparation ko bohot easy
            bana diya! Ab mujhe apne weak topics ya kisi bhi topic ko practice
            karne ke liye PDF dhoondhne ki zarurat nahi padti. Ab main sirf
            subject aur chapter select karke ek quick mini mock bana kar
            practice kar sakti hoon. It has really helped me improve my speed
            and accuracy. Sabse achi baat ye lagi ke saare questions previous
            year papers se hote hain.`}
          </div>
          <div className="user-details">
            <img
              className="user-details-child"
              alt=""
              src="/ellipse-600@2x.png"
            />
            <div className="shalini-jain">Shalini Jain</div>
            <div className="ssc-mts">SSC CGL</div>
          </div>
        </div>
        <div className="testimonial-1">
          <div className="jorem-ipsum-dolor1">
            {`I'm preparing for SSC CGL and I was looking especially for custom
            tests for CGL, I think NioPractice is the one who provides it. Thank
            you for providing such amazing mock tests. The questions of mock
            tests were very well curated. I'll recommend NioPractice to all the
            aspirants who are preparing for any SSC exam and try out their
            Custom Test feature it's amazing.`}
          </div>
          <div className="user-details">
            <img
              className="user-details-child"
              alt=""
              src="/ellipse-601@2x.png"
            />
            <div className="shalini-jain">Ramesh Gupta</div>
            <div className="ssc-mts">SSC CGL</div>
          </div>
        </div>
        <div className="testimonial-1">
          <div className="jorem-ipsum-dolor1">
            {`I can say this is something new and better for SSC CGL students.
            NioPractices Custom Test feature is incredible! It has made my
            preparation so much easier. I no longer need to search for PDFs to
            practice any topics. I can simply select the subject and chapter to
            create a quick mini mock test. This has significantly improved my
            confidence and marks as well. The best part is that all the
            questions are with solution`}
          </div>
          <div className="user-details">
            <img
              className="user-details-child"
              alt=""
              src="/ellipse-602@2x.png"
            />
            <div className="shalini-jain">Kailash Chaurasia</div>
            <div className="ssc-mts">SSC CGL</div>
          </div>
        </div>
      </div>
    </div>
  );
};

FrameComponent.propTypes = {
  className: PropTypes.string,
};

export default FrameComponent;
