import axios from 'axios'

const getallchaptermetadata = async () => {
    const apiUrl = `https://7xb527e9w1.execute-api.ap-northeast-1.amazonaws.com/getAllChapterMEtadata/getallchaptermetadata`

    try {
        const response = await axios.get(apiUrl);
        return response
    } catch {
        throw new Error("An error occurred during the API request.");
    }

}

export default getallchaptermetadata