import React, { useRef, useState, useEffect } from 'react'
import { SpedeVarLanding, SpedeVarLandingCenter, SpedeVarLandingRight } from '../../assets/icons'

function SectionSeven() {

    return (<>
        <div className='headingfiledSection-seven'>
            Ineffective practice is <span className='headingfiledSection-seven headingCostly'>
                costly
            </span>
        </div>
        <div className="section-seven" >
            <div className='section-seven__container'>
                <div className='seven-sectionImages'>
                    <img src={SpedeVarLanding} />
                    {/* dg */}
                </div>
                <h2 className={`section-seven__title  `}>Slow Speed</h2>
                <h3 className="section-seven__sub-title">Not solving problems within the<br /> time limit</h3>
            </div>
            <div className='section-seven__container'>
                <div className='seven-sectionImages'>
                    <img src={SpedeVarLandingRight} />


                    {/* dg */}
                </div>
                <h2 className={`section-seven__title  `}>Memory Fails</h2>
                <h3 className="section-seven__sub-title">Forgetting practiced approaches and <br /> formulaes during exams</h3>
            </div>
            <div className='section-seven__container'>
                <div className='seven-sectionImages'>
                    <img src={SpedeVarLandingCenter} />


                    {/* dg */}
                </div>
                <h2 className={`section-seven__title  `}>Wasted Time</h2>
                <h3 className="section-seven__sub-title">time-consuming practice sets <br /> without improvements</h3>
            </div>

        </div>
    </>
    )
}

export default SectionSeven