import { useState, useEffect } from "react";

function useLocalStorage(key, initialValue = {}) {

    const [LSData, setLSData] = useState(() => {
        const storedValue = localStorage.getItem(key);
        return storedValue ? JSON.parse(storedValue) : initialValue;
    });

    useEffect(() => {
        localStorage.setItem(key, JSON.stringify(LSData));
    }, [key, LSData]);

    const setLocalStorage = (data) => {
        setLSData((prevValue) => {
            let updatedValue;

            if (typeof prevValue === 'object' && !Array.isArray(prevValue)) {
                updatedValue = { ...prevValue, ...data };
            }

            if (Array.isArray(prevValue)) {
                updatedValue = Array.from(new Set([...prevValue, ...data]));
            }

            return updatedValue !== undefined ? updatedValue : data;
        });
    };



    const removeLocalStorage = () => {
        setLSData(initialValue);
    };

    const updateLocalStorage = (keyToUpdate, newValue) => {
        setLSData((prevValue) => {
            const updatedValue = { ...prevValue, [keyToUpdate]: newValue };
            return updatedValue;
        });
    };

    const deleteLocalStorage = (keyId) => {
        //console.log('%c deleting local storage', "color: red")
        localStorage.removeItem(keyId)
    };

    const getLocalStorage = (storageId) => {
        const storedData = localStorage.getItem(storageId)

        if (!storedData) return null;

        return JSON.parse(storedData)
    }

    return {
        LSData, setLocalStorage, removeLocalStorage, updateLocalStorage,
        deleteLocalStorage, getLocalStorage
    };
}

export default useLocalStorage;
