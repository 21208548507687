import { useDispatch, useSelector } from "react-redux";

import { set, setPopUp } from "../slice/signinPopup";

const useSigninPop = () => {
  const currentSigninPopup = useSelector((state) => state.signinModalOpenStore);
  const dispatch = useDispatch();

  const setloginPopVisible = (val) => {
    dispatch(setPopUp(val));
  };

  // const setSelectedChapterID = (tobeUpdateChapter) => {
  //     dispatch(setTime(tobeUpdateChapter))
  // }

  return { currentSigninPopup, setloginPopVisible };
};

export default useSigninPop;
