import { motion, useTransform, useScroll } from "framer-motion";
import { useRef } from "react";
import SectionOne from "./SectionOne";
import SectionTwo from "./SectionTwo";
import SectionThree from "./SectionThree";

const Example = () => {
    return (
        // <div className="bg-neutral-800">
        <div className="unFoldingErosion unFoldingErosionMargin">
            {/* <div className="flex h-48 items-center justify-center">
                <span className="font-semibold uppercase text-neutral-500">
                    Scroll down
                </span>
            </div> */}
            <HorizontalScrollCarousel />
            {/* <div className="flex h-48 items-center justify-center">
                <span className="font-semibold uppercase text-neutral-500">
                    Scroll up
                </span>
            </div> */}
        </div>
    );
};

const HorizontalScrollCarousel = () => {
    const targetRef = useRef(null);
    const { scrollYProgress } = useScroll({
        target: targetRef,
    });

    const x = useTransform(scrollYProgress, [0, 1], ["1%", "-62%"]);

    return (
        <section ref={targetRef} className="relative h-[300vh] ">
            <div className="sticky top-0 flex h-screen items-center overflow-hidden">
                <div className={`headEightSection  originalHead headindSections headerDats`}>
                    <div className='HeadingTextSection-eight originalHead'>
                        Use &nbsp;
                        <span style={{ color: 'var(--accent-primary-clr)' }}>NioPractice</span>&nbsp;
                        Today!
                    </div>
                </div>
                {/* <div className=" items-center headindSections"> */}
                {/* hiii */}
                {/* </div> */}
                <motion.div style={{ x }} className="flex gap-4 gapperData">
                    {cards.map((card) => {
                        return <Card card={card} key={card.id} />;
                    })}
                </motion.div>
            </div>
        </section>
    );
};

const Card = ({ card }) => {
    return (
        <div
            key={card.id}
            className="group relative h-[100vh] w-[90vw] overflow-hidden centerizedTheCard  "
        >


            {/* <div
                style={{
                    backgroundImage: `url(${card.url})`,
                    backgroundSize: "cover",
                    backgroundPosition: "center",
                }}
                className="absolute inset-0 z-0 transition-transform duration-300 group-hover:scale-110"
            ></div>
            <div className="absolute inset-0 z-10 grid place-content-center">
                <p className="bg-gradient-to-br from-white/20 to-white/0 p-8 text-6xl font-black uppercase text-white backdrop-blur-lg">
                    {card.title}
                </p>
            </div> */}
            {/* <SectionThree /> */}
            {card.id === 1 && <SectionOne />}
            {card.id === 2 && <SectionTwo />}
            {card.id === 3 && <SectionThree />}
        </div>
    );
};

export default Example;

const cards = [
    {
        url: "/imgs/abstract/1.jpg",
        title: "Title 1",
        id: 1,
    },
    {
        url: "/imgs/abstract/2.jpg",
        title: "Title 2",
        id: 2,
    },
    {
        url: "/imgs/abstract/3.jpg",
        title: "Title 3",
        id: 3,
    }

];