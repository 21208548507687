import React from "react";
// import { ThemeProvider } from "@mui/material/styles";
import { createRoot } from "react-dom/client";
// import CssBaseline from "@mui/material/CssBaseline";
import reportWebVitals from "./reportWebVitals";
// import theme from "./theme";
import App from "./App";
import "./index.css";

const rootElement = document.getElementById("root")!;
const root = createRoot(rootElement);

// Conditionally import CSS based on URL
if (!window.location.href.toLowerCase().includes("careerlauncher")) {
  // import("./index.css");
} else {
  import("./colourIndexCL.css");
}

root.render(
  // <ThemeProvider theme={theme}>
  <div>
    {/* <CssBaseline /> */}
    <App />
  </div>
  // </ThemeProvider>
);

reportWebVitals();
