import React from 'react';
import useScrollToTop from '../../hooks/useScrollToTop';

import Footer from '../../components/footer/Footer';

import '../misc.css';

function TermsAndConditions() {
    useScrollToTop()

    return (
        <>

            <div className='terms-and-conditions misc'>
                <div className='misc-header'>
                    <div className='misc-inner-container'>
                        <h1 className=' misc-title'> Terms and Conditions</h1>
                        <div className=' misc-updated-date'> Last updated: January 18, 2023 </div>
                    </div>
                    <div className='app-horizontal-line' />
                </div>

                <h2 className='misc-sub-title'>
                    These Terms and Conditions ("Terms") govern your use of the website [niopractice.com] ("Website"), provided by [Nioclass] ("we," "us," or "our"). By accessing or using the Website, you agree to be bound by these Terms. If you do not agree with these Terms, please refrain from using the Website.
                </h2>

                <div className='misc-paragraph-container'>
                    <h2 className='misc__paragraph-title'> Use of the Website</h2>
                    <p className='misc__paragraph'> A. You must be at least 13 years old to use the Website. If you are under 13 years old, you must obtain parental consent before accessing or using the Website. </p>
                    <p className='misc__paragraph'> B. You are solely responsible for maintaining the confidentiality of any login credentials or account information associated with the Website. </p>
                    <p className='misc__paragraph'>C. You agree to use the Website only for lawful purposes and in compliance with all applicable laws and regulations.  </p>
                </div>

                <div className='misc-paragraph-container'>
                    <h2 className='misc__paragraph-title'>User Conduct </h2>
                    <p className='misc__paragraph'> A. You agree not to engage in any activity that may interfere with or disrupt the functioning of the Website or compromise its security. </p>
                    <p className='misc__paragraph'> B. You agree not to upload, post, or transmit any content that is illegal, infringing, harmful, or violates the rights of others. </p>
                    <p className='misc__paragraph'> C. You agree not to engage in any unauthorized access or use of the Website or any associated systems, networks, or data. </p>
                </div>

                <div className='misc-paragraph-container'>
                    <h2 className='misc__paragraph-title'> Intellectual Property </h2>
                    <p className='misc__paragraph'> A. The Website and all its contents, including but not limited to text, graphics, images, logos, and software, are the property of [Nioclass] or its licensors and are protected by intellectual property laws. </p>
                    <p className='misc__paragraph'> B. You may not reproduce, distribute, modify, transmit, or otherwise exploit any content from the Website without prior written permission from [Nioclass]. </p>
                </div>

                <div className='misc-paragraph-container'>
                    <h2 className='misc__paragraph-title'> Third-Party Links</h2>
                    <p className='misc__paragraph'> A. The Website may contain links to third-party websites or resources. These links are provided for your convenience, and we have no control over the content or practices of these third-party sites. </p>
                    <p className='misc__paragraph'> B. We are not responsible for the availability, accuracy, or reliability of any third-party websites or resources linked to the Website. Your use of such third-party websites is at your own risk. </p>
                </div>

                <div className='misc-paragraph-container'>
                    <h2 className='misc__paragraph-title'> Disclaimer of Warranties </h2>
                    <p className='misc__paragraph'> A. The Website and its content are provided on an "as-is" and "as available" basis. We make no warranties or representations regarding the accuracy, completeness, or reliability of the content. </p>
                    <p className='misc__paragraph'> B. We disclaim all warranties, whether express or implied, including but not limited to warranties of merchantability, fitness for a particular purpose, and non-infringement. </p>
                </div>

                <div className='misc-paragraph-container'>
                    <h2 className='misc__paragraph-title'> Limitation of Liability</h2>
                    <p className='misc__paragraph'> A. In no event shall [Nioclass] or its affiliates be liable for any direct, indirect, incidental, special, or consequential damages arising out of or in connection with your use of the Website. </p>
                    <p className='misc__paragraph'> B. [Nioclass] shall not be liable for any damages or losses resulting from any interruption, suspension, or termination of the Website or your access to it. </p>
                </div>

                <div className='misc-paragraph-container'>
                    <h2 className='misc__paragraph-title'> Indemnification</h2>
                    <p className='misc__paragraph'> You agree to indemnify, defend, and hold [Nioclass] and its affiliates, directors, officers, employees, and agents harmless from any claims, liabilities, damages, losses, costs, or expenses arising out of or in connection with your use of the Website or violation of these Terms. </p>
                </div>

                <div className='misc-paragraph-container'>
                    <h2 className='misc__paragraph-title'>Modifications to the Website and Terms </h2>
                    <p className='misc__paragraph'> We reserve the right to modify, suspend, or discontinue the Website or any part thereof at any time without notice. We may also revise these Terms from time to time by updating this page. Your continued use of the Website after any modifications constitutes your acceptance of the revised Terms. </p>
                </div>

                <div className='misc-paragraph-container'>
                    <h2 className='misc__paragraph-title'> Governing Law and Jurisdiction</h2>
                    <p className='misc__paragraph'> These Terms shall be governed by and construed in accordance with the laws of [Jurisdiction]. Any legal action or proceeding arising out of or in connection with these Terms shall be brought in the courts of [Jurisdiction]. </p>
                </div>

                <div className='misc-paragraph-container'>
                    <h2 className='misc__paragraph-title'> Severability</h2>
                    <p className='misc__paragraph'> If any provision of these Terms is held to be invalid, illegal, or unenforceable, the remaining provisions shall continue in full force and effect. </p>
                </div>

                <div className='misc-paragraph-container'>
                    <h2 className='misc__paragraph-title'>Entire Agreement </h2>
                    <p className='misc__paragraph'> These Terms constitute the entire agreement between you and [Nioclass] regarding your use of the Website and supersede any prior agreements or understandings. </p>
                </div>

                <div className='misc-paragraph-container'>
                    <h2 className='misc__paragraph-title'> If you have any questions or concerns about these Terms, please contact us at m@nioclass.com. </h2>
                </div>

                <p className='misc-footnote'> By using the Website, you signify your acceptance of these Terms. If you do not agree to these Terms, please refrain from using the Website. </p>

            </div>

            <Footer />
        </>
    )
}

export default TermsAndConditions