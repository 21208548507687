import React from 'react';

import './tool-tip.css'

function ToolTip(props) {

    const { text, right, left, top, horizontal } = props;

    return (
        <div className={`tool-tip
         ${right ? "right-side" : ""}
         ${left ? 'left-side' : ''} 
         ${top ? 'top-side' : ''} 
         ${horizontal ? 'horizontal' : ''}`}>
            {text}
            <div className='tool-tip__corner' />
        </div>
    )
}

export default ToolTip