import React, { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { signInWithPopup, FacebookAuthProvider } from "firebase/auth";

import useLocalUserData from "../../localStorage/useLocalUserData";
import useCurrentUser from "../../redux/control/useCurrentUser";

import {
  authUser,
  fbProvider,
  googleProvider,
} from "../../context/FirebaseContext";

import createUser from "./createUser";
import checkUserExist from "./checkUserExists";
import checkUserExistReturn from "./checkUserExistreturn";
import { fbIcon, googleIcon } from "../../assets/icons";
import axios from "axios";

function QuickSignUp({
  referredBy,
  phoneNumber,
  setUser,
  utmCompaignD,
  subjectualData,
}) {
  // console.log(phoneNumber)

  const navigate = useNavigate();

  const { setCurrentUser } = useCurrentUser();
  const { createLocalUserData } = useLocalUserData();

  const handleFacebookLogin = () => {
    signInWithPopup(authUser, fbProvider)
      .then((res) => {
        // console.log("RES: ", res);
        //unClear abhi
        setUserName(res.user);
        const credential = FacebookAuthProvider.credentialFromResult(res);
        const accessToken = credential.accessToken;
      })
      .catch((err) => {
        console.log("Fb error: ", err);
      });
  };
  async function updateCreditPoints(referalbyUserId) {
    try {
      await axios.get(
        "https://lkbe8ay3y7.execute-api.ap-northeast-1.amazonaws.com/updateUserCredits/updateusercredits?UserID=" +
          referalbyUserId +
          "&CreditPoints=1",
        {
          headers: { "Content-Type": "application/x-www-form-urlencoded" },
        }
      );
    } catch (e) {
      console.log("caught err" + e.message);
    }
  }
  function checkUserExistReturnss(referalbyUserId) {
    try {
      axios
        .get(
          `https://uhuu9h6pef.execute-api.ap-northeast-1.amazonaws.com/getUserProfileDetails/getuserprofiledetails?UserID=${referalbyUserId}&abc=abc`,
          {
            headers: { "Content-Type": "application/x-www-form-urlencoded" },
          }
        )
        .then((resp) => {
          let data = resp.data;
          // console.log(resp.data)
          setCurrentUser(data[0]);

          //add user credential to Local Storage - data[0]
          createLocalUserData(data[0]);

          return data?.length > 0 ? true : false;
        })
        .catch((err) => {
          console.log(err);
        });
    } catch (e) {
      console.log("caught err" + e.message);
    }
  }
  useEffect(() => {
    checkUserExistReturnss(referredBy);
  }, [referredBy]);
  // console.log(userExistsAlready)
  const handleGoogleLogin = () => {
    signInWithPopup(authUser, googleProvider)
      .then(async (res) => {
        // console.log(res);
        // setUserName(res.user.displayName);
        // setUserId(res.user.email);
        console.log(res.user.email);
        const userExists = await checkUserExistReturn(
          '"' + res.user.email + '"',
          setCurrentUser,
          createLocalUserData
        );
        // const userExists = checkUserExistReturnss(('"' + res.user.email + '"'));
        // console.log(await checkUserExistReturn(res.user.email, setCurrentUser, createLocalUserData))
        // const userExists = await checkUserExist(res.user.displayName, res.user.email);
        // console.log(await checkUserExist(res.user.email, setCurrentUser, createLocalUserData))
        // console.log(userExists)
        // console.log(userExists, userExistsAlready, referredBy)
        // console.log(userExists)

        // testerrrrr
        try {
          if (referredBy) {
            if (!userExists) {
              updateCreditPoints(referredBy);
            }
          }
          setUser('"' + res.user.email + '"');
          if (localStorage.getItem("UserID")) {
            axios
              .get(
                "https://yec5cxedkh.execute-api.ap-northeast-1.amazonaws.com/updateUserIDwithTables?NewUserID=" +
                  '"' +
                  res.user.email +
                  '"' +
                  "&OldUserID=" +
                  localStorage.getItem("UserID")
              )
              .then((res) => {})
              .catch((err) => console.log(err));
          }

          if (!userExists) {
            // console.log("inner");

            createUser(
              res.user.displayName,
              '"' + res.user.email + '"',
              phoneNumber,
              referredBy,
              localStorage.getItem("UtmCampaign")?.length > 2
                ? localStorage.getItem("UtmCampaign")
                : utmCompaignD
            )
              .then(() => {
                let user_id = '"' + res.user.email + '"';
                if (
                  window.location.pathname.includes("website") ||
                  location.pathname.includes("contact-us")
                ) {
                  if (subjectualData?.navbar?.includes("chapters")) {
                    navigate(
                      "/chapter-selection" +
                        "?&" +
                        subjectualData?.DropSubjects[0] +
                        "&Test=yes",
                      { state: { UserID: user_id } }
                    );
                  } else {
                    navigate(`/${subjectualData?.navbar[0]}`, {
                      state: { UserID: user_id },
                    });
                  }
                  localStorage.setItem("UserID", '"' + res.user.email + '"');
                }
              })
              .catch((err) => alert("please login after sometime"));
          } else {
            // navigate("/dashboard");
            localStorage.setItem("UserID", '"' + res.user.email + '"');
          }
          // localStorage.setItem("UserID", '"' + res.user.email + '"');
          // window.reload()
        } catch {
          alert("Something went wrong, Please try Again...");
        }

        //ends
        // console.log("outerrrr")
        // navigate('/dashboard');
      })
      .catch((err) => {
        console.log("google error: ", err);
      });
  };

  return (
    <div className="sign-up-quick">
      {/* <b></b> */}
      <div className="emptyDivSign_in_height" />

      <div className="quick-sign-up-otps">
        <div
          className="sign-up-google sign-up-btn googleLoginStrokeBorder"
          style={{ boxShadow: "0px 0px 5px 1px" }}
        >
          <button onClick={handleGoogleLogin}>
            <img src={googleIcon} alt="google" />
            <div>Continue with Google</div>
          </button>
        </div>
        {/* <div className="sign-up-fb sign-up-btn">
                    <button onClick={handleFacebookLogin}>
                        <img src={fbIcon} alt="facebook" />
                        Facebook
                    </button>
                </div> */}
      </div>
    </div>
  );
}

export default QuickSignUp;
