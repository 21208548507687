import React from 'react'
import "./downloadapp.css"
import { closeBtnIcon, nioLogo } from '../../assets/icons'
function DownloadTheApp({ visibility, setVisibility }) {
    const handleSetTheVisiBiliTYDRAWN = (e) => {
        setVisibility(false)
    }
    const handleClickAppClick = () => {
        // console.log("Clicking");
        window.location.href = "https://play.google.com/store/apps/details?id=com.niopractice.prodMob";
    };


    return (<>
        <div className='DownLoadAppContainerFixed'>
            <div className='DownLoadAppContainerFixedlogoCont'>
                <img className='DownLoadAppContainerFixedlogoCont logoValue' src={nioLogo} />
            </div>
            <div className='AppTextDownCOnt'>
                Download The App
                <br />
                <span className='AppTextDownCOntSubText'>

                    Get the Best Experience
                </span>
            </div>
            <button onClick={handleClickAppClick} className="app-btn-1" name="btn-start-practice"
                style={{ fontSize: "12px" }} >
                Get The App
            </button>
            <div onClick={handleSetTheVisiBiliTYDRAWN}>

                <img className="closeBtnIconTextDown" src={closeBtnIcon} alt="" />
            </div>
        </div>
    </>
    )
}

export default DownloadTheApp