import React, { useState } from "react";

import { useNavigate } from "react-router-dom";

import { getFbAuth } from "../../context/FirebaseContext";
import useRecordActivity from '../../hooks/useRecordActivity';

import {
    instagramIcon, whatsappIcon, linkedInIcon, facebookIcon,
} from "../../assets/icons";

import "./footer.css";

const Footer = () => {
    const navigate = useNavigate()

    const authUser = getFbAuth().currentUser;
    const recordActivity = useRecordActivity(authUser?.phoneNumber)

    const [subInputValue, setSubInputValue] = useState('')

    const handleSocialClick = (e) => {
        const targetName = e.target.name
        recordActivity('click', targetName, "footer")
    }

    const handleLinkClick = (destinationPath, e) => {
        navigate(destinationPath)
        const targetName = e.target.name
        recordActivity('click', targetName, "footer")
    }

    const handleSubscriptionField = (e) => {
        const val = e.target.value
        setSubInputValue(val)
    }

    const handleSubcribeBtn = (e) => {
        setSubInputValue('')
        const targetName = e.target.name
        recordActivity('click', targetName, "footer")
    }

    return (
        <footer className="footer">
            <div className="section-container">
                <div className="section">
                    <div>
                        <h3 className="title">Nioclass Pvt Ltd</h3>
                        <p className="subtitle">
                            Optimising student learning.
                        </p>
                    </div>

                    <div className="footer__social-media">
                        <a href="https://www.facebook.com/profile.php?id=100081858384824" target="_blank" rel="noreferrer">
                            <button className="facebookLink" name="ex-link-sm-facebook" onClick={handleSocialClick}>
                                <img src={facebookIcon} alt="nioclass" name="ex-link-sm-facebook" />
                            </button>
                        </a>

                        <a href="https://wa.me/+919650052472" target="_blank" rel="noreferrer">
                            <button className="WhatsAppLink" name="ex-link-sm-whatsapp" onClick={handleSocialClick}>
                                <img src={whatsappIcon} alt="nioclass" name="ex-link-sm-whatsapp" />
                            </button>
                        </a>

                        <a href="https://www.linkedin.com/company/nioclass" target="_blank" rel="noreferrer">
                            <button className="linkedInLink" name="ex-link-sm-linkedin" onClick={handleSocialClick}>
                                <img src={linkedInIcon} alt="nioclass" name="ex-link-sm-linkedin" />
                            </button>
                        </a>

                        <a href="https://www.instagram.com/niopractice/?igshid=YmMyMTA2M2Y%3D" target="_blank" rel="noreferrer">
                            <button className="instagramLink" name="ex-link-sm-instagram" onClick={handleSocialClick}>
                                <img src={instagramIcon} alt="nioclass" name="ex-link-sm-instagram" />
                            </button>
                        </a>
                    </div>
                </div>

                <div className="section">
                    <h3 className="title">Company</h3>
                    <div className="footer__inner-container">
                        <button className="subtitle" name="btn-about-us"
                            onClick={(e) => handleLinkClick("/about-us", e)}
                        >
                            About Us
                        </button>
                        <button className="subtitle" name="btn-blog"
                            onClick={(e) => handleLinkClick("/blog", e)}>
                            Blogs
                        </button>
                        <button
                            className="subtitle" name="btn-privacy-policy"
                            onClick={(e) => handleLinkClick("/privacy-policy", e)}
                        >
                            Privacy Policy
                        </button>
                        <button
                            className="subtitle" name="btn-terms-and-conditions"
                            onClick={(e) => handleLinkClick("/terms-and-conditions", e)}
                        >
                            Terms and Conditions
                        </button>
                    </div>
                </div>

                <div className="section">
                    <h3 className="title">Help</h3>
                    <div className="footer__inner-container">
                        <button className="subtitle" name="btn-site-map"
                            onClick={(e) => handleLinkClick("/sitemap", e)}>
                            Site Map
                        </button>
                        <button className="subtitle" name="btn-faqs"
                            onClick={(e) => handleLinkClick("/faq", e)}>
                            FAQ'S
                        </button>
                        <button
                            className="subtitle" name="btn-refund-policy"
                            onClick={(e) => handleLinkClick("/cancellation-and-refund-policy", e)}>
                            Refund Policy
                        </button>
                        <button className="subtitle" name="btn-pricing-policy"
                            onClick={(e) => navigate("/pricing-policy", e)}>
                            Pricing
                        </button>
                    </div>
                </div>

            </div>

            <div className="section newsletter">
                <h2 className="title newsletter-subscribe">  Subscribe to our Newsletter </h2>
                <div className="footer__inner-container">
                    <input
                        type="email"
                        className="footer__newsletter__input"
                        placeholder="Enter Your Email"
                        name="input-subscription"
                        value={subInputValue}
                        onChange={handleSubscriptionField}
                        onBlur={(e) => recordActivity('write', e.target.name, "footer")}
                    />
                    <button className="app-btn-1 newsletter-subscribe-btn" name="btn-subscribe"
                        onClick={handleSubcribeBtn}
                    >Subscribe</button>
                </div>
            </div>

        </footer>
    );
};

export default Footer;
