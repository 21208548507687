import React, { useState, useEffect, useRef } from 'react';
import ChatBox from './ChatBox';

import useHandleMessage from './useHandleMessage';

import "./chat-box.css"

function ChatBot() {

    const msgBoxRef = useRef()

    const chatRef = useRef()
    const botTypeRef = useRef()
    const userTypeRef = useRef()

    const { handleMessage, data, loading, error } = useHandleMessage()

    const [messages, setMessages] = useState([]);
    const [isBotTyping, setIsBotTyping] = useState(false);
    const [isUserTyping, setIsUserTyping] = useState(false);

    const handleBotMessage = async (userQuery) => {

        const res = await handleMessage(userQuery);

        // console.log(userQuery)

        const botMessage = {
            text: res.message,
            isUserMessage: false
        };

        setMessages([...messages, botMessage]);

    }

    const handleUserMessage = (message) => {
        const userMessage = {
            text: message,
            isUserMessage: true
        };

        setMessages([...messages, userMessage]);

        handleBotMessage(message)
    };

    const chatScroll = () => {
        // console.log("chat scroll")

        if (chatRef.current) {
            chatRef.current.scrollIntoView({ behavior: 'smooth' });
        }

        if (botTypeRef.current) {
            botTypeRef.current.scrollIntoView({ behavior: 'smooth' });
        }

        if (userTypeRef.current) {
            userTypeRef.current.scrollIntoView({
                behavior: 'smooth',
                block: 'end',
                inline: 'nearest'
            });
        }
    }

    const handleMsgBoxScroll = () => {
        msgBoxRef.current.scrollTop += 200;
    }

    useEffect(() => {
        if (messages.length > 100) return
        setIsBotTyping(true)
        const intervalId = setInterval(() => {
            // handleBotMessage();
            setIsBotTyping(false)
        }, 5000);

        return () => {
            clearInterval(intervalId);
        };
    }, [messages]);

    useEffect(() => {
        chatScroll()
    }, [chatRef.current,
    botTypeRef.current,
    userTypeRef.current,
        messages,
        isUserTyping])

    return (
        <div className='chat-bot'>
            <h1 className='chat-bot__title'> Chatting with Nio </h1>
            <div className='chat-bot__msg-box' ref={msgBoxRef}>
                {
                    messages.map((msg, idx) => (
                        <div ref={chatRef}
                            key={idx}
                            className={`chat-bot__chat ${msg.isUserMessage ? "user-chat" : "bot-chat"}`}
                        >
                            <div>{msg.text} </div>
                        </div>
                    ))
                }

                {isBotTyping && <div ref={botTypeRef}
                    className="chat-bot__typing-state bot-typing" >
                    bot is typing...
                </div>}

                {isUserTyping && <div ref={userTypeRef}
                    className="chat-bot__typing-state user-typing" >
                    user is typing...
                </div>}
            </div>

            <ChatBox
                handleMessage={handleUserMessage}
                setIsUserTyping={setIsUserTyping}
                handleMsgBoxScroll={handleMsgBoxScroll} />

        </div>
    )
}

export default ChatBot;