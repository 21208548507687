import React from 'react'
import SectionElevenFunction from './SectionElevenFunction'

function SectionEleven() {
    const dataValue = [{ Question: "How does the product work?", Answer: `The traditional pen-and-paper approach to problem-solving is Time-consuming and frustrating. NioPractice's technology offers a new way to practice problems digitally. It focuses on the "approach" by removing calculations, it helps student save time and quickly develop the right skills. It's algorithm keeps track of a student's learning and memory, and recommends steps to master problem-solving.` },
    { Question: "Will NioPractice increase my marks?", Answer: `Yes. NioPractice has shown to increase marks, on average, by over 34%.` },
    { Question: "I don’t have  time to do anything new. Will NioPractice still be useful?", Answer: `One of the main benefits of NioPractice is that is saves time. On average, students save  45 minutes per day. ` },
    { Question: "I already have practice tests / series. Will NioPractice add value?", Answer: `Tests are necessary and useful. But they are not meant for effective daily practice. To master problem-solving, students need to know the right approach, experience a wide range of problems, and maintain consistent revision. NioPractice accomplishes this.` }]

    return (
        <>
            <div style={{ marginBottom: "4em" }}>

                <div className='headEightSection section-9' style={{ marginBottom: "4em" }}>
                    <div className='HeadingTextSection-eight'>
                        FAQs
                    </div>
                </div>
                <div className='sectionElevenQuestionBarContainer'>

                    {dataValue.map((obj,index) => (
                        <SectionElevenFunction obj={obj} key={index}/>

                    ))}
                </div>
            </div>
        </>
    )
}

export default SectionEleven