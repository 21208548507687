import React, { useState } from "react";

import "./toast.css";

export function useToast() {
  const [msg, setMsg] = useState("");
  const [clr, setClr] = useState("");
  const [iconUrl, setIconUrl] = useState("");
  const [isToastDisplayed, setIsToastDisplayed] = useState(false);
  const [animDuration, setAnimDuration] = useState("");

  const Toast = () => {
    const color = {
      red: "red",
      green: "lightgreen",
      pink: "#f8219f",
      yellow: "yellow",
    };

    if (!msg) return null;

    return (
      <div
        style={{
          "--anim-duration": `${animDuration}s`,
          borderColor: color[clr],
          color: color[clr],
        }}
        className={`toast-div ${msg ? "show" : "no-show"}`}
      >
        <b>{msg}</b>

        {iconUrl && <img src={iconUrl} className="toast-div__icon-img" />}
      </div>
    );
  };

  const clearToast = (timer) => {
    setTimeout(() => {
      setMsg("");
      setClr("");
      setIsToastDisplayed(false);
    }, timer * 1000);
  };

  const setToast = (m, options = {}) => {
    if (!isToastDisplayed) {
      setIsToastDisplayed(true);

      setMsg(m);

      if (options.color) setClr(options.color);

      if (options.timer) {
        setAnimDuration(options.timer);
        clearToast(options.timer + 0.5);
      } else clearToast(1.5);

      if (options.iconUrl) setIconUrl(options.iconUrl);
    }
  };

  return { Toast, setToast };
}

export default useToast;
