import React from "react";

import { desktopMobileNioImg } from "../../assets/images";
import {
  laptopMobileImage,
  appStoreImage,
  googlePlayLinkImg,
  appStoreLinkImg,
} from "../../assets/icons";

function SectionFive({ handleClickStartPracticising }) {
  const handleClickAppClick = () => {
    // console.log("Clicking");
    window.location.href =
      "https://play.google.com/store/apps/details?id=com.niopractice.prodMob";
  };

  return (
    <div className="sectionfive">
      <div className="sectionfive__outer-container">
        <div className="sectionfive__container">
          <div className="sectionfive__img1-container">
            <img
              src={desktopMobileNioImg}
              onResize={"contain"}
              className="sectionfive__img1"
              alt="landing-img-5"
            />
          </div>

          <div className="sectionfive__inner-container">
            <h2 className="sectionfive__title">
              {" "}
              Available across all platforms!{" "}
            </h2>

            {/* <a href="https://forms.gle/mfG6owfoYx31jXgn9" target='_'>
                            <button

                                className="app-btn-1 sectionfive__btn"
                            >
                                GET ACCESS!
                            </button>
                        </a> */}

            <div>
              <p className="app-faded-clr">
                Mobile Application is coming soon to help you
              </p>
              <p className="">
                <b>Study at your convenience!</b>
              </p>
            </div>
          </div>

          <div className="sectionfive__app-link-container">
            <button
              className="sectionfive__app-link"
              onClick={handleClickAppClick}
            >
              <img
                className="sectionfive__img2"
                src={googlePlayLinkImg}
                alt="landing-img-5"
              />
            </button>

            <button
              className="sectionfive__app-link"
              onClick={handleClickAppClick}
            >
              <img
                className="sectionfive__img2"
                src={appStoreLinkImg}
                alt="landing-img-5"
              />
            </button>
          </div>
        </div>
      </div>
    </div>
  );
}

export default SectionFive;
