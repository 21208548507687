import React, { useState, useEffect } from "react";




import { closeBtnIcon, underScoreIcon } from "../../../assets/icons";


import "../../chapter/styles/explanation-modal.css";
import ToolTip from "../../ui/tooltip/ToolTip";
import useDelayedStateToggle from "../../../hooks/useDelayStateToggle";
import BackDrop from "../../ui/backdrop/BackDrop";
import SyncLoader from "react-spinners/SyncLoader";

function NameData(props) {
    const {
        handleLogoutOtherDevice, setFormsOpen, onHandleChangeName,setClosePermanently=()=>{}
    } = props;


    const [isCloseBtnHover, setIsCloseBtnHover] = useState(false);
    const [loader, setLoader] = useState(false)

    const [toggleCloseBtnHover, toggleCloseBtnHoverfalse] =
        useDelayedStateToggle(setIsCloseBtnHover);



    return (
        <>
            <div className="explanation-modal-container" >

                <div className="explanation-modal-top">

                    <h1 className="stepexplanationheading">What Should we call you?<br />
                    </h1>

                    <button
                        className="close-explanation-modal-btn"
                        onMouseOver={toggleCloseBtnHover}
                        onMouseOut={toggleCloseBtnHoverfalse}
                        onClick={() => {
                            setFormsOpen(false),setClosePermanently(true)
                        }}
                    >
                        <img src={closeBtnIcon} alt="" />

                        {isCloseBtnHover && <ToolTip left text="Close" />}
                    </button>


                </div>

                <img src={underScoreIcon} alt="separatorIcon" className="app-height-3" />
                <div style={{ display: "flex", flexDirection: "column", justifyContent: "center", alignItems: "center", padding: "1em", gap: "0.5em" }}>

                    <h1 className="stepexplanationheading">
                        <input
                            className="nameInputModalValue"
                            placeholder="Enter Your Name"
                            onChange={(e) => onHandleChangeName(e)}
                        />
                    </h1>

                    {/* <MentorShipForm setFormsOpen={setFormsOpen} Plan={Plan} /> */}
                    {/* <span style={{ fontSize: "22px" }}>
                        Log-out from All Devices
                    </span> */}
                    {!loader ? <button
                        style={{
                            // border: "2px solid var(--icon-primary-clr)",
                            marginTop: "1em",
                            // height: "2em"
                        }}
                        className="app-btn-1" onClick={() => (setLoader(true), handleLogoutOtherDevice())}>
                        Submit
                    </button>
                        : <div style={{
                            // border: "2px solid var(--icon-primary-clr)",
                            marginTop: "1em",
                            height: "2em"
                        }}>

                            <SyncLoader color="var(--accent-primary-clr)" size={8} />
                        </div>}
                    <span className="stepexplanationheading" style={{ fontSize: "12px", textAlign: "center" }}>
                        {/* If Rittik Bhaiya didn't connect <br /> */}
                        {/* Need Help ? */}
                        Be noticed on the leaderboard – it's all about your name!<br />
                        {/* <span style={{}}> */}

                        Click button to proceed
                        {/* </span> */}

                    </span>
                </div>



            </div>

            <BackDrop blur closeHandler={() => (setFormsOpen(false),setClosePermanently(true))} />
        </>
    );
}

export default NameData;
