import { createSlice } from "@reduxjs/toolkit";

const initialState = {
    activityStepRecordData: 0
}

const addActivitySymbolize = createSlice({
    name: 'activityStepRecordData',
    initialState: initialState,
    reducers: {
        setActivityStepRecord: (state, action) => {
            state.activityStepRecordData = action.payload;
        },
        resetActivityStepRecord: (state) => {
            state.activityStepRecordData = 0;
        }
    }
})


export default addActivitySymbolize.reducer;
// console.log(addActivitySymbolize)
export const { setActivityStepRecord, resetActivityStepRecord } = addActivitySymbolize.actions;