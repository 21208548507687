
import './pre-loader.css';

import { logoLoader } from '../../../assets/icons';

const Preloader = () => {

    return (
        <>
            <div className="preloader">
                {/* <img
                    src={logoLoader}
                    alt="nioclass Loading ... "
                    className="preloader__img"
                /> */}


                <div class="bookshelf_wrapper LoadTQ-bookshelf">
                    <ul class="books_list">
                        <li class="book_item LoadTQ-book-item-1 first"></li>
                        <li class="book_item LoadTQ-book-item-2 second"></li>
                        <li class="book_item LoadTQ-book-item-3 third"></li>
                        <li class="book_item LoadTQ-book-item-4 fourth"></li>
                        <li class="book_item LoadTQ-book-item-5 fifth"></li>
                        <li class="book_item LoadTQ-book-item-6 sixth"></li>
                    </ul>
                    <div class="shelf LoadTQ-shelf"></div>
                </div>
            </div>

        </>
    );
};
export default Preloader;
