import React, { useState, useContext } from 'react';

import { getFbAuth } from "../../context/FirebaseContext";
import useRecordActivity from '../../hooks/useRecordActivity';
import { PopupContext } from '../../context/PopupStatusContext';
import { selectIcon, selectedIcon } from '../../assets/icons';

const BuyChaptersList = ({ chap, ind, selectedCount, setSelectedCount, setSelectedChapterIds, seletedChapterIds }) => {

	const authUser = getFbAuth().currentUser;
	const recordActivity = useRecordActivity(authUser?.phoneNumber)
	const [selected, setSelected] = useState(false)

	const handleClick = (e) => {
		if (selected) {
			// console.log("nonselected", chap);
			setSelectedChapterIds((prev)=>{
				const temp = [prev.filter((obj1)=>obj1!=chap.ChapterID)];
				return temp;
			})
			setSelected(false);
			setSelectedCount(selectedCount - 1);
		}
		else {
			// console.log("selected", chap);
			setSelectedChapterIds((prev)=>{
				return [...prev, chap.ChapterID];
			})
			setSelected(true);
			setSelectedCount(selectedCount + 1);
		}
		// console.log(seletedChapterIds)
		recordActivity('click', e.target.name, 'buy chapter list')
	}

	return (
		<button className={`useCredit__ChapterDisp ${selected ? "chap-selected" : ""}`}
			key={ind}
			name='btn-buy-chapter-select'
			onClick={handleClick}>

			<div className="useCredit__ChapterDispLeft">
				<img className="usecredit__chapterDispImg"
					src={chap?.ChapterThumbnailURL}
					alt=" "
				/>
				<div className="usecredit__chapterDispName">
					<h2>{chap?.ChapterName}</h2>
				</div>
			</div>

			<div className="useCredit__ChapterDispRight">
				<img src={selected ? selectedIcon : selectIcon} alt="selectIcon" />
			</div>

		</button>
	)
}

export default BuyChaptersList