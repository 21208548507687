import { useEffect } from 'react';
import { animateScroll as scroll } from 'react-scroll';

const useScrollToTop = () => {
    useEffect(() => {
        const scrollToTop = () => {
            scroll.scrollToTop();
        };

        scrollToTop();
    }, []);

    // You can return any additional data or functions as needed
};

export default useScrollToTop;
