import { useState, useEffect, useContext } from "react";
import { useParams } from "react-router";
// import { toast } from "react-toastify";

import { RecaptchaVerifier, signInWithPhoneNumber } from "firebase/auth";
import { FirebaseAuthContext } from "../../context/FirebaseAuthContext";
import { FirebaseContext } from "../../context/FirebaseContext";
import { regexPhoneNumber } from "../../utils/regex";

// import { dataCountry } from "./SignIn.logic";

export const dataCountry = [
  {
    name: "India",
    code: "+91",
  },
  {
    name: "VietNam",
    code: "+84",
  },
  {
    name: "YeMen",
    code: "+967",
  },
  {
    name: "Ukraine",
    code: "+380",
  },

  {
    name: "Trung Quốc",
    code: "+86",
  },
  {
    name: "Nga",
    code: "+7",
  },
  {
    name: "Hoa Kỳ",
    code: "+1",
  },
];

const useLogic = () => {
  const { auth } = useContext(FirebaseContext);
  const { setResultAuth } = useContext(FirebaseAuthContext);

  const [openOtpToFillIn, setOpenOTPTOfillIn] = useState(false);
  const [phoneCountry, setPhoneCountry] = useState(dataCountry[0]);

  const param = useParams();
  const [referred_by, setRefferedBy] = useState(param["user"]);
  useEffect(() => {
    if (param?.user) {
      setRefferedBy(window.location.pathname?.split("/")[2]);
    } else if (window.location.pathname?.split("/")?.length > 2) {
      window.location.pathname?.split("/");
      setRefferedBy(window.location.pathname?.split("/")[2]);
    }
  }, [window.location.pathname?.split("/"), param?.user]);
  const [phone, setPhone] = useState("");
  const [name, setUserName] = useState("");
  const fullPhone = phoneCountry.code + phone;

  const isVeryPhone = regexPhoneNumber(fullPhone);

  const [isLoading, setIsLoading] = useState(false);

  const onHandleChange = (e) => {
    const val = e.target.value;
    if (isNaN(+val)) return;
    setPhone(val);
  };
  const onHandleChangeName = (e) => {
    const val = e.target.value;
    if (/\d/.test(val)) return;
    setUserName(val);
  };

  useEffect(() => {
    try {
      if (!window.recaptchaVerifier) {
        window.recaptchaVerifier = new RecaptchaVerifier("sign-in-button", {
          size: "invisible",
          callback: () => {
            onGetOtp();
          },
        });
      }
    } catch (error) {}
  }, []);

  const onGetOtp = async () => {
    // console.log('getting otp')

    if (!isVeryPhone) return;
    if (isLoading) return;
    setIsLoading(true);

    try {
      auth.languageCode = "en";
      window.recaptchaVerifier = new RecaptchaVerifier(
        "recaptcha-container",
        { size: "invisible" },
        auth
      );
      const appVerifier = window?.recaptchaVerifier;
      const res = await signInWithPhoneNumber(auth, fullPhone, appVerifier);

      setResultAuth(res);
      setOpenOTPTOfillIn(true);
    } catch (err) {
      alert("please relogin after 2 minutes");
      // toast.error(err.code);
      console.log(err);
    } finally {
      //console.log("finals!!!");
      window.recaptchaVerifier?.clear();

      setOpenOTPTOfillIn(true);
      setIsLoading(false);
    }
  };

  return {
    onGetOtp,
    onHandleChangeName,
    onHandleChange,
    setOpenOTPTOfillIn,
    openOtpToFillIn,
    name,
    isLoading,
    phoneCountry,
    setPhoneCountry,
    phone,
    setPhone,
    referred_by,
    isVeryPhone,
    setUserName,
  };
};

export default useLogic;
