import React, { useState, useEffect, useContext } from 'react'
import { PopupContext } from '../../context/PopupStatusContext';
import './styles/creditDebit.css'

const CreditDebit = () => {
    const {creditDebited, setcreditDebited, amountOfCreditDebit} = useContext(PopupContext);
    return (
        <>
            {
                creditDebited && <div className='ntt'>
                    <div className='notifypopup'>
                        <svg width="52px" height="52px" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path d="M7.29417 12.9577L10.5048 16.1681L17.6729 9" stroke="#fff" strokeWidth="2.5" strokeLinecap="round" strokeLinejoin="round" />
                            <circle cx="12" cy="12" r="10" stroke="#fff" strokeWidth="2" />
                        </svg>
                        <div style={{ margin: "10px" }}>
                            <h1>{amountOfCreditDebit} Credit Used</h1>
                            {
                                window.location.pathname !== "/my-profile" ?
                                <h1>Initializing Chapter</h1> :
                                <h1>Successfully Unlocked the Chapter{amountOfCreditDebit>1 && "s"}</h1>
                            }
                        </div>
                    </div>
                </div>
            }
        </>
    )
}

export default CreditDebit