import React, { useEffect, useContext, useState } from "react";
import { Navigate } from "react-router-dom";
// import { FirebaseContext } from "../../context/FirebaseContext";

// import Preloader from "../ui/loading/Preloader";
// import Landingpage from "../landingpage/Landingpage";
import { loggedInUserCheck } from "../signIn/SignIn";

function LandingPageNav({
  utmCompaignD = null,
  component: Component,
  subjectualData,
  user,
  setUser,
}) {
  //   const { auth } = useContext(FirebaseContext);

  //   const [user, setUser] = useState(null);
  //   const [loading, setLoading] = useState(true);

  //   useEffect(() => {
  //     const unsubscribe = auth.onAuthStateChanged((user) => {
  //       setUser(user);
  //       setLoading(false);
  //     });

  //     return () => {
  //       unsubscribe();
  //     };
  //   }, [auth]);

  //   if (loading) return <Preloader />;

  return loggedInUserCheck(user) ? (
    <Component
      utmCompaignD={utmCompaignD}
      setUser={setUser}
      subjectualData={subjectualData}
      user={user}
    />
  ) : (
    //   <Landingpage
    //   utmCompaignD={utmCompaignD}
    //   user={user}
    //   setUser={setUser}
    //   subjectualData={subjectualData}
    // />
    <Navigate
      to={"/website" + window.location.search}
      setUser={setUser}
      utmCompaignD={utmCompaignD}
      user={user}
      replace
    />
  );
}

export default LandingPageNav;
