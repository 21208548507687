import { useDispatch, useSelector } from "react-redux";

import { set, setId } from "../slice/currentChapter";

const useCurrentChapter = () => {

    const currentChapter = useSelector((state) => state.currentChapterStore)
    // const selectChapterID = useSelector((state) => state.currentChapterStore)
    const dispatch = useDispatch()

    const setCurrentChapter = (tobeUpdateChapter) => {
        dispatch(set(tobeUpdateChapter))
    }

    const setSelectedChapterID = (tobeUpdateChapter) => {
        dispatch(setId(tobeUpdateChapter))
    }

    return { currentChapter, setCurrentChapter, setSelectedChapterID }

}

export default useCurrentChapter;