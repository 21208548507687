import React from 'react'
import { FounderPhotuManavDetails, Testinomial1, Testinomial11, Testinomial12, Testinomial13, Testinomial14, Testinomial2, Testinomial3, Testinomial4, manavKImage } from '../../assets/icons'
import { green } from '@mui/material/colors'
// import {
//     motion,
//     useScroll,
//     useSpring,
//     useTransform,
//     useMotionValue,
//     useVelocity,
//     useAnimationFrame
// } from "framer-motion";


function SectionNine() {
    const dataValue = [
        { name: 'Rajesh Shah', Quotes: "I like question-solving step-by-step on niopractice it helps me with right approach to take on every step", image: Testinomial1 },
        { name: 'Vivek Dhillon', Quotes: "NioPractice has transformed my preparation journey into mastering concepts.", image: Testinomial2 },
        { name: 'Geetika Chopra', Quotes: "NioPractice's step-by-step method not only guided me to master the subjects but also significantly boosted my speed!", image: Testinomial3 },
        { name: 'Ayesha Malik', Quotes: "With Niopractice, I save a lot of time on preparation.", image: Testinomial4 },
        // { name: 'Anshuman Tripathy1', Quotes: '"Touh problems seems way easier now."', image: manavKImage },
        // { name: 'Anshuman Tripathy2', Quotes: '"Touh problems seems way easier now."', image: manavKImage },
        // { name: 'Anshuman Tripathy', Quotes: '"Touh problems seems way easier now."', image: manavKImage },
    ]
    const dataValueDown = [
        { name: 'Siddharth Jain', Quotes: "NioPractice's clear, step-by-step solutions are a big help", image: Testinomial11 },
        { name: 'Rahul Kumar', Quotes: "I don't do math practice online, but NioPractice is easy to use and saves my a lot of time", image: Testinomial12 },
        { name: 'Karan Khandelwal', Quotes: "I love the most is it has no ads in between like every other app has and the second it's dark theme awesome", image: Testinomial13 },
        { name: 'Shruti Naidu', Quotes: 'Fantastic platform Every Jee aspirant has to use this Platform ', image: Testinomial14 },
        // { name: 'Anshuman Tripathy1', Quotes: '"Touh problems seems way easier now."', image: manavKImage },
        // { name: 'Anshuman Tripathy2', Quotes: '"Touh problems seems way easier now."', image: manavKImage },
        // { name: 'Anshuman Tripathy', Quotes: '"Touh problems seems way easier now."', image: manavKImage },
    ]


    return (
        <>
            <div className='headEightSection section-9'>
                <div className='HeadingTextSection-eight' style={{ fontSize: "calc(1.75rem + 1vmin)", marginTop: "1em" }}>

                    We understand that mastering subjects can be quite challenging.
                    <br />
                    <span className='subtextOriginSectionNine' >
                        See what others have to say!
                    </span>
                </div>
            </div>
            <div className='FounderInvitationSectionNine'>
                <div className='FounderInvitationSectionNine-container'>
                    {/* <div className='circulatedpicSectionNine' /> */}
                    <img src={FounderPhotuManavDetails} className='circulatedpicSectionNine' style={{ width: "4.5em" }} />
                    {/* <div className='circulatedpicSectionNine runningItems' /> */}


                    <div className='NameFounderNineSectionDalla'>
                        <div>
                            Manav Kothari, CEO
                        </div>
                        <div className='circulatedpicSectionNineGreetsContent'>
                            Hey! Can you please give a review on NioPractice!
                        </div>
                    </div>
                </div>
            </div>


            <section className="slide-option">
                <div className='FounderInvitationSectionNine runningItem'>

                    <div id="infinite" className="highway-slider">
                        <div className="container highway-barrier">
                            <ul className="highway-lane">

                                {dataValue.map((obj, index) => (
                                    <li className="highway-car" key={index}>

                                        <div className='FounderInvitationSectionNine-container runningItems'>
                                            <img src={obj.image} className='circulatedpicSectionNine runningItems' />
                                            {/* <div className='circulatedpicSectionNine runningItems' /> */}

                                            <div>
                                                <div style={{ marginBottom: "0.3em" }}>
                                                    {obj.name}
                                                </div>
                                                <div className='circulatedpicSectionNineGreetsContent runningItems'>
                                                    {obj.Quotes}
                                                </div>
                                            </div>
                                        </div>
                                    </li>
                                ))}
                                {dataValue.map((obj, idx) => (
                                    <li className="highway-car" key={idx}>

                                        <div className='FounderInvitationSectionNine-container runningItems'>
                                            <img src={obj.image} className='circulatedpicSectionNine runningItems' />

                                            <div>
                                                <div style={{ marginBottom: "0.3em" }}>
                                                    {obj.name}
                                                </div>
                                                <div className='circulatedpicSectionNineGreetsContent runningItems'>
                                                    {obj.Quotes}
                                                </div>
                                            </div>
                                        </div>
                                    </li>
                                ))}



                            </ul>
                        </div>
                    </div>
                    <div id="infinite" className="highway-slider" style={{ marginTop: "1rem" }}>
                        <div className="container highway-barrier">
                            <ul className="highway-lane">

                                {dataValueDown.map((obj, idx) => (
                                    <li className="highway-car reversify" key={idx}>

                                        <div className='FounderInvitationSectionNine-container runningItems'>
                                            <img src={obj.image} className='circulatedpicSectionNine runningItems' />

                                            <div>
                                                <div style={{ marginBottom: "0.3em" }}>
                                                    {obj.name}
                                                </div>
                                                <div className='circulatedpicSectionNineGreetsContent runningItems'>
                                                    {obj.Quotes}
                                                </div>
                                            </div>
                                        </div>
                                    </li>
                                ))}
                                {dataValueDown.map((obj, index) => (
                                    <li className="highway-car reversify" key={index}>

                                        <div className='FounderInvitationSectionNine-container runningItems'>
                                            <img src={obj.image} className='circulatedpicSectionNine runningItems' />

                                            <div>
                                                <div style={{ marginBottom: "0.3em" }}>
                                                    {obj.name}
                                                </div>
                                                <div className='circulatedpicSectionNineGreetsContent runningItems'>
                                                    {obj.Quotes}
                                                </div>
                                            </div>
                                        </div>
                                    </li>
                                ))}



                            </ul>
                        </div>
                    </div>
                </div>
            </section>


            {/* <div>SectionNine</div> */}
        </>
    )
}

export default SectionNine