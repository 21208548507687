import { createSlice } from "@reduxjs/toolkit";

const initialState = {
    currentUser: {},
}

const currentUserSlice = createSlice({
    name: 'currentUser',
    initialState: initialState,
    reducers: {
        set: (state, action) => {
            //console.log(action)
            state.currentUser = action.payload;
        },
        remove: (state) => {
            state.currentUser = {};
        },

    }
})

export default currentUserSlice.reducer
export const { set, remove } = currentUserSlice.actions