import React, { useEffect, useState } from "react";
import useRecordActivity from "../../hooks/useRecordActivity";
import { dbImg, GateDBImg, PYQDBImg } from "../../assets/images";
// import dbImg2 from "/";
const vidURL =
  "https://niopracticevideos.s3.ap-northeast-1.amazonaws.com/finalv+(2).mp4";

function hero(props) {
  const {
    highSchool,
    user,
    handleClickStartPracticising,
    handleShiftLogin,
    authUser,
    handleClickLogin,
    subjectualData,
  } = props;
  const recordActivity = useRecordActivity(authUser?.phoneNumber);

  const handleStartPracticeBtnClick = (e) => {
    handleClickStartPracticising();
    const targetName = e.target.name;
    recordActivity("button click", targetName);
  };
  const [first, setfirst] = useState(true);

  useEffect(() => {
    if (
      user &&
      user != '"undefined"' &&
      localStorage.getItem("UserID") &&
      first
    ) {
      handleShiftLogin();
      setfirst(false);
    }
  }, [user, subjectualData?.navbar]);
  // console.log(subjectualData?.SlugCh)
  // console.log(authUser)
  // console.log(subjectualData)

  return (
    <div className="hero-section-bg">
      <div className="hero-section">
        <div className="hero-section__container">
          <h1 className="hero-section__title">
            {highSchool ? (
              <div style={{ textAlign: "center" }}>
                Master math with AI <br /> Your ultimate practice partner
              </div>
            ) : (
              <>
                {" "}
                {subjectualData?.SlugCh ? subjectualData?.SlugCh : "JEE"}{" "}
                Practice, Simplified{" "}
              </>
            )}
          </h1>
          <h2 className="hero-section__sub-title">
            {highSchool ? (
              <>
                Unlock your full potential with{" "}
                <em className="hero-section__sub-title__accented-text">
                  Step by Step{" "}
                </em>
                practice.{" "}
              </>
            ) : (
              <>
                Make the right approach{" "}
                <em className="hero-section__sub-title__accented-text">
                  click{" "}
                </em>
                instantly.{" "}
                {/* <em className='hero-section__sub-title__accented-text'>fast.</em> */}
              </>
            )}
          </h2>
          <div className="courseButtonHeroSection">
            <button
              className="app-btn-1 heroBtnPractising"
              name="btn-start-practice"
              onClick={handleStartPracticeBtnClick}
            >
              Start Practicing!
            </button>
            {/* {!authUser && <button className="app-btn-1-reverse hide-small heroBtnPractising" onClick={handleClickLogin}>
                            Log In
                        </button>} */}
          </div>
        </div>

        {/* <div className="">
                    <video controls loop autoPlay={true} muted
                        className="hero-section__video"
                    >
                        <source type="video/mp4" src={vidURL} />
                        Sorry, your browser doesn't 100tcsopacitysupport
                        embedded videos.
                    </video>
                </div> */}
        {/* <div className="about__container about__header LandingPage"> */}
        {/* <div className="about-img-main app-img-border-rotate large "> */}
        <div
          className="boxShadow"
          // style={{ boxShadow: "0px 0px 20px px rgba(158, 65, 170, 0.5)" }}
        >
          {window.innerWidth > 600 ? (
            <img
              src={
                subjectualData?.SlugCh === "GATE"
                  ? GateDBImg
                  : subjectualData?.SlugCh === "CAT"
                  ? PYQDBImg
                  : dbImg
              }
              alt="NioClass"
              className="about-hero-img hide-small LandingPage"
              loading="eager"
            />
          ) : (
            <img
              src={
                subjectualData?.SlugCh === "GATE"
                  ? GateDBImg
                  : subjectualData?.SlugCh === "CAT"
                  ? PYQDBImg
                  : "/Landing+page+hero.png"
              }
              alt="NioClass"
              className="blog-img-2 hide-big LandingPage"
              loading="eager"
            />
          )}
        </div>
        {/* </div> */}
      </div>
    </div>
  );
}

export default hero;
