import { createSlice } from "@reduxjs/toolkit";

const initialState = {
    questionIDs: []
}

const selectedQuestionIDs = createSlice({
    name: 'tutorialSlice',
    initialState: initialState,
    reducers: {
        setIDs: (state, actions) => {
            state.questionIDs = actions.payload;
        },
        resetIDs: (state) => {
            state.questionIDs = [];
        }
    }
})


export default selectedQuestionIDs.reducer;
export const { setIDs, resetIDs } = selectedQuestionIDs.actions;