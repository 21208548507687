import axios from 'axios'

const getpremiumchapters = async (myusrph, abc=false) => {
    const apiUrl = `https://kqd7mc4a26.execute-api.ap-northeast-1.amazonaws.com/getPremiumChapters/getpremiumchapters?UserID=${myusrph}`+
    (abc ?
        "&abc=abc":
        ""
    )
    try {
        const response = await axios.get(apiUrl);
        return response
    } catch {
        throw new Error("An error occurred during the API request.");
    }

}

export default getpremiumchapters