
import useLocalStorage from "./useLocalStorage";

function useNioclassTutorialRecord() {

    const { LSData,
        setLocalStorage,
        removeLocalStorage,
        updateLocalStorage } = useLocalStorage("NioClassTutorialRecord");

    const createTutorialRecord = () => {
        setLocalStorage({
            isTutorialEnd: false,
            currentStep: 6,
            isAuthenticated: false
        })
    }

    const setTutorialRecord = () => {
        // if (!LSData)
        createTutorialRecord()
    }

    const deleteTutorialRecord = (value) => {
        removeLocalStorage("NioClassTutorialRecord")
    }

    const updateTutorialStep = (value) => {
        updateLocalStorage('currentStep', value)
    }

    const endTutorial = () => {
        updateLocalStorage({
            isTutorialEnd: true
        })
    }

    const getTutorialRecord = () => {
        return LSData
    }

    const getTutorialStep = () => {
        return LSData.currentStep
    }

    const currentTutorialStep = LSData.currentStep

    return {
        setTutorialRecord,
        deleteTutorialRecord,
        updateTutorialStep,
        endTutorial,
        getTutorialStep,
        currentTutorialStep,
        getTutorialRecord
    }
}

export default useNioclassTutorialRecord;