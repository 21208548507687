import { useContext, useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { FirebaseContext } from "../../context/FirebaseContext";

import SimpleNavLinks from "./SimpleNavLinks";
import AuthenticatedNavLinks from "./AuthenticatedNavLinks";

import useRecordActivity from "../../hooks/useRecordActivity";
import { getAnalytics, setUserProperties, setUserId } from "firebase/analytics";
// import mixpanel from 'mixpanel-browser';
import axios from "axios";

import "./styles/navbar.css";
import IndependentLogo from "./IndependentLogo";
import NameData from "../signIn/VerifyOtp/NameData";
import useToast from "../toast/useToastNew";
import { successIcon } from "../../assets/icons";
import { examPageVisible } from "../landingpage/Landingpage";
import SignIn, { loggedInUserCheck } from "../signIn/SignIn";
import BackDrop from "../ui/backdrop/BackDrop";
import useSigninPop from "../../redux/control/useSignInPop";
import AllUserDataRecords from "../signIn/VerifyOtp/AllUserDataRecords";

export const pathsToCheck = [
  "website",
  "dashboard",
  "chapter-selection",
  "chapter-practice",
  "testSeries",
  "timerTest",
  "submitTest",
  "terms-and-conditions",
  "privacy-policy",
  "contact-us",
  "blog",
  "about-us",
  "sitemap",
  "cancellation-and-refund-policy",
  "faq",
  "pricing-policy",
];

export const activatedPath = pathsToCheck.some((path) =>
  window.location.pathname.includes(path)
);

function Navbar({
  subjectualData,
  setUser,
  user,
  loaded,
  setLoaded,
  setOpenChatbot,
  startingAnimation,
  bounceAnimation,
  setBounceAnimation,
  setSwitches,
  utmCompaignD,
}) {
  const navigate = useNavigate();
  const { currentSigninPopup, setloginPopVisible } = useSigninPop();
  // console.log(currentSigninPopup);

  const [addedReturningUser, setAddedReturningUser] = useState(true);
  const [openNameModal, setOpenNameModal] = useState(false);
  const [loginPopup, setloginPopup] = useState(false);
  const [name, setUserName] = useState("");
  const { Toast, setToast } = useToast();
  const [closePermanently, setClosePermanently] = useState(false);

  const { auth } = useContext(FirebaseContext);
  // console.log(user);
  // const params = useParams();
  // const userAl = auth?.currentUser;
  // console.log(userAl);
  useEffect(() => {
    // if(user){

    setTimeout(() => {
      setloginPopup(true);
    }, [1500]);
    // }
  }, [user]);
  // useEffect(() => {
  //   // if (window.location.pathname?.includes("website")) {
  //   //   setSwitches(false);
  //   // }
  //   // else

  // }, [window.location.pathname]);
  useEffect(() => {
    if (
      user &&
      user !== '"undefined"' &&
      window.location.host !== "localhost:3000"
    ) {
      if (!window.location.pathname?.includes("website")) {
        setSwitches(true);
      } else {
        setSwitches(false);
      }
      axios.get(
        `https://n911gqr3if.execute-api.ap-northeast-1.amazonaws.com/navigationRecords?UserID=${user}&launchTime=${new Date()
          .toISOString()
          .slice(0, 19)
          .replace("T", " ")}&pathName=${window.location.pathname}`
      );
    }

    // console.log("Hello", window.location.pathname);
  }, [window.location.pathname]);

  useEffect(() => {
    const previousPath = window.history.state && window.history.state.prevPath;
    console.log(window.history.state);
    if (previousPath === "/timerTest") {
      // navigate('/testSeries');
    } else {
      // navigate(-1);
    }
  }, [location.pathname]);

  useEffect(() => {
    // setloginPopVisible(true);
    // console.log(user);

    setTimeout(() => {
      // console.log(localStorage.getItem("UserID")?.length > 5);

      if (!(user && user !== '"undefined"')) {
        // console.log(user);
        const alreadyLoggedUser = auth?.currentUser?.phoneNumber?.substring(
          1,
          auth?.currentUser?.phoneNumber?.length
        )
          ? auth?.currentUser?.phoneNumber?.substring(
              1,
              auth?.currentUser?.phoneNumber?.length
            )
          : '"' + auth?.currentUser?.email + '"';
        // console.log(alreadyLoggedUser);

        if (alreadyLoggedUser !== '"undefined"' && alreadyLoggedUser) {
          setUser(alreadyLoggedUser);
          // console.log(alreadyLoggedUser);
        } else if (localStorage.getItem("UserID")?.length > 5) {
          const value = localStorage.getItem("UserID");
          setUser(value);
          // console.log(value);
        }
      }
    }, [1000]);
  }, [user, auth?.currentUser, localStorage?.getItem("UserID")]);
  // console.log(alreadyLoggedUser);
  const auth_person = user;

  const recordActivity = useRecordActivity(auth_person);

  const handleClickLogo = (e) => {
    navigate("/website");
    const targetName = e.target.name;
    recordActivity("click", targetName, "navbar");
  };

  const handleStartPracticingBtn = () => {
    navigate("/chapter-selection", {
      state: {
        Chapter: `${subjectualData?.DropSubjects[0]}`,
        UserID: auth_person,
      },
    });
  };

  // useEffect(() => {
  //   //   console.log(params?.token);
  //   if (auth) {
  //     auth.onAuthStateChanged(function (user) {
  //       if (user) setUser(user);
  //       else setUser(null);
  //     });
  //     setLoaded(true);
  //   }
  // }, [auth]);
  useEffect(() => {
    if (
      addedReturningUser &&
      auth_person !== '"undefined"' &&
      window.performance.navigation.type === 0
    ) {
      axios
        .get(
          `https://itr6nwsp84.execute-api.ap-northeast-1.amazonaws.com/addUserStatsVisits?UserID=${auth_person}`
        )
        .then(() => {
          // console.log(window.performance.navigation.type === 0)
          setAddedReturningUser(false);
          setLoaded(true);
        })
        .catch((err) => console.log(err));
    }
  }, [auth_person]);

  async function handleNameUpdate() {
    await axios.get(
      "https://1edof059ii.execute-api.ap-northeast-1.amazonaws.com/setUserName/setusername?UserID=" +
        auth_person +
        "&Name=" +
        name,
      {
        headers: {
          "Content-Type": "application/x-www-form-urlencoded",
        },
      }
    );
  }
  const handleNameChange = () => {
    if (name.length) {
      setClosePermanently(true);
      handleNameUpdate()
        .then((res) => {
          setOpenNameModal(false);
          setToast("Updated Successfully", {
            color: "green",
            iconUrl: successIcon,
            timer: 1,
          });
          // alert("Updated Successfully")
        })
        .catch((err) => console.log(err));

      // console.log(auth_person)
    } else {
      alert("Please Enter a Name");
    }
  };
  const onHandleChangeName = (e) => {
    const val = e.target.value;
    if (/\d/.test(val)) return;
    setUserName(val);
    // console.log(val)
  };

  // useEffect(() => {
  //     let uidTest = (auth ? (auth.currentUser?.phoneNumber
  //         ? (auth.currentUser.phoneNumber?.substring(
  //             1,
  //             auth.currentUser.phoneNumber?.length
  //         ))
  //         : '"' + auth.currentUser?.email + '"') : Math.floor((10000000 + Math.random()) * 0x10000)
  //             .toString(32)
  //             .substring(1))
  //     if (uidTest === '"undefined"') {
  //         const uidTests = Math.floor((10000000000000 + Math.random()) * 0x100000)
  //             .toString(32)
  //             .substring(1)
  //         uidTest = "New User " + uidTests
  //     }
  //     // console.log(uidTest)

  //     mixpanel.init(`d23ef9ddfb4a5c510ee2e8d1bb3e3c5d`, { debug: true, track_pageview: true, persistence: 'localStorage' });

  //     // Set this to a unique identifier for the user performing the event.
  //     mixpanel.identify(uidTest)

  //     // Track an event. It can be anything, but in this example, we're tracking a Sign Up event.
  //     mixpanel.track('Sign Up', {
  //         'Signup Type': 'Referral'
  //     })
  //     mixpanel.set_config({ 'persistence': 'localStorage' })

  // }, [auth])
  // console.log(activatedPath);
  useEffect(() => {
    // console.log(user)
    let uidTest = auth
      ? auth.currentUser?.uid
      : Math.floor((10000000 + Math.random()) * 0x10000)
          .toString(32)
          .substring(1);
    if (uidTest != '"undefined"' && uidTest) {
      // const uidTests = Math.floor((10000000000000 + Math.random()) * 0x100000)
      //     .toString(32)
      //     .substring(1)
      // uidTest = "New User " + uidTests

      const analytics = getAnalytics();
      setUserProperties(analytics, { user_id: uidTest });
      setUserId(analytics, uidTest);
      window.dataLayer.push({
        user_id: uidTest,
      });
    }
    setLoaded(true);
    // const userid = auth.currentUser?.phoneNumber
    //     ? auth.currentUser.phoneNumber?.substring(
    //         1,
    //         auth.currentUser.phoneNumber?.length
    //     )
    //     : '"' + auth.currentUser?.email + '"';
    // console.log(uidTest, auth.currentUser)
    // const analytics = getAnalytics();
    // setUserProperties(analytics, { user_id: uidTest });
    // setUserId(analytics, uidTest);

    // window.dataLayer.push({
    //     'user_id': uidTest
    // });
  }, [window.location.pathname, user, auth]);

  useEffect(() => {
    // Check if the hostname matches the specified domains
    if (window.location.hostname?.toLowerCase()?.includes("careerlauncher")) {
      // Create a new link element
      const link = document.createElement("link");
      link.rel = "icon";
      link.href = `${process.env.PUBLIC_URL}/CLfavicon.ico`; // Specify the favicon URL for niopractice.com

      // Append the link element to the head section of the document
      document.head.appendChild(link);
    } else {
      // Create a new link element
      const link = document.createElement("link");
      link.rel = "icon";
      link.href = `${process.env.PUBLIC_URL}/favicon.ico`; // Specify the default favicon URL

      // Append the link element to the head section of the document
      document.head.appendChild(link);
    }
  }, []);
  // console.log(subjectualData?.SlugCh?.toLowerCase());

  if (
    location?.pathname?.length < 2 &&
    window.location.host?.includes("careerlauncher")
  )
    return null;
  return (
    <>
      <Toast />
      {!(
        location.pathname.includes("timerTest") ||
        location.pathname.includes("testSolutions")
      ) ? (
        <>
          {!location.pathname.includes("/sign-in") ? (
            <header className="header">
              {!examPageVisible ? (
                <AuthenticatedNavLinks
                  utmCompaignD={utmCompaignD}
                  userid={auth_person}
                  auth={auth}
                  startingAnimation={startingAnimation}
                  bounceAnimation={bounceAnimation}
                  setBounceAnimation={setBounceAnimation}
                  handleClickLogo={handleClickLogo}
                  subjectualData={subjectualData}
                  setOpenNameModal={setOpenNameModal}
                  setUser={setUser}
                  user={user}
                  handleStartPracticingBtn={handleStartPracticingBtn}
                  setloginPopVisible={setloginPopVisible}
                  openNameModal={openNameModal}
                />
              ) : (
                <SimpleNavLinks
                  subjectualData={subjectualData}
                  examPageVisible={examPageVisible}
                  handleClickLogo={handleClickLogo}
                  handleStartPracticingBtn={handleStartPracticingBtn}
                />
              )}
            </header>
          ) : (
            <header>
              <IndependentLogo
                handleClickLogo={handleClickLogo}
                subjectualData={subjectualData}
              />
            </header>
          )}
        </>
      ) : (
        <></>
      )}
      {/* {console.log(loginPopup)} */}
      {(!subjectualData?.org?.toLowerCase()?.includes("cl") ||
        window?.location?.host?.toLowerCase()?.includes("niopractice") ||
        window?.location?.host?.toLowerCase()?.includes("local")) &&
      loginPopup &&
      ((currentSigninPopup?.signINshowPopup && !loggedInUserCheck(user)) ||
        (!loggedInUserCheck(user) &&
          !pathsToCheck.some((path) =>
            window.location.pathname.includes(path)
          ))) ? (
        <div>
          <div className="signinPopupAbsolution">
            <SignIn
              subjectualData={subjectualData}
              setUser={setUser}
              utmCompaignD={utmCompaignD}
            />
          </div>
          {pathsToCheck.some((path) =>
            window.location.pathname.includes(path)
          ) ? (
            <BackDrop blur />
          ) : null}
        </div>
      ) : null}

      {openNameModal &&
      !window.location.host?.toLowerCase()?.includes("careerlauncher") &&
      !closePermanently ? (
        <>
          {subjectualData?.SlugCh?.toLowerCase() != "ssc" &&
          subjectualData?.SlugCh?.toLowerCase() != "ibps" ? (
            <AllUserDataRecords
              selectedExam={subjectualData?.SlugCh?.toLowerCase()}
              user={user}
              setOpenNameModal={setOpenNameModal}
              setClosePermanently={setClosePermanently}
            />
          ) : (
            <NameData
              setFormsOpen={setOpenNameModal}
              handleLogoutOtherDevice={handleNameChange}
              onHandleChangeName={onHandleChangeName}
              setClosePermanently={setClosePermanently}

              // handleLoginPopUP={console.log("hii")}
            />
          )}
        </>
      ) : (
        ""
      )}
    </>
  );
}

export default Navbar;
