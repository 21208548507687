import React, { useEffect, useState } from "react";
import "./styles/allDataRecords.css";
import ComponentStatePartTwo from "./ComponentStatePartTwo";
import { coachingInstitutes } from "./AllCoachingInsttitues";
import { useToast } from "../../toast/useToastNew";
import { errIcon } from "../../../assets/icons";
import axios from "axios";

function AllUserDataRecords({
  selectedExam,
  user,
  setOpenNameModal,
  setClosePermanently,
}) {
  const { Toast, setToast } = useToast();
  const [step, setStep] = useState(1);
  const [valueState, setValueState] = useState("");
  const [name, setName] = useState("");
  const [lastName, setLastName] = useState("");
  const [instituteSelected, setInstitute] = useState();
  const [selfStudy, setSelfStudy] = useState(false);
  const [selectedAttempt, setSelectedAttempt] = useState();
  const handleContinueBtn = () => {
    if (!name && step === 1) {
      setToast("Enter Name", { color: "red", iconUrl: errIcon, timer: 1 });
      return;
    }
    if (!valueState && step === 2) {
      setToast("Select State", { color: "red", iconUrl: errIcon, timer: 1 });
      return;
    }
    if (!selectedAttempt?.key && step === 2) {
      setToast("Select Category", { color: "red", iconUrl: errIcon, timer: 1 });
      return;
    }
    if (step < 3) {
      setStep(step + 1);
    } else {
      // start api and link data to it
      console.log("api");
      handleLogin();
    }
  };
  const handleBackNavigator = () => {
    setStep(step - 1);
  };

  // console.log(selectedAttempt);
  const handleLogin = async () => {
    setClosePermanently(true);
    let data = selectedAttempt.key;
    let inputValue = {
      state: valueState.code,
      jee_grade: selectedExam === "iit" ? (data ? data : "null") : "null",
      cat_attempt: selectedExam === "cat" ? (data ? data : "null") : "null",
      ssc_category: selectedExam === "ssc" ? (data ? data : "null") : "null",
      studyMode: selfStudy ? "Self" : instituteSelected?.Name,
      AttemptingExam: selectedExam,
      firstName: name,
      lastName: lastName,
    };
    // console.log(user);
    if (user) {
      await axios
        .post(
          `https://djo4ra118a.execute-api.ap-northeast-1.amazonaws.com/updateUserDetail?UserID=${user}`,
          inputValue
        )
        .then((res) => {
          console.log(res.data);
          setOpenNameModal(false);
        })
        .catch((err) => {
          console.log(err);
        });
    } else {
      console.log("Didn't get user id");
    }
  };

  useEffect(() => {
    if (
      coachingInstitutes?.filter((obj) =>
        obj?.examValid?.toLowerCase()?.includes(selectedExam)
      )?.length
    )
      setInstitute(
        coachingInstitutes?.filter((obj) =>
          obj?.examValid?.toLowerCase()?.includes(selectedExam)
        )[0]
      );
  }, [selectedExam]);

  return (
    <div className="allUserDataContainer">
      <Toast />
      <div className="allUserDataRecords">
        <div className="allUserDataRecordsHeader">
          {/* All User Data Records */}
          <div className="userdataHeader">
            <div className="backBtnCirculations">
              {" "}
              <div
                className="CDSTRevBackBtn pphBackBtn"
                onClick={() => handleBackNavigator()}
                style={{
                  opacity: step > 1 ? 1 : 0,
                  visibility: step > 1 ? "visible" : "hidden",
                  cursor: "pointer",
                }}
              >
                <svg
                  width="16"
                  height="14"
                  viewBox="0 0 16 14"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M1 7L7 1M1 7L7 13M1 7H15"
                    stroke="#2F99D7"
                    stroke-width="2"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                  />
                </svg>
                BACK
              </div>
            </div>
            <div className="userdataHeaderSteps"> Step {` ${step} `} of 3</div>
          </div>
          <div className="userdataProgress">
            <div
              className="userdataProgressSteps"
              style={{
                width: `${(step / 3) * 100}%`,
                transition: "width 0.5s ease-out",
              }}
            ></div>
          </div>
          {step === 1 ? (
            <div className="step1UserData">
              <div className="userdataBodyNAme">What should we call you?</div>
              {/* input fields for Name and last name */}
              <div className="userdataBodyNameInput">
                <input
                  type="text"
                  placeholder="First Name"
                  className="userdataBodyNameInputField"
                  onChange={(e) => setName(e.target.value)}
                  value={name}
                />
                <input
                  type="text"
                  placeholder="Last Name"
                  className="userdataBodyNameInputField"
                  onChange={(e) => setLastName(e.target.value)}
                  value={lastName}
                />
              </div>
            </div>
          ) : null}

          {step === 2 ? (
            <div>
              <ComponentStatePartTwo
                setSelectedAttempt={setSelectedAttempt}
                selectedAttempt={selectedAttempt}
                selectedExam={selectedExam}
                setValueState={setValueState}
                valueState={valueState}
              />
            </div>
          ) : null}

          {step === 3 ? (
            // <div className="step3UserData">
            //   <div className="userdataBodyNAme">Select Coaching Institute</div>
            //   {/* input fields for Name and last name */}
            //   <div className="userdataBodyNameInput">
            //     {/* array of the coaching institutes single select */}
            //     <select
            //       className="userdataBodyNameInputField"
            //       onChange={(e) => setInstitute(e.target.value)}
            //       value={institute}
            //     >
            //       <option value="0">Select Coaching Institute</option>
            //       <option value="1">Aakash</option>
            //       <option value="2">Allen</option>
            //       <option value="3">Resonance</option>
            //       <option value="4">Vidyamandir</option>
            //       <option value="5">Career Point</option>
            //       <option value="6">FIITJEE</option>
            //     </select>
            //   </div>
            // </div>
            <div className="step3UserData">
              <div className="userdataBodyNAme">Select Coaching Institute</div>
              <div
                className="allCoachingInstitues"
                style={{ opacity: selfStudy ? 0.6 : 1 }}
              >
                {coachingInstitutes
                  ?.filter((obj) =>
                    obj?.examValid?.toLowerCase()?.includes(selectedExam)
                  )
                  ?.map((institute) => (
                    <div
                      className={
                        "coachingInstitute " +
                        (institute?.id === instituteSelected?.id
                          ? "selected"
                          : "")
                      }
                      onClick={() => [
                        setInstitute(institute),
                        setSelfStudy(false),
                      ]}
                    >
                      <img
                        src={institute.Logo}
                        alt={institute.DisplayedText}
                        className="coachingImage"
                      />
                      <div className="coachingInstituteName">
                        {institute.DisplayedText}
                      </div>
                    </div>
                  ))}
                {/* checkbox selection  for the Self Study */}
              </div>
              <div className="selfStudyArea">
                {/* <div className="selfStudyAreaName">Self Study</div> */}
                <div
                  className="selfStudyAreaCheckbox"
                  onClick={() => setSelfStudy(!selfStudy)}
                >
                  {selfStudy ? (
                    <svg
                      width="18"
                      height="18"
                      viewBox="0 0 24 24"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        fill-rule="evenodd"
                        clip-rule="evenodd"
                        d="M2.66667 0.00195312C1.95942 0.00195313 1.28115 0.282905 0.781048 0.783002C0.280951 1.2831 0 1.96138 0 2.66862V21.3353C0 22.0425 0.280951 22.7208 0.781048 23.2209C1.28115 23.721 1.95942 24.002 2.66667 24.002H21.3333C22.0406 24.002 22.7189 23.721 23.219 23.2209C23.719 22.7208 24 22.0425 24 21.3353V2.66862C24 1.96138 23.719 1.2831 23.219 0.783002C22.7189 0.282905 22.0406 0.00195313 21.3333 0.00195312H2.66667ZM18.6 9.06329C18.8502 8.81328 18.9908 8.47412 18.9909 8.12043C18.9911 7.76673 18.8507 7.42747 18.6007 7.17729C18.3507 6.9271 18.0115 6.78648 17.6578 6.78635C17.3041 6.78623 16.9649 6.92661 16.7147 7.17662L10.1147 13.7766L7.28667 10.9486C7.16287 10.8247 7.01589 10.7265 6.85412 10.6594C6.69234 10.5923 6.51894 10.5577 6.3438 10.5577C5.99011 10.5576 5.65085 10.6979 5.40067 10.948C5.15048 11.198 5.00986 11.5371 5.00973 11.8908C5.00961 12.2445 5.14999 12.5838 5.4 12.834L9.07733 16.5113C9.21354 16.6476 9.37525 16.7556 9.55324 16.8294C9.73123 16.9031 9.922 16.9411 10.1147 16.9411C10.3073 16.9411 10.4981 16.9031 10.6761 16.8294C10.8541 16.7556 11.0158 16.6476 11.152 16.5113L18.6 9.06329Z"
                        fill="#2F99D7"
                      />
                    </svg>
                  ) : (
                    <div className="checkboxSelfStudy"></div>
                  )}
                  <label className="selfStudyLabel">Self Study</label>
                  {"(Select if you study on your own)"}
                </div>
              </div>
            </div>
          ) : null}
        </div>
        <div
          className="app-btn-1"
          onClick={() => handleContinueBtn()}
          style={{ cursor: "pointer" }}
        >
          {step < 3 ? "Continue" : "Get Started"}
        </div>
      </div>
    </div>
  );
}

export default AllUserDataRecords;
