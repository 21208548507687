import React, { useState, useEffect, useContext } from "react";

import { getFbAuth } from "../../context/FirebaseContext";
import useRecordActivity from "../../hooks/useRecordActivity";

import BuyChaptersList from "../navbar/BuyChaptersList";
import { fetchUrl } from "../../utils/globalFunctions";
import useCredit from "../../redux/control/useCredit";
import getpremiumchapters from "../apis/apiFunctions/getpremiumchapters";
import getallchaptermetadata from "../apis/apiFunctions/getallchaptermetadata";
import addpremiumaccess from "../apis/apiFunctions/addpremiumaccess";
import updateusercredits from "../apis/apiFunctions/updateusercredits";
import { PopupContext } from "../../context/PopupStatusContext";
import "../profile/buyPremiumModal.css";
// import useCredit from '../../redux/control/useCredit';
import axios from "axios";

import { underScoreIcon, closeBtnIcon } from "../../assets/icons";

const UseCredits = ({ userid, UserCredits, setUseCredits }) => {
  const authUser = getFbAuth().currentUser;
  const recordActivity = useRecordActivity(authUser?.phoneNumber);

  const { userCredit, setUsersCredit } = useCredit();

  const handleUnlock = (e) => {
    setUseCredits(false);
    recordActivity("click", e.target.name, "credit modal");
  };
  const handleClose = (e) => {
    setUseCredits(false);

    recordActivity("click", e.target.name, "credit modal");
  };

  const { amountOfCreditDebit, setAmountOfCreditDebit, setcreditDebited } =
    useContext(PopupContext);

  const [isLoaded, setIsLoaded] = useState(false);
  const [disabled, setDisabled] = useState(false);
  const [isLoaded2, setIsLoaded2] = useState(true);
  const [selectedCount, setSelectedCount] = useState(0);
  const [chapterMetaList, setChapterMetaList] = useState([]);
  const [seletedChapterIds, setSelectedChapterIds] = useState([]);
  const [nonPurchasedChapterList, setNonPurchasedChapterList] = useState([]);

  useEffect(() => {
    setDisabled(userCredit > 0 && selectedCount <= userCredit);
  }, [selectedCount, userCredit]);

  const fetchData = async () => {
    if (userid) {
      try {
        const allChap = await getallchaptermetadata();
        setChapterMetaList(allChap.data);
        setIsLoaded(true);
        const premChap = await getpremiumchapters(userid);

        if (allChap?.data?.length && premChap?.data?.length) {
          const filteredChap = allChap.data.filter((obj1) => {
            return !premChap.data.some(
              (obj2) => obj1.ChapterID === obj2.ChapterID
            );
          });
          setNonPurchasedChapterList(filteredChap);
        }
      } catch (e) {
        console.log("caught err" + e.message);
      }
    }
  };

  useEffect(() => {
    setUseCredits(5);
    fetchData();
  }, [userid]);

  const handleBuyChapters = async (e) => {
    if (userCredit > 0 && selectedCount <= userCredit && selectedCount > 0) {
      try {
        setAmountOfCreditDebit(selectedCount);
        setIsLoaded2(false);
        const temp = seletedChapterIds.join(", ");
        const chaptersData = { ChapterID: temp };
        await addpremiumaccess(userid, chaptersData);
        await updateusercredits(userid, selectedCount);
        await fetchData();
        handleClose();
      } catch (err) {
        console.log(err);
      } finally {
        setcreditDebited(true);
        setUsersCredit(userCredit - selectedCount);
        setIsLoaded2(true);
      }
    }
  };

  // useEffect(()=>{
  // 	console.log(seletedChapterIds.join(", "));
  // 	// console.log(seletedChapterIds.map((obj1)=> obj1.join(", ")));
  // 	// console.log(seletedChapterIds)
  // },[seletedChapterIds])

  // const handleChapterBuyFromCredits = (parameters) => {
  //     addpremiumaccess(user, chapterData)
  //         .then(() => {
  //             updateusercredits(user)
  //                 .then((resp) => {
  //                     linkedEvaluatedCode({ id: 1, subtopicChange: parameters })
  //                     return resp.data;
  //                 })
  //                 .catch((err) => console.log(err));
  //         })
  //         .catch((err) => console.log(err));

  return (
    <div className="buyPremiumModalContainer credit">
      {isLoaded && (
        <div className="chapterPremiumPricingModal credit">
          <div className="selectedChapterPremiumInformationDiv credit">
            <div
              className="closebuttonForPremiumModal"
              name="btn-close"
              onClick={handleClose}
            >
              <img src={closeBtnIcon} alt="" name="btn-close" />
            </div>
            <div className="singleChapterTitleForPremium credit">
              Select a chapter to Unlock
            </div>
            <div className="gradiantLineForPremiumModal">
              {" "}
              <img src={underScoreIcon} alt="separatorIcon" />
            </div>
            <div className="subTitleForThePremiumModal use-credits">
              You can unlock a new chapter using your credits.
              <br />
              <span className="app-accented">1 Credits = 1 Chapter </span>
            </div>
            {isLoaded2 ? (
              <>
                <div className="useCredits__chapters">
                  {nonPurchasedChapterList?.map((chap, ind) => {
                    return (
                      <BuyChaptersList
                        key={ind}
                        chap={chap}
                        ind={ind}
                        seletedChapterIds={seletedChapterIds}
                        setSelectedChapterIds={setSelectedChapterIds}
                        selectedCount={selectedCount}
                        setSelectedCount={setSelectedCount}
                      />
                    );
                  })}
                </div>
                <div className="useCredit__seperation"></div>
                <div className="useCredits__BottomContainer">
                  <div className="useCredits__Bottom">
                    <div className="useCredits__BottomButton">
                      <button
                        onClick={handleBuyChapters}
                        className={`app-btn-1 ${
                          userCredit > 0 && selectedCount <= userCredit
                            ? ""
                            : "creditDisabled"
                        }`}
                        disabled={!disabled}
                      >
                        {userCredit > 0 && selectedCount <= userCredit
                          ? `Use ${selectedCount}/${userCredit}`
                          : "Not Enough Credits"}
                      </button>
                    </div>
                    <div className="useCredits__BottomChapters">
                      {selectedCount !== 0 ? selectedCount : "No"}{" "}
                      {selectedCount <= 1 ? "Chapter" : "Chapters"} Selected
                    </div>
                  </div>
                </div>
              </>
            ) : (
              <>
                <br />
                <br />
                <p>Processing your Purchase...</p>
                <br />
                <br />
                <br />
              </>
            )}
          </div>
        </div>
      )}
    </div>
  );
};

export default UseCredits;
