import axios from 'axios'

const updateusercredits = async (user, creditPoints="1") => {
    const apiUrl = `https://lkbe8ay3y7.execute-api.ap-northeast-1.amazonaws.com/updateUserCredits/updateusercredits?UserID=${user}&CreditPoints=-${creditPoints}`

    const options = {
        headers: {
            "Content-Type": "application/x-www-form-urlencoded",
        },
    };

    try {
        const response = await axios.get(apiUrl, options);
        return response
    } catch {
        throw new Error("An error occurred during the API request.");
    }

}

export default updateusercredits