import React, { useState } from "react";
import "./componentTwo.css";
import { arrowDown } from "../../../assets/icons";
import BackDrop from "../../ui/backdrop/BackDrop";

const data = [
  { code: "AN", name: "Andaman and Nicobar Islands", capital: "Port Blair" },
  { code: "AP", name: "Andhra Pradesh", capital: "Amaravati" },
  { code: "AR", name: "Arunachal Pradesh", capital: "Itanagar" },
  { code: "AS", name: "Assam", capital: "Dispur" },
  { code: "BR", name: "Bihar", capital: "Patna" },
  { code: "CG", name: "Chhattisgarh", capital: "Raipur" },
  { code: "CH", name: "Chandigarh", capital: "Chandigarh" },
  //   { code: 'DN', name: 'Dadra and Nagar Haveli and Daman and Diu', capital: 'Daman' },
  { code: "DL", name: "Delhi", capital: "New Delhi" },
  { code: "GA", name: "Goa", capital: "Panaji" },
  { code: "GJ", name: "Gujarat", capital: "Gandhinagar" },
  { code: "HR", name: "Haryana", capital: "Chandigarh" },
  { code: "HP", name: "Himachal Pradesh", capital: "Shimla" },
  { code: "JK", name: "Jammu and Kashmir", capital: "Srinagar" },
  { code: "JH", name: "Jharkhand", capital: "Ranchi" },
  { code: "KA", name: "Karnataka", capital: "Bengaluru" },
  { code: "KL", name: "Kerala", capital: "Thiruvananthapuram" },
  { code: "LA", name: "Ladakh", capital: "Leh" },
  { code: "LD", name: "Lakshadweep", capital: "Kavaratti" },
  { code: "MP", name: "Madhya Pradesh", capital: "Bhopal" },
  { code: "MH", name: "Maharashtra", capital: "Mumbai" },
  { code: "MN", name: "Manipur", capital: "Imphal" },
  { code: "ML", name: "Meghalaya", capital: "Shillong" },
  { code: "MZ", name: "Mizoram", capital: "Aizawl" },
  { code: "NL", name: "Nagaland", capital: "Kohima" },
  { code: "OR", name: "Odisha", capital: "Bhubaneswar" },
  { code: "PY", name: "Puducherry", capital: "Puducherry" },
  { code: "PB", name: "Punjab", capital: "Chandigarh" },
  { code: "RJ", name: "Rajasthan", capital: "Jaipur" },
  { code: "SK", name: "Sikkim", capital: "Gangtok" },
  { code: "TN", name: "Tamil Nadu", capital: "Chennai" },
  { code: "TS", name: "Telangana", capital: "Hyderabad" },
  { code: "TR", name: "Tripura", capital: "Agartala" },
  { code: "UP", name: "Uttar Pradesh", capital: "Lucknow" },
  { code: "UK", name: "Uttarakhand", capital: "Dehradun" },
  { code: "WB", name: "West Bengal", capital: "Kolkata" },
];

const gradeData = [
  { key: "11th", label: "11th" },
  { key: "12th", label: "12th" },
  { key: "dropper", label: "Dropper" },
];

const attemptData = [
  { key: "attempt_Zero", label: "0" },
  { key: "attempt_One", label: "1" },
  { key: "attempt_Two", label: "2" },
  { key: "attempt_Three", label: "3" },
  { key: "attempt_Three_Plus", label: "3+" },
];

const sscData = [
  { key: "cgl", label: "Combined Graduate level" },
  { key: "chsl", label: "combined higher secondary level" },
  { key: "mts", label: "multitasking staff" },
  { key: "cpo", label: "central police organisation" },
];

const ComponentStatePartTwo = ({
  selectedExam,
  value,
  valueState,
  setValueState,
  selectedGrade,
  setSelectedGrade,
  selectedAttempt,
  setSelectedAttempt,
  selectedExams,
  setSelectedExams,
}) => {
  const [isOpen, setIsOpen] = useState(false);

  const handleToggle = () => {
    setIsOpen(!isOpen);
  };
  return (
    <div className="componentDrops">
      <div className="userdataBodyNAme">State</div>
      <div className="comDrops_stateSelectorDrop">
        <div className="custom-dropdown">
          <div className="dropdown-toggle" onClick={handleToggle}>
            {valueState?.name ? valueState?.name : "Select State"}
            <img
              className="dropdownBtnIconsELECTsTATE"
              name="btn-chapterlist-down"
              src={arrowDown}
              style={{
                transform: isOpen ? "rotate(180deg)" : "rotate(360deg)",
                transition: "transform 0.3s ease-in-out",
              }}
              alt="dropdown-arrow"
            />
          </div>
          {isOpen && (
            <>
              <div className="dropdown-list">
                {data.map((item, index) => (
                  <div
                    key={index}
                    className="dropdown-item"
                    onClick={() => [setValueState(item), setIsOpen(false)]}
                  >
                    {item.name}
                  </div>
                ))}
              </div>
              <BackDrop closeHandler={() => setIsOpen(false)} />
            </>
          )}
        </div>
      </div>
      <div className="userdataBodyNAme">
        {selectedExam === "cat"
          ? "Your Attempts"
          : selectedExam === "ssc"
          ? "Exam Category"
          : "Grade"}
      </div>

      <div
        className={
          "Attemptsselector " + (selectedExam === "ssc" ? "sscAlign" : "")
        }
      >
        {(selectedExam === "cat"
          ? attemptData
          : selectedExam === "ssc"
          ? sscData
          : gradeData
        ).map((obj) => (
          <div
            className={
              "umberSelector " +
              (obj.key == selectedAttempt?.key ? "selectedAttempt" : "")
            }
            onClick={() => setSelectedAttempt(obj)}
          >
            {selectedExam === "ssc" ? (
              <span className="userdataBodyNAmeKEy">
                {obj.key?.toUpperCase()}
              </span>
            ) : null}
            <span className="userdataBodyNAmeKEySub">
              {selectedExam === "ssc" ? `(${obj.label})` : `${obj.label}`}
            </span>
          </div>
        ))}
      </div>
    </div>
  );
};

export default ComponentStatePartTwo;
