// import { width } from 'dom7';
import React, { useEffect, useState } from "react";
import OTPInput from "react-otp-input";

function OtpForm(props) {
  const {
    name,
    onHandleChangeName,
    userInfor,
    setUserName,
    HandleSubmitOtpforSubmisssion,
    otpVerify,
    setOtpVerify,
    autoSubmit,
    setAutoSubmit,
  } = props;

  const handleOnKeyDown = (e) => {
    if (otpVerify?.length === 6 && e.key === "Enter") {
      HandleSubmitOtpforSubmisssion();
    }
  };

  const handleChangeOtpForm = (e) => {
    // //console.log("Aaaaaaaaaaaaaaaaa");
    //console.log(otpVerify);
    //console.log(e);
  };
  // #191341

  const OTPinputStyle = {
    background: "#191341",
    display: "flex",
    width: "clamp(50px, 5vw, 64px)",
  };
  const handlePaste = (event) => {
    const data = event.clipboardData.getData("text");
    setOtpVerify(data);
  };
  // console.log((!(userInfor.length) || !(userInfor[0]?.NAME?.length)), userInfor)

  return (
    <div className="otp-form">
      {/* {(!(userInfor.length) || !(userInfor[0]?.NAME?.length)) ? <>
                <div className="otp-form__title"> Enter Your Name </div>
                <input
                    className="nameInputModalValue"
                    placeholder="Enter Your Name"
                    onChange={(e) => onHandleChangeName(e)}
                />
            </> : ""} */}

      <div className="otp-form__title"> Enter The OTP </div>
      <form
        onKeyDown={handleOnKeyDown}
        onChange={handleChangeOtpForm}
        onSubmit={handleOnKeyDown}
        className="otp-form__inputs-container"
      >
        <OTPInput
          shouldAutoFocus
          value={otpVerify}
          onChange={setOtpVerify}
          onPaste={(e) => handlePaste(e)}
          inputStyle={OTPinputStyle}
          numInputs={6}
          renderSeparator={<div className="otp-form__inputs-seperator"></div>}
          renderInput={(props) => (
            <input
              {...props}
              autocomplete="one-time-code"
              inputMode="decimal"
              className="otp-form__inputs"
            />
          )}
        />
      </form>
      <div
        className="otp-form__btn-container"
        onClick={HandleSubmitOtpforSubmisssion}
      >
        <button
          className={`otp-form__get-started-btn ${
            otpVerify?.length > 4 ? "active" : "inactive"
          }`}
        >
          Submit{" "}
        </button>
      </div>
    </div>
  );
}

export default OtpForm;
