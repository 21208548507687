import React, { useState, useEffect } from 'react';
import ScrollingAnimationComponent from './ScrollingAnimationComponent';
import { ParallaxProvider } from 'react-scroll-parallax';

function SectionEight() {
    const [isSticky, setIsSticky] = useState(true);

    useEffect(() => {
        const handleScroll = () => {
            const scrollingElement = document.scrollingElement || document.documentElement;
            const scrollPosition = scrollingElement.scrollTop;

            if (scrollPosition >= (scrollingElement.scrollHeight - window.innerHeight) / 2) {
                setIsSticky(false);
            } else {
                setIsSticky(true);
            }
        };

        window.addEventListener('scroll', handleScroll);
        return () => {
            window.removeEventListener('scroll', handleScroll);
        };
    }, []);

    return (
        <ParallaxProvider >

        <>
            <div className={`headEightSection originalHead ${isSticky ? ' sticky' : ''} unFoldingErosionRev`}>
                <div className='HeadingTextSection-eight originalHead'>
                    Use &nbsp;
                    <span style={{ color: 'var(--accent-primary-clr)' }}>NioPractice</span>&nbsp;
                    Today!
                </div>
            </div>
            <ScrollingAnimationComponent isSticky={isSticky} setIsSticky={setIsSticky} />
        </>
        </ParallaxProvider>
    );
}

export default SectionEight;
