import { useDispatch, useSelector } from "react-redux";
import { setUserCredit } from "../slice/userCreditSlice";

const useCredit = () => {
    const userCredit = useSelector((state) => state.userCreditStore.userCredit)
    
    const dispatch = useDispatch();

    const setUsersCredit = (updatedData) => {
        dispatch(setUserCredit(updatedData));
    } 

    return { userCredit, setUsersCredit }
}

export default useCredit