import { createContext, useState, useEffect } from "react";

export const PopupContext = createContext({});

const PopupContextProvider = ({ children }) => {

  const [notesAdded, setNotesAdded] = useState(false);
  const [creditDebited, setcreditDebited] = useState(false);
  const [amountOfCreditDebit, setAmountOfCreditDebit] = useState(1);

  useEffect(() => {
    const timeout = setTimeout(() =>{ setcreditDebited(false); setAmountOfCreditDebit(1);}, 5000);
    return () => clearTimeout(timeout);
  }, [creditDebited])

  return (
    <PopupContext.Provider value={{ creditDebited, setcreditDebited, notesAdded, setNotesAdded, amountOfCreditDebit, setAmountOfCreditDebit }}>
      {children}
    </PopupContext.Provider>
  );
};
export default PopupContextProvider;
