import React from "react";
import { nioLogo } from "../../assets/icons";
import { CLLogo } from "../../assets/icons";

function IndependentLogo(props) {
  const { handleClickLogo, subjectualData } = props;

  return (
    <div className="independentLogo">
      <button
        className="header__left header__site-logo header__left__fitWidth"
        onClick={handleClickLogo}
      >
        <img
          className="siteIcon"
          alt="nioclass"
          // src={nioLogo}
          src={
            subjectualData?.logo
              ? subjectualData?.logo === "nio"
                ? nioLogo
                : subjectualData?.logo
              : nioLogo
          }
        />
        {/* <span className="header__part header__site-name">{location.pathname === '/dashboard' ? "dashboard" : "practice"}</span> */}
        <span className="header__part header__site-name">
          {location.pathname === "/dashboard" ? "dashboard" : ""}
        </span>
      </button>
    </div>
  );
}

export default IndependentLogo;
