import { useDispatch, useSelector } from "react-redux";

import { resetActivityStepRecord, setActivityStepRecord } from "../slice/addActivitySymbolize";

function useActivityRecordControl() {

    const dispatch = useDispatch()


    const setActivityModalStep = (value) => {
        // console.log(value)
        dispatch(setActivityStepRecord(value))
    }
    const currentActivityStepRecord = useSelector((state) => state.ActivityStore.activityStepRecordData)

    const resetActivityModalStepRecord = () => {
        dispatch(resetActivityStepRecord())
    }

    return { setActivityModalStep, resetActivityModalStepRecord, currentActivityStepRecord }

}

export default useActivityRecordControl