import { createContext, useState } from "react";

export const FirebaseAuthContext = createContext({});
const FirebaseAuthContextProvider = ({ children }) => {
  const [resultAuth, setResultAuth] = useState({});
  return (
    <FirebaseAuthContext.Provider value={{ resultAuth, setResultAuth }}>
      {children}
    </FirebaseAuthContext.Provider>
  );
};
export default FirebaseAuthContextProvider;
