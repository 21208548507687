import axios from 'axios'

const examSaturation = async () => {
    const apiUrl = "https://ryw9bvqib6.execute-api.ap-northeast-1.amazonaws.com/ExamsSaturations/"

    try {
        const response = await axios.get(apiUrl);
        return response
    } catch {
        throw new Error("An error occurred during the API request.");
    }

}

export default examSaturation