import React, { useEffect, useState } from 'react';

import './chat-box.css';

function ChatBox(props) {

    const { handleMessage, setIsUserTyping, handleMsgBoxScroll } = props;

    const [inputVal, setInputVal] = useState("")

    const handleInputChange = (e) => {
        const val = e.target.value;
        setInputVal(val)
        setIsUserTyping(val.length > 0)
        handleMsgBoxScroll()
    }

    const handleSendBtn = () => {
        handleMsgBoxScroll()
        if (!inputVal) return;
        handleMessage(inputVal)
        setIsUserTyping(false)
        setInputVal('')
    }

    const handleEnterPress = (e) => {
        const key = e.key;
        if (key === 'Enter') {
            handleSendBtn()
            handleMsgBoxScroll()
        }
    }

    useEffect(() => {

        // logic to automatically remove user typing after a period of time. do later
    }, [])

    return (
        <div className='chat-box'>
            <input
                onChange={handleInputChange}
                onKeyDown={handleEnterPress} value={inputVal} />
            <button
                onClick={handleSendBtn}
            > Send </button>
        </div>
    )
}

export default ChatBox