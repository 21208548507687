import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import BackDrop from "../ui/backdrop/BackDrop";

import { usersIconURL, phoneIconURL, closeBtnIcon } from "../../assets/icons";

const nioLogo = "./nioLogo.svg";
// const CLLogo = "./CLLogo.svg";
import { CLLogo } from "../../assets/icons";

function SimpleNavLinks(props) {
  const navigate = useNavigate();

  const {
    handleClickLogo,
    handleStartPracticingBtn,
    subjectualData,
    examPageVisible,
  } = props;

  const [isOpen, setIsopen] = useState(false);

  const ToggleSidebar = () => {
    setIsopen(!isOpen);
  };

  const handleNavClick = (destinationPath) => {
    navigate(destinationPath);
    setIsopen(false);
  };

  const handleSignIn = () => {
    navigate("/sign-in", { state: { defaultLogin: true } });
  };

  const handleChatBtn = () => {
    setOpenChatbot(true);
  };

  return (
    <div className="header-container">
      <button
        className="header__left header__site-logo header__left__fitWidth"
        onClick={handleClickLogo}
      >
        <img
          className="siteIcon"
          alt="nioclass"
          // src={nioLogo}
          src={
            subjectualData?.logo
              ? subjectualData?.logo === "nio"
                ? nioLogo
                : subjectualData?.logo
              : nioLogo
          }
        />
        {/* <span className="header__part header__site-name">{location.pathname === '/dashboard' ? "dashboard" : "practice"}</span> */}
        <span className="header__part header__site-name">
          {location.pathname === "/dashboard" ? "dashboard" : ""}
        </span>
      </button>

      <div className="header__right">
        <nav className="navbar__main">
          <button className="navbar_link" onClick={() => navigate("/about-us")}>
            <div>About Us</div>
          </button>

          <button
            className="navbar_link"
            onClick={() => navigate("/contact-us")}
          >
            <div>Contact</div>
          </button>

          {!examPageVisible &&
            !(
              location.pathname === "/chapter-selection" ||
              location.pathname === "/chapter-trial" ||
              location.pathname === "/deck-completion" ||
              location.pathname === "/chapter-detail" ||
              location.pathname === "/sign-in"
            ) && (
              <button
                className="app-btn-1 small"
                // onClick={handleStartPracticingBtn}
                onClick={handleSignIn}
              >
                Start Practicing!
              </button>
            )}

          {/* {!(location.pathname === '/sign-in') && <button className="app-btn-1-reverse hide-small" onClick={handleSignIn}>
                        Log In
                    </button>} */}
        </nav>

        <div className="header__menu-btn" onClick={ToggleSidebar}>
          ☰
        </div>
      </div>

      {isOpen ? (
        <nav className={`navbar__slider ${isOpen ? "show" : "hide"} `}>
          <div className="navbar__slider__menu-container">
            <div className="">
              <button className="navbar__slider__close" onClick={ToggleSidebar}>
                <img src={closeBtnIcon} alt="" />
              </button>

              <div className="navbar__slider__menu-item">
                <img src={usersIconURL} />
                <button onClick={() => handleNavClick("/about-us")}>
                  {" "}
                  About Us
                </button>
              </div>

              <div className="navbar__slider__menu-item">
                <img src={phoneIconURL} />
                <button onClick={() => handleNavClick("/contact-us")}>
                  Contact
                </button>
              </div>
            </div>

            <div className="navbar__slider__btn-container">
              <button
                className="navbar__slider__menu-item log-out-btn"
                onClick={handleSignIn}
              >
                Log In
              </button>
            </div>
          </div>
        </nav>
      ) : (
        ""
      )}

      {isOpen && <BackDrop closeHandler={() => setIsopen(false)} />}
    </div>
  );
}

export default SimpleNavLinks;
