import toast from "react-hot-toast";
import axios from "axios";

//payment script
function loadScript(src) {
    return new Promise((resolve) => {
        const script = document.createElement("script");
        script.src = src;
        script.onload = () => {
            resolve(true);
        };
        script.onerror = () => {
            resolve(false);
        };
        document.body.appendChild(script);
    });
}

// Function to create order and display razorpay prompt
async function displayRazorpay(userid, chpid, price, auth) {
    const res = await loadScript(
        "https://checkout.razorpay.com/v1/checkout.js"
    );

    if (!res) {
        alert("Razorpay SDK failed to load. Are you online?");
        return;
    }

    try {
        // axios.geting the order id
        return axios
            .get(
                "https://x0xuqi1pc9.execute-api.ap-northeast-1.amazonaws.com/CreateOrder/createorder?UserID=" +
                userid +
                "&ChapterID=" +
                chpid +
                "&Amount=" +
                price,
                {
                    headers: {
                        "Content-Type": "application/x-www-form-urlencoded",
                    },
                }
            )
            .then((resp) => {
                if (!resp.data) {
                    alert("Server error. Are you online?");

                    return;
                }

                // Getting the order details back
                const { amount, id: order_id, currency } = resp.data;

                // options for the razorpay prompt
                const options = {
                    // key: "rzp_test_t3fpjUZX7hPAXf", // Enter the Key ID generated from the Dashboard
                    key: "rzp_live_sZm7m0BX3jHSOp",
                    amount: amount.toString(),
                    currency: currency,
                    name: "NIOPRACTICE",
                    description: "Transaction",
                    order_id: order_id,
                    prefill: {
                        contact: auth.currentUser.phoneNumber
                            ? auth.currentUser.phoneNumber
                            : "9999900000",
                        email: auth.currentUser.email
                            ? auth.currentUser.email
                            : "user@gmail.com",
                    },
                    handler: async function (response) {

                        toast.success("Payment Successfull");
                        try {
                            axios
                                .get(
                                    "https://ohu6hwihm9.execute-api.ap-northeast-1.amazonaws.com/addPremiumAccess/addpremiumaccess?UserID=" +
                                    userid +
                                    "&PaymetOptions=RazorPay&ChapterID=" +
                                    chpid,
                                    {
                                        crossorigin: true,
                                        mode: "no-cors",
                                        headers: {
                                            "Content-Type":
                                                "application/x-www-form-urlencoded",
                                        },
                                    }
                                )
                                .then((resp) => {
                                    return resp.data;
                                })
                                .catch((err) =>
                                    console.log("caught err " + err)
                                );
                        } catch (e) {
                            toast.error("Payment Failed");
                            console.log("caught err" + e.message);
                        }
                    },
                    theme: {
                        color: "#61dafb",
                    },
                };

                const paymentObject = new window.Razorpay(options);
                paymentObject.open();
            })
            .catch((err) => console.log(err));
    } catch (e) {
        toast.error("Payment Failed");
        console.log("caught err" + e.message);
    }
}

// Function called when user click on buy premium button
export async function addPremiumChapter(userid, chpid, price, auth) {
    // alert(`${userid} clicked ${chpid}`);

    await displayRazorpay(userid, chpid, price * 100, auth);
}

// link is similar to getChapterMetalist of ChapterSelectionScreen.js

//1. getAllChaptersMeta
//2. getAllCHaptersPremium
//3. getUserCredits
//4. getUnattemptedQuestionNumber
//5. getReviewGraphByUser
//6. getInitilizedChapter or getCHapterlist
//7. getLastChapterttempted
//8. getLastChapterWiseTime
//9. getTotalUniqueQuestions
//10. getUnattemptedQuestionNumber
// const allURL = [
//     [
//         "https://7xb527e9w1.execute-api.ap-northeast-1.amazonaws.com/getAllChapterMEtadata/getallchaptermetadata",
//     ],
//     [
//         `https://kqd7mc4a26.execute-api.ap-northeast-1.amazonaws.com/getPremiumChapters/getpremiumchapters?UserID=` +
//             userid,
//     ],
//     [
//         "https://sqa1zros3i.execute-api.ap-northeast-1.amazonaws.com/getUserCredits/getusercredits?UserID=" +
//             userid,
//     ],
//     [
//         "https://yoh3xeyt4m.execute-api.ap-northeast-1.amazonaws.com/getNumberOfQuestionsInChapter/getnumberofquestionsinchapter?ChapterID=" +
//             chpid,
//     ],
//     [
//         "https://c29swv5kmg.execute-api.ap-northeast-1.amazonaws.com/getReviewGraph/getreviewgraph?UserID=" +
//             userid,
//     ],
//     [
//         `https://p4r1inhvs3.execute-api.ap-northeast-1.amazonaws.com/getInitaitedChapterIDs/getinitiatedchapterids?UserID=` +
//             userid,
//     ],
//     [
//         `https://a8arxiabqb.execute-api.ap-northeast-1.amazonaws.com/getLastChapterAttempted/getlastchapterattempted?UserID=` +
//             userid,
//     ],
//     [
//         "https://3zbl4cst14.execute-api.ap-northeast-1.amazonaws.com/getchapterwisetime/getchapterwisetime?UserID=" +
//             userid,
//     ],
//     [
//         "https://zhxvcqlrf7.execute-api.ap-northeast-1.amazonaws.com/getNumberOfUniqueQuestions/getnumberofuniquequestions?UserID=" +
//             userid,
//     ],
//     [
//         "https://q4ftpjfd13.execute-api.ap-northeast-1.amazonaws.com/getUnattemptedQuestions/getunattemptedquestions?UserID=" +
//             userid,
//     ],
//     [
//         "https://181talpgtl.execute-api.ap-northeast-1.amazonaws.com/logPremiumAccess/logpremiuminterest?UserID=" +
//             userid +
//             "&Stage=" +
//             num,
//     ],
//     [
//         "https://9csqyfdrjk.execute-api.ap-northeast-1.amazonaws.com/getChapterwiseGraph/getchapterwisegraph?UserID=" +
//             userid +
//             "&ChapterID=" +
//             chapter,
//     ],
//     [
//         "https://q8rqzslgl0.execute-api.ap-northeast-1.amazonaws.com/getAttemptedQuestionNumber/getattemptedchapterquestionnumber?UserID=" +
//             userid +
//             "&ChapterID=" +
//             chapter,
//     ],
//     [
//         "https://lkbe8ay3y7.execute-api.ap-northeast-1.amazonaws.com/updateUserCredits/updateusercredits?UserID=" +
//             userid +
//             "&CreditPoints=-1",
//     ],
//     [
//         "https://ohu6hwihm9.execute-api.ap-northeast-1.amazonaws.com/addPremiumAccess/addpremiumaccess?UserID=" +
//             userid +
//             "&ChapterID=" +
//             chpid,
//     ],
//     [
//         "https://qjcdh5hfpd.execute-api.ap-northeast-1.amazonaws.com/getChapterReviewDeck/getchapterreviewdeck?UserID=" +
//             userid +
//             "&ChapterID=" +
//             chapter,
//     ],
// ];
//last on prepraReviewDeckAndLaunch
export function newLineDisplay(std) {
    let new_string = [];
    // //console.log(std)
    // if (!std.include(`\begin{`))
    if (
        // !(std?.includes("\\begin{") && std?.includes("\\end{")) &&
        std !== undefined &&
        std !== null
    ) {
        // //console.log(std.split('\\\\'))
        let stdi = std.replaceAll(`\\\\`, `$\\\\$`);
        // //console.log("ex", stdi);
        let ex = stdi.split(`\\\\`);
        if ((std?.includes("\\begin{") && std?.includes("\\end{"))) {
            ex = std.split(`$\\\\$`);
        }
        // //console.log(ex, "----------");
        // //console.log(ex,"------------------",std)
        ex = ex.filter((str) => str !== "");
        let string = ex;
        // function removeOddIndexCharacters(string)
        // {

        // Stores the resultant string
        // //console.log(string[string?.length-1])

        // for (let i = 0; i < string?.length; i++) {

        //     // If current index is odd
        //     // if (i % 2 === 1) {

        //         // ading the character

        //         // }

        //         // Otherwise, append the
        //         // charactr
        //         let count=0

        //         // //console.log(count,"OKKOKKKK")
        //         // if(string[i]?.length===2||1)
        //         // {
        //         //     //console.log("=============",string[i])
        //         // }
        //        if(((string[2]!==`$`)||(string[0]!==`$`)||(string[1]!==`$`))&&(string[string[i]?.length-1]!==`$`))
        //         {

        //             string[i]=`$`+string[i]+`$`
        //             // //console.log(string[i]?.length)
        //         }
        //        else if(((string[2]==='$')||(string[0]===`$`)||(string[1]===`$`))&&(string[string[i]?.length-1]!==`$`))
        //         {

        //             // //console.log(string[i]?.length)
        //             // else
        //             string[i]=string[i]+`$`
        //         }
        //       else  if(((string[2]!=='$')||(string[0]!==`$`)||(string[1]!==`$`))&&(string[string[i]?.length-1]===`$`))
        //         {
        //             // if(count%2===0){
        //             //     string[i]=`$`+string[i]+'$.'
        //             // }
        //             // //console.log(string[i]?.length)
        //             // else
        //             string[i]=`$`+string[i]
        //         }
        //        else
        //         {
        //             // if(count%2===0){
        //             //     string[i]=`$`+string[i]
        //             // }
        //             // //console.log(string[i]?.length)

        //             // string[i]=string[i]
        //         }
        //         // if(string[i]===`$ $$`)
        //         // {
        //             //     string[i]=`$$`
        //             // }
        //             // //console.log(string[i]?.length,string[i])
        //             // //console.log(string[i].indexOf(`$`),string[i])

        //             string[i]=string[i].replaceAll("$$",`$`)
        //             for(let j = 0; j < string[i]?.length; j++)
        //             {
        //                 if(string[i][j]===`$`)
        //                 count++;
        //             }
        //             if(count%2!==0 )
        //             {

        //                 string[i]=string[i]+`$`
        //             }
        //             new_string.push(string[i]);
        //         new_string.push("");
        // }

        // Return the result
        return string;
    } else {
        new_string.push(std);
        return new_string;
    }
    // }
}
export function newLineDisplayforTest(std) {
    let new_string = [];
    // //console.log(std)
    // if (!std.include(`\begin{`))
    // console.log(std)
    if (
        // !(std?.includes("\\begin{") && std?.includes("\\end{")) &&
        std !== undefined &&
        std !== null
    ) {
        // //console.log(std.split('\\\\'))
        // let stdi = std.replaceAll(`\\\\`, `$\\\\$`);
        // //console.log("ex", stdi);
        // let ex = stdi.split(`\\\\`);
        // if ((std?.includes("\\begin{") && std?.includes("\\end{"))) {
        let ex = std?.split(`$\\\\$`);
        // }
        // //console.log(ex, "----------");
        // //console.log(ex,"------------------",std)
        ex = ex.filter((str) => str !== "");
        let string = ex;
        // function removeOddIndexCharacters(string)
        // {

        // Stores the resultant string
        // //console.log(string[string?.length-1])

        // for (let i = 0; i < string?.length; i++) {

        //     // If current index is odd
        //     // if (i % 2 === 1) {

        //         // ading the character

        //         // }

        //         // Otherwise, append the
        //         // charactr
        //         let count=0

        //         // //console.log(count,"OKKOKKKK")
        //         // if(string[i]?.length===2||1)
        //         // {
        //         //     //console.log("=============",string[i])
        //         // }
        //        if(((string[2]!==`$`)||(string[0]!==`$`)||(string[1]!==`$`))&&(string[string[i]?.length-1]!==`$`))
        //         {

        //             string[i]=`$`+string[i]+`$`
        //             // //console.log(string[i]?.length)
        //         }
        //        else if(((string[2]==='$')||(string[0]===`$`)||(string[1]===`$`))&&(string[string[i]?.length-1]!==`$`))
        //         {

        //             // //console.log(string[i]?.length)
        //             // else
        //             string[i]=string[i]+`$`
        //         }
        //       else  if(((string[2]!=='$')||(string[0]!==`$`)||(string[1]!==`$`))&&(string[string[i]?.length-1]===`$`))
        //         {
        //             // if(count%2===0){
        //             //     string[i]=`$`+string[i]+'$.'
        //             // }
        //             // //console.log(string[i]?.length)
        //             // else
        //             string[i]=`$`+string[i]
        //         }
        //        else
        //         {
        //             // if(count%2===0){
        //             //     string[i]=`$`+string[i]
        //             // }
        //             // //console.log(string[i]?.length)

        //             // string[i]=string[i]
        //         }
        //         // if(string[i]===`$ $$`)
        //         // {
        //             //     string[i]=`$$`
        //             // }
        //             // //console.log(string[i]?.length,string[i])
        //             // //console.log(string[i].indexOf(`$`),string[i])

        //             string[i]=string[i].replaceAll("$$",`$`)
        //             for(let j = 0; j < string[i]?.length; j++)
        //             {
        //                 if(string[i][j]===`$`)
        //                 count++;
        //             }
        //             if(count%2!==0 )
        //             {

        //                 string[i]=string[i]+`$`
        //             }
        //             new_string.push(string[i]);
        //         new_string.push("");
        // }

        // Return the result
        return string;
    } else {
        new_string.push(std);
        return new_string;
    }
    // }
}

export async function fetchUrl(url, options) {
    try {
        return axios.get(url, options)
            .then((response) => {
                return response.data;
            }).catch(err => {
                console.log(err)
            });
    } catch (error) {
        console.error(error);
    }
}

export function getSpecificChapterMetaData(
    mychapid,
    allChaptersMetaLoaded,
    allChaptersMeta
) {
    if (allChaptersMetaLoaded && allChaptersMeta?.length > 0) {
        let obj = allChaptersMeta.filter((mychap) => {
            return mychap.ChapterID.trim() === mychapid;
        });
        //console.log(obj);
        return obj;
    }
}

export function getSpecificChapterPremium(
    mychapid,
    allChaptersMetaLoaded,
    allChaptersPremiumLoaded,
    allChaptersMeta,
    allChaptersPremium
) {
    if (
        allChaptersMetaLoaded &&
        allChaptersPremiumLoaded &&
        allChaptersMeta?.length > 0 &&
        allChaptersPremium?.length > 0
    ) {
        let obj = allChaptersPremium.filter((mychap) => {
            return mychap.ChapterID.trim() === mychapid;
        });
        return obj;
    } else return [];
}

// function to change minute to hour-minute format
export function toHoursAndMinutes(totalMinutes) {
    const hours = Math.floor(totalMinutes / 60);
    const minutes = totalMinutes % 60;
    return `${hours}h${minutes > 0 ? ` ${Math.floor(minutes)}m` : ""}`;
}

export function getSpecificChapterProgress(
    mychapid,
    initiatedChaptersLoaded,
    chpprogLoaded,
    chpprog
) {
    if (initiatedChaptersLoaded && chpprogLoaded && chpprog?.length > 0) {
        let obj = chpprog.filter((mychap) => {
            return mychap.chapid.trim() === mychapid;
        });
        return obj;
    } else return null;
}

// function checkChapterProgressAndStartDeck(mychapid) {
//     try {
//         return axios
//             .get(
//                 "https://falpuarwj7.execute-api.ap-northeast-1.amazonaws.com/getChapterStartQuestionID/getchapterstartquestionid?ChapterID=" +
//                     mychapid,
//                 {
//                     headers: {
//                         "Content-Type": "application/x-www-form-urlencoded",
//                     },
//                 }
//             )
//             .then((chapterStartResp) => {
//                 const chapterStartData = chapterStartResp.data;
//                 const mylastques = chapterStartData[0];
//                 try {
//                     return axios
//                         .get(
//                             "https://6h0gl6fat7.execute-api.ap-northeast-1.amazonaws.com/getDeckInProgressAllInfo/getdeckinprogressallinfo?UserID=" +
//                                 userid +
//                                 "&ChapterID=" +
//                                 mychapid,
//                             {
//                                 headers: {
//                                     "Content-Type":
//                                         "application/x-www-form-urlencoded",
//                                 },
//                             }
//                         )
//                         .then((resp) => {
//                             const dekrec = resp.data;
//                             //console.log("deckrecords==", dekrec);

//                             if (dekrec?.length === 0) {
//                                 return axios
//                                     .get(
//                                         `https://f1nohln2og.execute-api.ap-northeast-1.amazonaws.com/getNewDeck/getnewdeck?ChapterID=${mychapid}&QuestionID=${mylastques.AttemptedTillQuestionID}`,
//                                         {
//                                             headers: {
//                                                 "Content-Type":
//                                                     "application/x-www-form-urlencoded",
//                                             },
//                                         }
//                                     )
//                                     .then((response) => {
//                                         const nwdk = response.data;
//                                         //console.log(nwdk);
//                                         if (nwdk?.length === 0) {
//                                             notifyNoQuestions();
//                                             return;
//                                         }
//                                         navigate("/chapter-practice", {
//                                             state: {
//                                                 ChapterID: mychapid,
//                                                 QuestionIDs: nwdk,
//                                                 AttemptedTill:
//                                                     mylastques.AttemptedTillQuestionID,
//                                             },
//                                         });
//                                     });

//                                 //   const myurlstr = `https://donvtn5h2b.execute-api.ap-northeast-1.amazonaws.com/addDeckHistoryRecord/adddeckhistoryrecord?UserID=${userid}&DeckID=${userid}_${new Date().toISOString().slice(0, 19).replace("T", " ")}&ChapterID=${mychapid}&DeckStartTime=${new Date().toISOString().slice(0, 19).replace("T", " ")}&DeckQuestionIDs=${nwdk.map((x) => x.QuestionID).join(",")}&LastQuestionIDAttempted=${mylastques.AttemptedTillQuestionID}&DeckCompleted=0&DeckExitTime=${new Date().toISOString().slice(0, 19).replace("T", " ")}&PercentageChapterCompletionScore=0.0`;

//                                 //   const addDeckHistoryResponse = await axios.get(myurlstr, {
//                                 //
//                                 //     headers: {
//                                 //       "Content-Type": "application/x-www-form-urlencoded",
//                                 //     },
//                                 //   });

//                                 //   const addDeckHistoryData = await addDeckHistoryResponse.data;
//                             }

//                             if (dekrec?.length >= 1) {
//                                 const arr =
//                                     dekrec[0].DeckQuestionIDs.split(",");

//                                 const obj3 = [];

//                                 arr.forEach((element, index) => {
//                                     obj3.push({ QuestionID: element });
//                                 });

//                                 if (dekrec[0].DeckQuestionIDs === "") {
//                                     notifyNoQuestions();
//                                     return;
//                                 } else {
//                                     navigate("/chapter-practice", {
//                                         state: {
//                                             ChapterID: mychapid,
//                                             QuestionIDs: obj3,
//                                             AttemptedTill:
//                                                 mylastques.AttemptedTillQuestionID,
//                                         },
//                                     });
//                                 }
//                             }
//                         });
//                 } catch (error) {
//                     console.error(error);
//                 }
//             });

//         // const lastqstr =
//         //     "https://q03u3s13w3.execute-api.ap-northeast-1.amazonaws.com/getLastQuestion/getlastquestion?UserID=" +
//         //     userid +
//         //     "&ChapterID=" +
//         //     mychapid;

//         // const lastqstrResp = await fetch(lastqstr, {
//         //     method: "GET",
//         //     headers: {
//         //         "Content-Type": "application/x-www-form-urlencoded",
//         //     },
//         // });

//         // const lastqstrData = await lastqstrResp.json();
//     } catch (e) {
//         console.log("caught err" + e.message);
//     }
// }
