import React from 'react'

const B2 = 'https://niopracticevideos.s3.ap-northeast-1.amazonaws.com/NioPractice_B1_Web.gif';
const B2Mobile = "https://niopracticevideos.s3.ap-northeast-1.amazonaws.com/NioPractice_B1_Mob.gif";

function SectionOne() {
    return (
        <section id="SectionOne">

            <div className="section-one" >
                <div className='section-one__img-bg app-img-border-rotate'>

                    <img className='section-one__img hide-small imageRecordSectTen' src={B2} alt="landing-img-2" />
                    <img className='section-one__img hide-big' src={B2Mobile} alt="landing-img-2" />

                </div>
                <div className="section-one__container">
                    <div className='widthNumberification unFoldingErosion'>

                        1
                    </div>
                    <h2 className='section-one__title' style={{ color: "#2F99D7" }}> {"Crack problems in < 120 second"} </h2>
                    <p className="section-one__sub-title">
                        {"Learn to think faster, use the right approach, and solve problems in < 120 seconds."}
                    </p>
                </div>

            </div>
            {/* <svg xmlns="http://www.w3.org/2000/svg" width="827" height="369" viewBox="0 0 827 369" fill="none">
                <path opacity="0.5" d="M2 0V282.5H824.5V369" stroke="#6F5FDB" />
            </svg> */}
        </section>
    )
}

export default SectionOne