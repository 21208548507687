import { createSlice } from "@reduxjs/toolkit";

const initialState = {
    currentChapterName: 'chapter name',
    selectedChapterId: ''
}

const currentChapterSlice = createSlice({
    name: 'currentChapter',
    initialState: initialState,
    reducers: {
        set: (state, action) => {
            state.currentChapterName = action.payload;
        },
        setId: (state, action) => {
            state.selectedChapterId = action.payload;
        },
    }
})

export default currentChapterSlice.reducer
export const { set, setId } = currentChapterSlice.actions