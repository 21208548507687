const countryData = [
    {
        id:"India",
        name:"India",
        code:"+91",
        imgURL:"https://countryflagsv2.s3.ap-northeast-1.amazonaws.com/in.svg",
    },
    {
        id:"United States",
        name:"United States",
        code:"+1",
        imgURL:"https://countryflagsv2.s3.ap-northeast-1.amazonaws.com/us.svg",
    },
    {
        id:"China",
        name:"China",
        code:"+86",
        imgURL:"https://countryflagsv2.s3.ap-northeast-1.amazonaws.com/cn.svg",
    },{
        id:"Argentina",
        name:"Argentina",
        code:"+54",
        imgURL:"https://countryflagsv2.s3.ap-northeast-1.amazonaws.com/ar.svg",
    },
    {
        id:"Australia",
        name:"Australia",
        code:"+61",
        imgURL:"https://countryflagsv2.s3.ap-northeast-1.amazonaws.com/au.svg",
    },
    {
        id:"Austria",
        name:"Austria",
        code:"+43",
        imgURL:"https://countryflagsv2.s3.ap-northeast-1.amazonaws.com/at.svg",
    },
    {
        id: 'bangkok',
        name: "Bangkok",
        code: "+66",
        imgURL: "https://upload.wikimedia.org/wikipedia/commons/thumb/b/b6/Flag_of_Bangkok.svg/200px-Flag_of_Bangkok.svg.png"

    },
    {
        id:"Bangladesh",
        name:"Bangladesh",
        code:"+880",
        imgURL:"https://countryflagsv2.s3.ap-northeast-1.amazonaws.com/bd.svg",
    },
    {
        id:"Belgium",
        name:"Belgium",
        code:"+32",
        imgURL:"https://countryflagsv2.s3.ap-northeast-1.amazonaws.com/be.svg",
    },
    {
        id:"Brazil",
        name:"Brazil",
        code:"+55",
        imgURL:"https://countryflagsv2.s3.ap-northeast-1.amazonaws.com/br.svg",
    },
    {
        id:"Canada",
        name:"Canada",
        code:"+1",
        imgURL:"https://countryflagsv2.s3.ap-northeast-1.amazonaws.com/ca.svg",
    },
    {
        id:"Chile",
        name:"Chile",
        code:"+56",
        imgURL:"https://countryflagsv2.s3.ap-northeast-1.amazonaws.com/cl.svg",
    },
    {
        id:"Colombia",
        name:"Colombia",
        code:"+57",
        imgURL:"https://countryflagsv2.s3.ap-northeast-1.amazonaws.com/co.svg",
    },
    {
        id:"Egypt",
        name:"Egypt",
        code:"+20",
        imgURL:"https://countryflagsv2.s3.ap-northeast-1.amazonaws.com/eg.svg",
    },
    {
        id:"France",
        name:"France",
        code:"+33",
        imgURL:"https://countryflagsv2.s3.ap-northeast-1.amazonaws.com/fr.svg",
    },
    {
        id:"Germany",
        name:"Germany",
        code:"+49",
        imgURL:"https://countryflagsv2.s3.ap-northeast-1.amazonaws.com/de.svg",
    },
    {
        id:"Hong Kong",
        name:"Hong Kong",
        code:"+852",
        imgURL:"https://countryflagsv2.s3.ap-northeast-1.amazonaws.com/hk.svg",
    },
    {
        id:"Indonesia",
        name:"Indonesia",
        code:"+62",
        imgURL:"https://countryflagsv2.s3.ap-northeast-1.amazonaws.com/id.svg",
    },
    {
        id:"Iran",
        name:"Iran",
        code:"+98",
        imgURL:"https://countryflagsv2.s3.ap-northeast-1.amazonaws.com/ir.svg",
    },
    {
        id:"Italy",
        name:"Italy",
        code:"+39",
        imgURL:"https://countryflagsv2.s3.ap-northeast-1.amazonaws.com/it.svg",
    },
    {
        id:"Japan",
        name:"Japan",
        code:"+81",
        imgURL:"https://countryflagsv2.s3.ap-northeast-1.amazonaws.com/jp.svg",
    },
    {
        id:"Malaysia",
        name:"Malaysia",
        code:"+60",
        imgURL:"https://countryflagsv2.s3.ap-northeast-1.amazonaws.com/my.svg",
    },
    {
        id:"Mexico",
        name:"Mexico",
        code:"+52",
        imgURL:"https://countryflagsv2.s3.ap-northeast-1.amazonaws.com/mx.svg",
    },
    {
        id:"Netherlands",
        name:"Netherlands",
        code:"+31",
        imgURL:"https://countryflagsv2.s3.ap-northeast-1.amazonaws.com/nl.svg",
    },
    {
        id:"New Zealand",
        name:"New Zealand",
        code:"+64",
        imgURL:"https://countryflagsv2.s3.ap-northeast-1.amazonaws.com/nz.svg",
    },
    {
        id:"Pakistan",
        name:"Pakistan",
        code:"+92",
        imgURL:"https://countryflagsv2.s3.ap-northeast-1.amazonaws.com/pk.svg",
    },
    {
        id:"Philippines",
        name:"Philippines",
        code:"+63",
        imgURL:"https://countryflagsv2.s3.ap-northeast-1.amazonaws.com/ph.svg",
    },
    {
        id:"Saudi Arabia",
        name:"Saudi Arabia",
        code:"+966",
        imgURL:"https://countryflagsv2.s3.ap-northeast-1.amazonaws.com/sa.svg",
    },
    {
        id:"Singapore",
        name:"Singapore",
        code:"+65",
        imgURL:"https://countryflagsv2.s3.ap-northeast-1.amazonaws.com/sg.svg",
    },
    {
        id:"South Africa",
        name:"South Africa",
        code:"+27",
        imgURL:"https://countryflagsv2.s3.ap-northeast-1.amazonaws.com/za.svg",
    },
    {
        id:"South Korea",
        name:"South Korea",
        code:"+82",
        imgURL:"https://countryflagsv2.s3.ap-northeast-1.amazonaws.com/kr.svg",
    },
    {
        id:"Taiwan",
        name:"Taiwan",
        code:"+886",
        imgURL:"https://countryflagsv2.s3.ap-northeast-1.amazonaws.com/tw.svg",
    },
    {
        id:"Turkey",
        name:"Turkey",
        code:"+90",
        imgURL:"https://countryflagsv2.s3.ap-northeast-1.amazonaws.com/tr.svg",
    },
    {
        id:"United Arab Emirates",
        name:"United Arab Emirates",
        code:"+971",
        imgURL:"https://countryflagsv2.s3.ap-northeast-1.amazonaws.com/ae.svg",
    },
    {
        id:"United Kingdom",
        name:"United Kingdom",
        code:"+44",
        imgURL:"https://countryflagsv2.s3.ap-northeast-1.amazonaws.com/uk.svg",
    },
    {
        id:"Vietnam",
        name:"Vietnam",
        code:"+84",
        imgURL:"https://countryflagsv2.s3.ap-northeast-1.amazonaws.com/vn.svg",
    },

];

export default countryData;