import React, { useState, useEffect, useRef } from 'react'

function SectionFour() {

    const targetRef = useRef(null)

    const [isIntersecting, setIsIntersecting] = useState(false);
    const [isCounterAnimOn, setisSpeedCounterAnimOn] = useState(false)
    const [isAnim, setIsAnim] = useState(false)

    const [speedCount, setSpeedCount] = useState(0);
    const [timeCount, setTimeCount] = useState(0);
    const [accuractCount, setAccuractCount] = useState(0);

    const speedCountTarget = 76;
    const timeCountTarget = 45;
    const accuracyCountTarget = 34;

    const animationDuration = 2000; // 2 seconds
    const intervalDuration1 = animationDuration / speedCount;
    const intervalDuration2 = animationDuration / timeCount;
    const intervalDuration3 = animationDuration / accuractCount;

    const startSpeedCounterAnimation = () => {
        let currentCount = 0;
        const intervalId = setInterval(() => {
            currentCount++;
            setSpeedCount(currentCount);
            if (currentCount >= speedCountTarget) {
                clearInterval(intervalId);
            }
        }, intervalDuration1);

        return () => {
            clearInterval(intervalId);
        };
    };

    const startTimeCounterAnimation = () => {
        let currentCount = 0;
        const intervalId = setInterval(() => {
            currentCount++;
            setTimeCount(currentCount);
            if (currentCount >= timeCountTarget) {
                clearInterval(intervalId);
            }
        }, intervalDuration2);

        return () => {
            clearInterval(intervalId);
        };
    };


    const startAccuracyCounterAnimation = () => {
        let currentCount = 0;
        const intervalId = setInterval(() => {
            currentCount++;
            setAccuractCount(currentCount);
            if (currentCount >= accuracyCountTarget) {
                clearInterval(intervalId);
            }
        }, intervalDuration3);

        return () => {
            clearInterval(intervalId);
        };
    };


    useEffect(() => {
        if (isIntersecting && !isAnim) {
            startSpeedCounterAnimation()
            startTimeCounterAnimation()
            startAccuracyCounterAnimation()
            // setisSpeedCounterAnimOn(true)
            setIsAnim(true)
        }

    }, [isIntersecting])

    useEffect(() => {
        const observer = new IntersectionObserver(
            ([entry]) => {
                setIsIntersecting(entry.isIntersecting);
            },
            {
                root: null,
                rootMargin: '0px',
                threshold: 1
            }
        );

        if (targetRef.current) {
            observer.observe(targetRef.current);
        }

        return () => {
            if (targetRef.current) {
                observer.unobserve(targetRef.current);
            }
        };
    }, []);

    return (
        <div className="section-four" ref={targetRef}>
            <div className='section-four__container'>
                <h2 className={`section-four__title ${isCounterAnimOn ? 'anim' : ''}`}>{speedCount}%</h2>
                <h3 className="section-four__sub-title">Average speed improvement</h3>
            </div>
            <div className='section-four__container'>
                <h2 className={`section-four__title ${isCounterAnimOn ? 'anim' : ''}`}> {timeCount} Mins</h2>
                <h3 className="section-four__sub-title">Average time saved per day!</h3>
            </div>
            <div className='section-four__container'>
                <h2 className={`section-four__title ${isCounterAnimOn ? 'anim' : ''}`}>{accuractCount}%</h2>
                <h3 className="section-four__sub-title">Average Accuracy improved</h3>
            </div>
        </div>
    )
}

export default SectionFour