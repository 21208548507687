const hambergerMenuIconURL = 'https://niopracticevideos.s3.ap-northeast-1.amazonaws.com/hambergermenu.svg';

const nioLogo = "https://niopracticevideos.s3.ap-northeast-1.amazonaws.com/nioLogo.svg"
// const dbImg = "https://niopracticevideos.s3.ap-northeast-1.amazonaws.com/dbimg.png"
const dbImg = "https://niopracticevideos.s3.ap-northeast-1.amazonaws.com/Landing+page+hero.png"
const GateDBImg = "https://niopracticevideos.s3.ap-northeast-1.amazonaws.com/Screenshot+(30).png"
const PYQDBImg = "https://niopracticevideos.s3.ap-northeast-1.amazonaws.com/Add+a+subheading+(1).jpg"
// const dbImg2 = "https://niopracticevideos.s3.ap-northeast-1.amazonaws.com/myDashboard2.png"
const dbImg2 = "https://niopracticevideos.s3.ap-northeast-1.amazonaws.com/Landing+page+hero.png"
const img1 = "https://niopracticevideos.s3.ap-northeast-1.amazonaws.com/img1.png"
const img2 = "https://niopracticevideos.s3.ap-northeast-1.amazonaws.com/img2.png"
const img3 = "https://niopracticevideos.s3.ap-northeast-1.amazonaws.com/img3.png"
const img4 = "https://niopracticevideos.s3.ap-northeast-1.amazonaws.com/img4.png"

const manavProfile = "https://niopracticevideos.s3.ap-northeast-1.amazonaws.com/mem1.png"
const himanshuProfile = "https://niopracticevideos.s3.ap-northeast-1.amazonaws.com/mem2.png"
const kunalProfile = "https://niopracticevideos.s3.ap-northeast-1.amazonaws.com/mem3.png"
const alvinProfile = "https://niopracticevideos.s3.ap-northeast-1.amazonaws.com/mem4.png"
const desktopMobileNioImg = "https://niopracticevideos.s3.ap-northeast-1.amazonaws.com/desktop-mobile-nio-img.svg"

export {
    hambergerMenuIconURL,
    nioLogo,
    dbImg,
    dbImg2,
    GateDBImg,
    PYQDBImg,
    img1,
    img2,
    img3,
    img4,
    manavProfile,
    himanshuProfile,
    kunalProfile,
    alvinProfile,
    desktopMobileNioImg
}