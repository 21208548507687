import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  signINshowPopup: false,
  // selectedChapterId: ''
};

const signinPopup = createSlice({
  name: "signinChapter",
  initialState: initialState,
  reducers: {
    set: (state, action) => {
      state.signINshowPopup = action.payload;
    },
    setPopUp: (state, action) => {
      // //console.log("___________________________", action)
      state.signINshowPopup = action.payload;
    },
  },
});

export default signinPopup.reducer;
export const { set, setPopUp } = signinPopup.actions;
