import React from 'react'
import { dbImg, dbImg2 } from '../../assets/images'
import { manavKImage } from '../../assets/icons'

const vidURL = "https://niopracticevideos.s3.ap-northeast-1.amazonaws.com/finalv+(2).mp4"
function SectionExtra() {
    return (
        <>
            <div>
                <div className='headEightSection section-9 marginationToLanders' style={{ marginTop: "12em", background: "transparent" }}>
                    <div className='HeadingTextSection-eight' style={{ fontSize: "calc(1.5rem + 1vmin)" }}>
                        Meet the creators
                    </div>
                </div>
                {/* <div className='HeadingTextSection-eight GetStartSectionTen' >
                    Get started with NioPractice
                </div> */}

                <div className='SectionTenWalkingThroughGet'>

                    <div className='SectionTenWalkingThroughWala secEx teser'>
                        <img className='imageContainerSecEx' src="https://niopracticevideos.s3.ap-northeast-1.amazonaws.com/IMG_4706.png" />
                        {/* <div className='imageContainerSecEx'>

                            hi
                        </div> */}
                        <div>

                            <div className='imageContainerSecExName'>
                                Manav Kothari
                            </div>
                            <div className='imageContainerSecExNameTit'>
                                Harvard University
                            </div>
                        </div>
                    </div>
                    <div className='SectionTenWalkingThroughWala secEx'>
                        <img className='imageContainerSecEx' src="https://niopracticevideos.s3.ap-northeast-1.amazonaws.com/IMG_4710.png" />
                        {/* <div className='imageContainerSecEx'>

                            hi
                        </div> */}
                        <div className='alignmentSecEX'>

                            <div className='imageContainerSecExName'>
                                Akram Khan
                            </div>
                            <div className='imageContainerSecExNameTit'>
                                IIT-Delhi (JEE 2014 <b>

                                    AIR 2</b> )
                            </div>
                        </div>
                    </div>
                    {/* <div className='SectionTenWalkingThroughWala'>
                        <div className='contentSectionTenWalkingThroughWala'>
                            <span className='spancontentSectionTenWalkingThroughWala'>
                                2
                            </span> &nbsp;
                            Sign Up and Get Started
                        </div>
                    </div> */}
                    {/* <div>

                       
                        <svg className='TouchScreenDotNONEY' xmlns="http://www.w3.org/2000/svg" width="40" height="8" viewBox="0 0 40 8" fill="none">
                            <path d="M0 4H40" stroke="#6F5FDB" stroke-opacity="0.5" strokeWidth="8" stroke-dasharray="8 8" />
                        </svg>
                        <svg className='TouchScreenDot' xmlns="http://www.w3.org/2000/svg" width="4" height="74" viewBox="0 0 4 74" fill="none">
                            <path className='svgPathResultsSectionTen' fill="none" d="M2 72 0 0 72" />
                        </svg>
                    </div>
                    <div className='SectionTenWalkingThroughWala'>
                        <div className='contentSectionTenWalkingThroughWala'>
                            <span className='spancontentSectionTenWalkingThroughWala'>
                                3
                            </span> &nbsp;

                            Choose Your Chapter
                        </div>
                    </div> */}
                    {/* <div>

                        <svg className='TouchScreenDotNONEY' xmlns="http://www.w3.org/2000/svg" width="40" height="8" viewBox="0 0 40 8" fill="none">
                            <path d="M0 4H40" stroke="#6F5FDB" stroke-opacity="0.5" strokeWidth="8" stroke-dasharray="8 8" />
                        </svg>
                        <svg className='TouchScreenDot' xmlns="http://www.w3.org/2000/svg" width="4" height="74" viewBox="0 0 4 74" fill="none">
                            <path className='svgPathResultsSectionTen' fill="none" d="M2 72 0 0 72" />
                        </svg>
                    </div>
                    <div className='SectionTenWalkingThroughWala'>
                     
                        <div className='contentSectionTenWalkingThroughWala'>
                            <span className='spancontentSectionTenWalkingThroughWala'>
                                4
                            </span> &nbsp;
                            Dive into Practice
                        </div>
                    </div> */}

                </div>




                {/* <div className='HeadingTextSection-eight videoSightAboutCompoany'>
                    <div className='headvideoSightAboutCompoany'>
                        Still having doubts? Watch this video to get started with
                        <span className='subheadvideoSightAboutCompoany'>
                            <br />NioPractise in 2 mins
                        </span>

                    </div>
                    <div className='HeadingTextSection-eight'>
                        <div className=' videoMakerheadvideoSightAboutCompoany'>
                            <div className='videoContetntSectionTEN'>
                                <div className="" >
                                    <video controls loop poster='https://niopracticevideos.s3.ap-northeast-1.amazonaws.com/Thumbnail+landingpage.jpg' autoPlay={false} muted={false} style={{ borderRadius: "10px" }}
                                    >
                                        <source type="video/mp4" src={vidURL} />
                                        Sorry, your browser doesn't 100tcsopacitysupport
                                        embedded videos.
                                    </video>
                                </div>
                            </div>


                        </div>
                    </div>
                </div> */}
            </div>
        </>
    )
}

export default SectionExtra