import React, { useEffect, useContext, useState } from "react";
import { Navigate, useNavigate } from "react-router-dom";
import { FirebaseContext } from "../../context/FirebaseContext";

import Preloader from "../ui/loading/Preloader";

function ProtectedRoute({
  component: Component,
  user,
  setUser,
  subjectualData,
  enterFullScreen,
  startingAnimation,
  setBounceAnimation,
}) {
  const { auth } = useContext(FirebaseContext);

  const [userIDS, setUserIDs] = useState(null);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const unsubscribe = auth.onAuthStateChanged((user) => {
      setUserIDs(user);
      setLoading(false);
    });
    if (user) {
      setLoading(false);
    }

    return () => {
      unsubscribe();
    };
  }, [auth]);

  if (loading) return <Preloader />;

  return user || userIDS ? (
    startingAnimation !== null ? (
      <Component
        user={user}
        subjectualData={subjectualData}
        enterFullScreen={enterFullScreen}
        startingAnimation={startingAnimation}
        setBounceAnimation={setBounceAnimation}
        setUser={setUser}
      />
    ) : (
      <Component
        user={user}
        subjectualData={subjectualData}
        setUser={setUser}
        enterFullScreen={enterFullScreen}
      />
    )
  ) : window.location?.host?.toLowerCase()?.includes("careerlauncher") ? (
    (window.location.href =
      "https://myzone.careerlauncher.com/mba/sis/test.jsp")
  ) : (
    <Navigate to="/sign-in" replace />
  );
}

export default ProtectedRoute;
