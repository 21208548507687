import axios from "axios";
/*
    This method checks if a chapter has been initialized
    If it is - then check if it is in progress - handle
    accordingly - call checkChapterProgressAndStartDeck
    otherwise - call initializeChapterAndStartDeck

    */


const checkUserExistReturn = async (userId, setCurrentUser, createLocalUserData) => {

    const apiURL = `https://uhuu9h6pef.execute-api.ap-northeast-1.amazonaws.com/getUserProfileDetails/getuserprofiledetails?UserID=${userId}&abc=abc`
    // console.log(apiURL)

    const options = {
        headers: { "Content-Type": "application/x-www-form-urlencoded", },
    }

    try {
        const res = await axios.get(apiURL, options)
        const data = await res.data;
        // console.log(data)
        setCurrentUser(data[0])

        //add user credential to Local Storage - data[0]
        createLocalUserData(data[0])
        if (data.length === 0) return false;
        if (data.length > 0) return true

    } catch (err) {
        console.log("%c:--", 'color: red', err);
    }
}

export default checkUserExistReturn;
