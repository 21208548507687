import useLocalStorage from "./useLocalStorage";

const useLocalUserData = () => {

    const { LSData,
        setLocalStorage,
        removeLocalStorage,
        updateLocalStorage } = useLocalStorage("NioClassCurrentUser");

    const createLocalUserData = (data) => {
        setLocalStorage({
            isAuthenticate: true,
            phoneNo: data?.PhoneNumber,
            name: data?.NAME,
            timestamp: Date.now(),
            visitCount: 0
        });
    }

    const createAnonymousLocalUserData = (data) => {
        if (LSData.visitCount > 0) return
        setLocalStorage({
            isAuthenticate: false,
            name: 'anonymous',
            timestamp: Date.now(),
            visitCount: 0,
        });
    }

    const removeLocalUserData = () => {
        removeLocalStorage('NioClassCurrentUser')
    }

    const unAuthenticateLocalUser = () => {
        updateLocalStorage("isAuthenticate", false)
    }

    // const increaseUserVisitCount = () => {

    // }

    const getUserVisitCount = () => LSData.visitCount

    return { createLocalUserData, createAnonymousLocalUserData, removeLocalUserData, unAuthenticateLocalUser, getUserVisitCount }

}


export default useLocalUserData;