import { createSlice } from "@reduxjs/toolkit";

const initialState = {
    userCredit: -1 
}

const userCreditSlice = createSlice({
    name: 'userCreditSlice',
    initialState: initialState,
    reducers:{
        setUserCredit: (state, action) => {
            state.userCredit = action.payload
        }
    }
})

export default userCreditSlice.reducer;
export const { setUserCredit } = userCreditSlice.actions;