function regexPhoneNumber(phone) {
  var phoneRegex =
    /^[\+]?[(]?[0-9]{3}[)]?[-\s\.]?[0-9]{3}[-\s\.]?[0-9]{4,6}$/im;
  if (phoneRegex.test(phone)) {
    return true;
  } else {
    return false;
  }
}

export { regexPhoneNumber };
