import React, { useEffect, useState } from "react";
import "./styles/chapterDropNav.css";
import axios from "axios";
function ChaptersDropNav({
  allSubjects,
  setAllSubjects,
  handleNavClick,
  selectedSubject,
  setSelectedSubject,
}) {
  return (
    <div
      className="dropdown-container"
      style={{
        marginTop: allSubjects?.length < 2 ? "1em" : "2.5em",
        height: allSubjects?.length < 2 ? "80%" : "",
      }}
    >
      {allSubjects?.map((obj, idx) => (
        <div
          data-testid={"ChapterSelecctionnavbarSubjects" + idx}
          className={`option-Selector-Drop ${
            selectedSubject === obj ? "chapterSelector" : ""
          } uniVersalTextRecord`}
          onClick={(e) => (
            setSelectedSubject(obj),
            handleNavClick("/chapter-selection", e, obj)
          )}
        >
          {/* <span className='arrowSizers'>
          ➤
        </span>&nbsp; */}
          {obj}
        </div>
      ))}
      {/* <div className={`option-Selector-Drop ${selectedSubject === 'Chemistry' ? "chapterSelector" : ""} uniVersalTextRecord`}
        onClick={(e) => (setSelectedSubject("Chemistry"), handleNavClick("/chapter-selection", e, "Chemistry"))}

      >
       
        Chemistry
      </div>
      <div
        className={`option-Selector-Drop ${selectedSubject === 'Physics' ? "chapterSelector" : ""} uniVersalTextRecord`}
        onClick={(e) => (setSelectedSubject("Physics"), handleNavClick("/chapter-selection", e, "Physics"))}

      >
        Physics
      </div> */}
    </div>
  );
}

export default ChaptersDropNav;
