import { createSlice } from "@reduxjs/toolkit";

const initialState = {
    allChapters: []
}

const alLChapterListSlice = createSlice({
    name: 'currentChapter',
    initialState: initialState,
    reducers: {
        set: (state, action) => {
            state.allChapters = action.payload;
        },
    }
})

export default alLChapterListSlice.reducer
export const { set } = alLChapterListSlice.actions