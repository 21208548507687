import React from 'react'

const B4Mobile = "https://niopracticevideos.s3.ap-northeast-1.amazonaws.com/NioPractice_B3_Mob.gif"
const B4 = "https://niopracticevideos.s3.ap-northeast-1.amazonaws.com/NioPractice_B3_Web.gif"

function SectionThree() {
    return (
        <div className="section-three">
            <div className='section-three__img-bg app-img-border-rotate'>

                <img className="section-three__img hide-small imageRecordSectTen" src={B4} alt="landing-img-4" />
                <img className="section-three__img hide-big" src={B4Mobile} alt="landing-img-4" />

            </div>
            <div className="section-three__container">
                <div className='widthNumberification unFoldingErosion' style={{ padding: " 0.15em 0.6em" }}>

                    3
                </div>
                <h2 className='section-three__title' style={{ color: "#2F99D7" }}>  Finish practice sessions quickly and easily </h2>
                <p className="section-three__sub-title">
                    Skip calculations and fly through practice sets
                </p>
            </div>
        </div>
    )
}

export default SectionThree