import axios from 'axios'

const addpremiumaccess = async (user, chapterData) => {
    const apiUrl = `https://ohu6hwihm9.execute-api.ap-northeast-1.amazonaws.com/addPremiumAccess/addpremiumaccess?UserID=${user}&PaymetOptions=Credits&ChapterID=${chapterData.ChapterID}`

    const options = {
        headers: {
            "Content-Type": "application/x-www-form-urlencoded",
        },
    };

    try {
        const response = await axios.get(apiUrl, options);
        return response
    } catch {
        throw new Error("An error occurred during the API request.");
    }

}

export default addpremiumaccess