import React from "react";

export const coachingInstitutes = [
  {
    id: 1,
    Name: "Nioclass",
    Logo: "https://niopracticevideos.s3.ap-northeast-1.amazonaws.com/Web/SSC/Nioclass_Logo.png",
    DisplayedText: "Nioclass",
    examValid: "ssc,cat",
  },
  {
    id: 2,
    Name: "Adda24-7",
    Logo: "https://niopracticevideos.s3.ap-northeast-1.amazonaws.com/Web/SSC/Adda24-7_Logo.png",
    DisplayedText: "Adda24-7",
    examValid: "ssc",
  },
  {
    id: 3,
    Name: "CareerWill",
    Logo: "https://niopracticevideos.s3.ap-northeast-1.amazonaws.com/Web/SSC/CareerWill_Logo.png",
    DisplayedText: "CareerWill",
    examValid: "ssc",
  },
  {
    id: 4,
    Name: "Lucent",
    Logo: "https://niopracticevideos.s3.ap-northeast-1.amazonaws.com/Web/SSC/Lucent_Logo.png",
    DisplayedText: "Lucent",
    examValid: "ssc",
  },
  {
    id: 5,
    Name: "KDCampus",
    Logo: "https://niopracticevideos.s3.ap-northeast-1.amazonaws.com/Web/SSC/KDCampus_Logo.png",
    DisplayedText: "KDCampus",
    examValid: "ssc",
  },

  {
    id: 6,
    Name: "PACE",
    Logo: "https://niopracticevideos.s3.ap-northeast-1.amazonaws.com/Web/SSC/PACE_Logo.png",
    DisplayedText: "PACE",
    examValid: "ssc",
  },
  {
    id: 7,
    Name: "Paramount",
    Logo: "https://niopracticevideos.s3.ap-northeast-1.amazonaws.com/Web/SSC/Paramount_Logo.png",
    DisplayedText: "Paramount",
    examValid: "ssc",
  },
  {
    id: 8,
    Name: "ParmarAcademy",
    Logo: "https://niopracticevideos.s3.ap-northeast-1.amazonaws.com/Web/SSC/ParmarAcademy_Logo.png",
    DisplayedText: "ParmarAcademy",
    examValid: "ssc",
  },
  {
    id: 9,
    Name: "Allen",
    Logo: "https://niopracticevideos.s3.ap-northeast-1.amazonaws.com/Web/IIT-JEE/ALLEN_Logo.png",
    DisplayedText: "Allen",
    examValid: "iit",
  },
  {
    id: 10,
    Name: "BansalClasses",
    Logo: "https://niopracticevideos.s3.ap-northeast-1.amazonaws.com/Web/IIT-JEE/BansalClasses_Logo.png",
    DisplayedText: "BansalClasses",
    examValid: "iit",
  },
  {
    id: 11,
    Name: "FIIT-JEE",
    Logo: "https://niopracticevideos.s3.ap-northeast-1.amazonaws.com/Web/IIT-JEE/FIIT-JEE_Logo.png",
    DisplayedText: "FIIT-JEE",
    examValid: "iit",
  },
  {
    id: 12,
    Name: "InfinityLearn",
    Logo: "https://niopracticevideos.s3.ap-northeast-1.amazonaws.com/Web/IIT-JEE/InfinityLearn_Logo.png",
    DisplayedText: "InfinityLearn",
    examValid: "iit",
  },
  {
    id: 13,
    Name: "PhysicsWallah",
    Logo: "https://niopracticevideos.s3.ap-northeast-1.amazonaws.com/Web/IIT-JEE/PhysicsWallahLogo.png",
    DisplayedText: "PhysicsWallah",
    examValid: "iit",
  },
  {
    id: 14,
    Name: "Resonance",
    Logo: "https://niopracticevideos.s3.ap-northeast-1.amazonaws.com/Web/IIT-JEE/Resonance_Logo.png",
    DisplayedText: "Resonance",
    examValid: "iit",
  },
  {
    id: 15,
    Name: "Unacademy",
    Logo: "https://niopracticevideos.s3.ap-northeast-1.amazonaws.com/Web/SSC/Unacademy_Logo.png",
    DisplayedText: "Unacademy",
    examValid: "ssc,iit",
  },

  {
    id: 16,
    Name: "2IIM",
    Logo: "https://niopracticevideos.s3.ap-northeast-1.amazonaws.com/Web/CAT/2IIM_Logo.png",
    DisplayedText: "2IIM",
    examValid: "cat",
  },
  {
    id: 17,
    Name: "Career Launcher",
    Logo: "https://niopracticevideos.s3.ap-northeast-1.amazonaws.com/Web/CAT/CareerLauncher_Logo.png",
    DisplayedText: "Career Launcher",
    examValid: "cat",
  },
  {
    id: 18,
    Name: "CATKing",
    Logo: "https://niopracticevideos.s3.ap-northeast-1.amazonaws.com/Web/CAT/CATKing_Logo.png",
    DisplayedText: "CATKing",
    examValid: "cat",
  },
  {
    id: 19,
    Name: "Cracku",
    Logo: "https://niopracticevideos.s3.ap-northeast-1.amazonaws.com/Web/CAT/Cracku_Logo.png",
    DisplayedText: "Cracku",
    examValid: "cat",
  },
  {
    id: 20,
    Name: "IMS Learning",
    Logo: "https://niopracticevideos.s3.ap-northeast-1.amazonaws.com/Web/CAT/IMS_Learning_Logo.png",
    DisplayedText: "IMS Learning",
    examValid: "cat",
  },
  {
    id: 21,
    Name: "MBAGuru",
    Logo: "https://niopracticevideos.s3.ap-northeast-1.amazonaws.com/Web/CAT/MBAGuru_Logo.png",
    DisplayedText: "MBAGuru",
    examValid: "cat",
  },
  {
    id: 22,
    Name: "Time",
    Logo: "https://niopracticevideos.s3.ap-northeast-1.amazonaws.com/Web/CAT/Time_Logo.png",
    DisplayedText: "Time",
    examValid: "cat",
  },
  {
    id: 23,
    Name: "MIndWorkzz",
    Logo: "https://niopracticevideos.s3.ap-northeast-1.amazonaws.com/Web/CAT/MIndWorkzz_Logo.png",
    DisplayedText: "MIndWorkzz",
    examValid: "cat",
  },

  {
    id: 24,
    Name: "Others",
    Logo: "https://niopracticevideos.s3.ap-northeast-1.amazonaws.com/Web/SSC/Others_Logo.png",
    DisplayedText: "Others",
    examValid: "ssc,iit,cat",
  },
];

// object of these images : https://niopracticevideos.s3.ap-northeast-1.amazonaws.com/Web/SSC/Adda24-7_Logo.png , https://niopracticevideos.s3.ap-northeast-1.amazonaws.com/Web/SSC/KDCampus_Logo.png, https://niopracticevideos.s3.ap-northeast-1.amazonaws.com/Web/SSC/CareerWill_Logo.png, https://niopracticevideos.s3.ap-northeast-1.amazonaws.com/Web/SSC/Lucent_Logo.png, https://niopracticevideos.s3.ap-northeast-1.amazonaws.com/Web/SSC/Nioclass_Logo.png,https://niopracticevideos.s3.ap-northeast-1.amazonaws.com/Web/SSC/Others_Logo.png, https://niopracticevideos.s3.ap-northeast-1.amazonaws.com/Web/SSC/PACE_Logo.png, https://niopracticevideos.s3.ap-northeast-1.amazonaws.com/Web/SSC/Paramount_Logo.png, https://niopracticevideos.s3.ap-northeast-1.amazonaws.com/Web/SSC/ParmarAcademy_Logo.png, https://niopracticevideos.s3.ap-northeast-1.amazonaws.com/Web/SSC/Unacademy_Logo.png
let InstituteData = [
  {
    id: 1,
    Name: "Nioclass",
    Logo: "https://niopracticevideos.s3.ap-northeast-1.amazonaws.com/Web/SSC/Nioclass_Logo.png",
    DisplayedText: "Nioclass",
    examValid: "ssc,cat",
  },
  {
    id: 2,
    Name: "Adda24-7",
    Logo: "https://niopracticevideos.s3.ap-northeast-1.amazonaws.com/Web/SSC/Adda24-7_Logo.png",
    DisplayedText: "Adda24-7",
    examValid: "ssc",
  },
  {
    id: 3,
    Name: "CareerWill",
    Logo: "https://niopracticevideos.s3.ap-northeast-1.amazonaws.com/Web/SSC/CareerWill_Logo.png",
    DisplayedText: "CareerWill",
    examValid: "ssc",
  },
  {
    id: 4,
    Name: "Lucent",
    Logo: "https://niopracticevideos.s3.ap-northeast-1.amazonaws.com/Web/SSC/Lucent_Logo.png",
    DisplayedText: "Lucent",
    examValid: "ssc",
  },
  {
    id: 5,
    Name: "KDCampus",
    Logo: "https://niopracticevideos.s3.ap-northeast-1.amazonaws.com/Web/SSC/KDCampus_Logo.png",
    DisplayedText: "KDCampus",
    examValid: "ssc",
  },

  {
    id: 6,
    Name: "PACE",
    Logo: "https://niopracticevideos.s3.ap-northeast-1.amazonaws.com/Web/SSC/PACE_Logo.png",
    DisplayedText: "PACE",
    examValid: "ssc",
  },
  {
    id: 7,
    Name: "Paramount",
    Logo: "https://niopracticevideos.s3.ap-northeast-1.amazonaws.com/Web/SSC/Paramount_Logo.png",
    DisplayedText: "Paramount",
    examValid: "ssc",
  },
  {
    id: 8,
    Name: "ParmarAcademy",
    Logo: "https://niopracticevideos.s3.ap-northeast-1.amazonaws.com/Web/SSC/ParmarAcademy_Logo.png",
    DisplayedText: "ParmarAcademy",
    examValid: "ssc",
  },
  {
    id: 9,
    Name: "Allen",
    Logo: "https://niopracticevideos.s3.ap-northeast-1.amazonaws.com/Web/IIT-JEE/ALLEN_Logo.png",
    DisplayedText: "Allen",
    examValid: "iit",
  },
  {
    id: 10,
    Name: "BansalClasses",
    Logo: "https://niopracticevideos.s3.ap-northeast-1.amazonaws.com/Web/IIT-JEE/BansalClasses_Logo.png",
    DisplayedText: "BansalClasses",
    examValid: "iit",
  },
  {
    id: 11,
    Name: "FIIT-JEE",
    Logo: "https://niopracticevideos.s3.ap-northeast-1.amazonaws.com/Web/IIT-JEE/FIIT-JEE_Logo.png",
    DisplayedText: "FIIT-JEE",
    examValid: "iit",
  },
  {
    id: 12,
    Name: "InfinityLearn",
    Logo: "https://niopracticevideos.s3.ap-northeast-1.amazonaws.com/Web/IIT-JEE/InfinityLearn_Logo.png",
    DisplayedText: "InfinityLearn",
    examValid: "iit",
  },
  {
    id: 13,
    Name: "PhysicsWallah",
    Logo: "https://niopracticevideos.s3.ap-northeast-1.amazonaws.com/Web/IIT-JEE/PhysicsWallahLogo.png",
    DisplayedText: "PhysicsWallah",
    examValid: "iit",
  },
  {
    id: 14,
    Name: "Resonance",
    Logo: "https://niopracticevideos.s3.ap-northeast-1.amazonaws.com/Web/IIT-JEE/Resonance_Logo.png",
    DisplayedText: "Resonance",
    examValid: "iit",
  },
  {
    id: 15,
    Name: "Unacademy",
    Logo: "https://niopracticevideos.s3.ap-northeast-1.amazonaws.com/Web/SSC/Unacademy_Logo.png",
    DisplayedText: "Unacademy",
    examValid: "ssc,iit",
  },

  {
    id: 16,
    Name: "2IIM",
    Logo: "https://niopracticevideos.s3.ap-northeast-1.amazonaws.com/Web/CAT/2IIM_Logo.png",
    DisplayedText: "2IIM",
    examValid: "cat",
  },
  {
    id: 17,
    Name: "Career Launcher",
    Logo: "https://niopracticevideos.s3.ap-northeast-1.amazonaws.com/Web/CAT/CareerLauncher_Logo.png",
    DisplayedText: "Career Launcher",
    examValid: "cat",
  },
  {
    id: 18,
    Name: "CATKing",
    Logo: "https://niopracticevideos.s3.ap-northeast-1.amazonaws.com/Web/CAT/CATKing_Logo.png",
    DisplayedText: "CATKing",
    examValid: "cat",
  },
  {
    id: 19,
    Name: "Cracku",
    Logo: "https://niopracticevideos.s3.ap-northeast-1.amazonaws.com/Web/CAT/Cracku_Logo.png",
    DisplayedText: "Cracku",
    examValid: "cat",
  },
  {
    id: 20,
    Name: "IMS Learning",
    Logo: "https://niopracticevideos.s3.ap-northeast-1.amazonaws.com/Web/CAT/IMS_Learning_Logo.png",
    DisplayedText: "IMS Learning",
    examValid: "cat",
  },
  {
    id: 21,
    Name: "MBAGuru",
    Logo: "https://niopracticevideos.s3.ap-northeast-1.amazonaws.com/Web/CAT/MBAGuru_Logo.png",
    DisplayedText: "MBAGuru",
    examValid: "cat",
  },
  {
    id: 22,
    Name: "Time",
    Logo: "https://niopracticevideos.s3.ap-northeast-1.amazonaws.com/Web/CAT/Time_Logo.png",
    DisplayedText: "Time",
    examValid: "cat",
  },
  {
    id: 23,
    Name: "MIndWorkzz",
    Logo: "https://niopracticevideos.s3.ap-northeast-1.amazonaws.com/Web/CAT/MIndWorkzz_Logo.png",
    DisplayedText: "MIndWorkzz",
    examValid: "cat",
  },

  {
    id: 24,
    Name: "Others",
    Logo: "https://niopracticevideos.s3.ap-northeast-1.amazonaws.com/Web/SSC/Others_Logo.png",
    DisplayedText: "Others",
    examValid: "ssc,iit,cat",
  },
];

//object these images : https://niopracticevideos.s3.ap-northeast-1.amazonaws.com/Web/IIT-JEE/ALLEN_Logo.png, https://niopracticevideos.s3.ap-northeast-1.amazonaws.com/Web/IIT-JEE/BansalClasses_Logo.png, https://niopracticevideos.s3.ap-northeast-1.amazonaws.com/Web/IIT-JEE/FIIT-JEE_Logo.png, https://niopracticevideos.s3.ap-northeast-1.amazonaws.com/Web/IIT-JEE/InfinityLearn_Logo.png, https://niopracticevideos.s3.ap-northeast-1.amazonaws.com/Web/IIT-JEE/Others_Logo.png, https://niopracticevideos.s3.ap-northeast-1.amazonaws.com/Web/IIT-JEE/PhysicsWallahLogo.png, https://niopracticevideos.s3.ap-northeast-1.amazonaws.com/Web/IIT-JEE/Resonance_Logo.png, https://niopracticevideos.s3.ap-northeast-1.amazonaws.com/Web/IIT-JEE/UnacademyLogo.png
const iit = [
  {
    id: 1,
    Name: "Allen",
    Logo: "https://niopracticevideos.s3.ap-northeast-1.amazonaws.com/Web/IIT-JEE/ALLEN_Logo.png",
    DisplayedText: "Allen",
    examValid: "iit",
  },
  {
    id: 2,
    Name: "BansalClasses",
    Logo: "https://niopracticevideos.s3.ap-northeast-1.amazonaws.com/Web/IIT-JEE/BansalClasses_Logo.png",
    DisplayedText: "BansalClasses",
    examValid: "iit",
  },
  {
    id: 3,
    Name: "FIIT-JEE",
    Logo: "https://niopracticevideos.s3.ap-northeast-1.amazonaws.com/Web/IIT-JEE/FIIT-JEE_Logo.png",
    DisplayedText: "FIIT-JEE",
    examValid: "iit",
  },
  {
    id: 4,
    Name: "InfinityLearn",
    Logo: "https://niopracticevideos.s3.ap-northeast-1.amazonaws.com/Web/IIT-JEE/InfinityLearn_Logo.png",
    DisplayedText: "InfinityLearn",
    examValid: "iit",
  },
  {
    id: 5,
    Name: "PhysicsWallah",
    Logo: "https://niopracticevideos.s3.ap-northeast-1.amazonaws.com/Web/IIT-JEE/PhysicsWallahLogo.png",
    DisplayedText: "PhysicsWallah",
    examValid: "iit",
  },
  {
    id: 6,
    Name: "Resonance",
    Logo: "https://niopracticevideos.s3.ap-northeast-1.amazonaws.com/Web/IIT-JEE/Resonance_Logo.png",
    DisplayedText: "Resonance",
    examValid: "iit",
  },
  {
    id: 7,
    Name: "Unacademy",
    Logo: "https://niopracticevideos.s3.ap-northeast-1.amazonaws.com/Web/IIT-JEE/Unacademy.png",
    DisplayedText: "Unacademy",
    examValid: "iit",
  },
];

//object these images : https://niopracticevideos.s3.ap-northeast-1.amazonaws.com/Web/CAT/2IIM_Logo.png, https://niopracticevideos.s3.ap-northeast-1.amazonaws.com/Web/CAT/CareerLauncher_Logo.png, https://niopracticevideos.s3.ap-northeast-1.amazonaws.com/Web/CAT/CATKing_Logo.png, https://niopracticevideos.s3.ap-northeast-1.amazonaws.com/Web/CAT/Cracku_Logo.png, https://niopracticevideos.s3.ap-northeast-1.amazonaws.com/Web/CAT/IMS_Learning_Logo.png, https://niopracticevideos.s3.ap-northeast-1.amazonaws.com/Web/CAT/MBAGuru_Logo.png, https://niopracticevideos.s3.ap-northeast-1.amazonaws.com/Web/CAT/MIndWorkzz_Logo.png, https://niopracticevideos.s3.ap-northeast-1.amazonaws.com/Web/CAT/Nioclass_Logo.png, https://niopracticevideos.s3.ap-northeast-1.amazonaws.com/Web/CAT/Others_Logo.png, https://niopracticevideos.s3.ap-northeast-1.amazonaws.com/Web/CAT/TIME_Logo.png

const cat = [
  {
    id: 1,
    Name: "2IIM",
    Logo: "https://niopracticevideos.s3.ap-northeast-1.amazonaws.com/Web/CAT/2IIM_Logo.png",
    DisplayedText: "2IIM",
    examValid: "cat",
  },
  {
    id: 2,
    Name: "Career Launcher",
    Logo: "https://niopracticevideos.s3.ap-northeast-1.amazonaws.com/Web/CAT/CareerLauncher_Logo.png",
    DisplayedText: "Career Launcher",
    examValid: "cat",
  },
  {
    id: 3,
    Name: "CATKing",
    Logo: "https://niopracticevideos.s3.ap-northeast-1.amazonaws.com/Web/CAT/CATKing_Logo.png",
    DisplayedText: "CATKing",
    examValid: "cat",
  },
  {
    id: 4,
    Name: "Cracku",
    Logo: "https://niopracticevideos.s3.ap-northeast-1.amazonaws.com/Web/CAT/Cracku_Logo.png",
    DisplayedText: "Cracku",
    examValid: "cat",
  },
  {
    id: 5,
    Name: "IMS Learning",
    Logo: "https://niopracticevideos.s3.ap-northeast-1.amazonaws.com/Web/CAT/IMS_Learning_Logo.png",
    DisplayedText: "IMS Learning",
    examValid: "cat",
  },
  {
    id: 6,
    Name: "MBAGuru",
    Logo: "https://niopracticevideos.s3.ap-northeast-1.amazonaws.com/Web/CAT/MBAGuru_Logo.png",
    DisplayedText: "MBAGuru",
    examValid: "cat",
  },
  {
    id: 7,
    Name: "Time",
    Logo: "https://niopracticevideos.s3.ap-northeast-1.amazonaws.com/Web/CAT/Time_Logo.png",
    DisplayedText: "Time",
    examValid: "cat",
  },
  {
    id: 8,
    Name: "MIndWorkzz",
    Logo: "https://niopracticevideos.s3.ap-northeast-1.amazonaws.com/Web/CAT/MIndWorkzz_Logo.png",
    DisplayedText: "MIndWorkzz",
    examValid: "cat",
  },
];
function AllCoachingInsttitues() {
  return <div>AllCoachingInsttitues</div>;
}

export default AllCoachingInsttitues;
