import React, { useEffect, useState } from 'react';
import SectionOne from './SectionOne';
import SectionTwo from './SectionTwo';
import SectionThree from './SectionThree';
// import './ScrollingAnimationComponent.css'; // Import your CSS file for styling
import { useParallax } from 'react-scroll-parallax';

function ScrollingAnimationComponent() {
    const [activeSection, setActiveSection] = useState(1);
    // console.log(activeSection)
    const parallax = useParallax({
        speed: 500,
        // startScroll: 1400,
        // endScroll: 1700,
        startScroll: 1150,
        endScroll: 1500,
        translateY: [0, 10, 'easeInOut'],
        scale: [1.9, 1, 'easeInOut'],
        opacity: [0, 1, 'easeInOut'],
        shouldAlwaysCompleteAnimation: true,


    });
    const parallaxw = useParallax({
        speed: 0,
        // startScroll: 1900,
        // endScroll: 2300,
        startScroll: 1500,
        endScroll: 1700,
        translateY: [0, 70, 'easeInOut'],
        scale: [1.9, 1, 'easeInOut'],
        opacity: [0, 1, 'easeInOut'],
    });
    const parallaxq = useParallax({
        speed: -100,
        startScroll: 2200,
        endScroll: 2500,
        translateY: [0, 60, 'easeInOut'],
        scale: [1.9, 1, 'easeInOut'],
        opacity: [0, 1, 'easeInOut'],
    });
    // console.log(parallax.element?.progress > 500 && parallax.element?.progress < 600 && true)
    // console.log(parallax.element?.progress === 1)
    useEffect(() => {
        const handleScroll = (event) => {
            const scrollingElement = document.scrollingElement || document.documentElement;
            const scrollPosition = scrollingElement.scrollTop;
            if (event.deltaY > 0 && scrollPosition < 1850) {
                setActiveSection(0);
            }
            else if (event.deltaY > 0 && scrollPosition >= 1850 && scrollPosition < 1950) {
                // scrollToPosition(2250);
                setActiveSection(1);
            } else if (event.deltaY > 0 && scrollPosition >= 2300 && scrollPosition < 2400) {
                // scrollToPosition(2950);
                setActiveSection(2);
            }
        };

        const scrollToPosition = (position) => {
            window.scrollTo({
                top: position,
                behavior: 'smooth',
            });
        };

        window.addEventListener('wheel', handleScroll);
        return () => {
            window.removeEventListener('wheel', handleScroll);
        };
    }, []);

    return (
        <div className="MagicScroll unFoldingErosionRev" data-options="mode: carousel; height: 275px;">
            {/* <img src="example1.jpg">
    <img src="example2.jpg">
    ... */}
            <div className='ComponentScrollingSectionAnimation'>
                <div ref={parallax.ref} className='ComponentScrollingSectionAnimationContainer scrollingComponentMarginsLanidng1'>
                    <SectionOne active={activeSection === 1} />


                </div>
                <div ref={parallaxw.ref} className='ComponentScrollingSectionAnimationContainer scrollingComponentMarginsLanidng2'>
                    <SectionTwo active={activeSection === 2} />
                </div>
                <div ref={parallaxq.ref} className='ComponentScrollingSectionAnimationContainer scrollingComponentMarginsLanidng3' >
                    <SectionThree active={activeSection === 3} />
                </div>
            </div>
        </div>
    );
}

export default ScrollingAnimationComponent;
