import React from 'react'
const B3 = "https://niopracticevideos.s3.ap-northeast-1.amazonaws.com/NioPractice_B2_Web.gif"
const B3Mobile = "https://niopracticevideos.s3.ap-northeast-1.amazonaws.com/NioPractice_B2_Mob.gif"

function SectionTwo() {
    return (
        <div className="section-two" >
            <div className='section-two__img-bg app-img-border-rotate'>

                <img className='section-two__img hide-small imageRecordSectTen' src={B3} alt="landing-img-3" />
                <img className='section-two__img hide-big' src={B3Mobile} alt="landing-img-3" />

            </div>
            <div className="section-two__container">
                <div className='widthNumberification unFoldingErosion' style={{ padding: " 0.15em 0.6em" }}>

                    2
                </div>
                <h2 className='section-two__title' style={{ color: "#2F99D7" }}>Recall formulas and approaches effortlessly </h2>
                <p className="section-two__sub-title">
                    Easily remember approaches and formulas even months after learning using AI-based memory tracking.
                </p>
            </div>
        </div>
    )
}

export default SectionTwo