import React, { useState, useEffect } from 'react';
import axios from 'axios';

function useHandleMessage() {

    const [loading, setLoading] = useState()
    const [error, setError] = useState()
    const [data, setData] = useState('')

    useEffect(() => {

    }, [])

    const handleMessage = async (msg) => {

        let result;

        const url = `https://26vrbrvtwa.execute-api.ap-northeast-1.amazonaws.com/chatbot?input_text=${msg}`

        try {
            setLoading(true)
            const res = await axios.get(url)
            setData(res.data)
            setLoading(false)
            result = res.data;
        }
        catch (err) {
            setError(err)
            console.log(err)
        }

        return result;

    }

    return { handleMessage, data, loading, error }
}

export default useHandleMessage;