import React, { useState } from 'react'
import { arrowDown } from '../../assets/icons'

export default function SectionElevenFunction({ obj }) {
    const [dropDownClicked, setDropDownClicked] = useState(false)

    const handleMouseLeave = () => {
        setDropDownClicked(!dropDownClicked);
    };

    return (
        <>
            <div className='sectionElevenMegaConatiner'>

                <div className='HeadingTextSection-eight FaqsSectionEleven' >
                    <div className='FaqsSectionElevenQuestion-container' onClick={handleMouseLeave}>
                        {obj.Question}
                        <img src={arrowDown} style={{
                            transform: dropDownClicked
                                ? "rotate(180deg)"
                                : "rotate(360deg)", transition: "transform 0.2s ease-in-out",
                        }} alt="dropdown-arrow" />
                    </div>

                </div>
                {dropDownClicked && <div className='HeadingTextSection-eight  SectionElevenAnswerTabConatiner'>
                    <div className='SectionElevenAnswerTabConatinerText'>

                        {obj.Answer}
                    </div>
                </div>}
            </div>
        </>
    )
}
