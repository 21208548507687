import { createContext, useContext } from "react";
import { getFirestore } from "@firebase/firestore";
import { initializeApp } from "firebase/app";
import { getAuth, FacebookAuthProvider, GoogleAuthProvider } from "firebase/auth";
import { getAnalytics, setUserProperties, setUserId } from "firebase/analytics";

import useCurrentUser from "../redux/control/useCurrentUser";

const firebaseConfig = {
    // // my key
    // apiKey: "AIzaSyByhAOE_4QBgjY1tnTbcCK6LO_UDmV3jeQ",
    // authDomain: "fir-auth-c2f1d.firebaseapp.com",
    // projectId: "fir-auth-c2f1d",
    // storageBucket: "fir-auth-c2f1d.appspot.com",
    // messagingSenderId: "146373223277",
    // appId: "1:146373223277:web:b77c2537d8671953913064",
    // measurementId: "G-ZQZRDSYNK4",

    // ---minmain
    // apiKey: process.env.REACT_APP_API_KEY,
    // authDomain: process.env.REACT_APP_AUTH_DOMAIN,
    // projectId: process.env.REACT_APP_PROJECT_ID,
    // storageBucket: process.env.REACT_APP_STORAGE_BUCKET,
    // messagingSenderId: process.env.REACT_APP_MESSAGING_SENDERID,
    // appId:  process.env.REACT_APP_APP_ID,
    // measurementId:  process.env.REACT_APP_MEASUREMENT_ID,

    //  your key
    // AIzaSyC5NLmGt0_-jl9c5v8iYlQb55NJdUYUNp8
    // apiKey: "AIzaSyC5w7EqUryLOibFe3haofb5VQ8fvcCRL-I",
    // authDomain: "project-807036436705.firebaseapp.com",
    // projectId: "project-807036436705",
    // storageBucket: "project-807036436705.appspot.com",
    // messagingSenderId: "807036436705",
    // appId: "1:807036436705:android:5d34aa3ac9ee83689077a0",
    // measurementId: "G-VBDVQQVPNQ",

    apiKey: "AIzaSyC5NLmGt0_-jl9c5v8iYlQb55NJdUYUNp8",
    authDomain: "nioclass-4073f.firebaseapp.com",
    projectId: "nioclass-4073f",
    storageBucket: "nioclass-4073f.appspot.com",
    messagingSenderId: "378679538824",
    appId: "1:378679538824:web:2fc9481d98afe484629b3d",
    // measurementId: "G-YKFHHTS584",
    measurementId: "G-VBDVQQVPNQ",

    // apiKey: "AIzaSyA23hQ_9UopzdDrnuqCZ6q_bbxGKLgj9Rw",
    // authDomain: "nioptc.firebaseapp.com",
    // projectId: "nioptc",
    // storageBucket: "nioptc.appspot.com",
    // messagingSenderId: "336196148955",
    // appId: "1:336196148955:web:d22a6deeda391d5ef976fe",
    // measurementId: "G-7JRTNHRP2R"
};

export const FirebaseContext = createContext();

export const getFbAuth = () => {
    const { auth } = useContext(FirebaseContext);
    return auth;
}


const app = initializeApp(firebaseConfig);
export const authUser = getAuth(app);
export const fbProvider = new FacebookAuthProvider();
export const googleProvider = new GoogleAuthProvider();

const FirebaseContextProvider = ({ children }) => {

    const app = initializeApp(firebaseConfig);
    const auth = getAuth(app);
    const db = getFirestore(app);
    // console.log(app)
    const analytics = getAnalytics(app);

    if (auth?.currentUser) {

        setUserProperties(analytics, { user_id: auth?.currentUser?.uid });
        auth.onAuthStateChanged((user) => {
            // console.log(user)
            if (user) {
                // Get the user ID.
                const userId = user.uid;

                // Set the user ID in GA4.
                // console.log(userId, analytics)
                setUserId(analytics, userId);
            }
        });
    }


    // const [auth, setAuth] = useState()

    // useEffect(() => {
    //     setAuth()
    // }, [authFb])

    return (
        <FirebaseContext.Provider value={{ app, auth, db }}>
            {children}
        </FirebaseContext.Provider>
    );
};

export default FirebaseContextProvider;
