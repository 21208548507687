import axios from "axios";

export default async function createUser(
  userName,
  userID,
  phoneNumber,
  referred_by,
  utmCompaignD = null
) {
  try {
    // console.log("userName: ", userName);
    // console.log("userID: ", userID);
    await axios.get(
      "https://odmw7sdpnk.execute-api.ap-northeast-1.amazonaws.com/tutorialPromtDetails?UserID=" +
        userID,
      {
        headers: {
          "Content-Type": "application/x-www-form-urlencoded",
        },
      }
    );

    await axios.get(
      "https://o33ra6381d.execute-api.ap-northeast-1.amazonaws.com/addNewUserIDToUserProfile/addnewuseridtouserprofile?UserID=" +
        userID +
        "&RefferedBy=" +
        (referred_by ? referred_by : "None") +
        "&utmCompaignD=" +
        utmCompaignD +
        "&Platform=" +
        window.location.href,
      {
        headers: {
          "Content-Type": "application/x-www-form-urlencoded",
        },
      }
    );

    await axios.get(
      "https://1edof059ii.execute-api.ap-northeast-1.amazonaws.com/setUserName/setusername?UserID=" +
        userID +
        "&Name=" +
        userName,
      {
        headers: {
          "Content-Type": "application/x-www-form-urlencoded",
        },
      }
    );

    if (!userID.includes("@") && userID) {
      await axios.get(
        "https://ni7u05base.execute-api.ap-northeast-1.amazonaws.com/setPhoneNumber/setphonenumber?UserID=" +
          userID +
          "&PhoneNumber=" +
          userID,
        {
          headers: {
            "Content-Type": "application/x-www-form-urlencoded",
          },
        }
      );
    }
    if (userID.includes("@") && phoneNumber && phoneNumber.length > 9) {
      await axios.get(
        "https://ni7u05base.execute-api.ap-northeast-1.amazonaws.com/setPhoneNumber/setphonenumber?UserID=" +
          userID +
          "&PhoneNumber=" +
          phoneNumber,
        {
          headers: {
            "Content-Type": "application/x-www-form-urlencoded",
          },
        }
      );
    }
  } catch (e) {
    console.log("caught err" + e.message);
  }
}
