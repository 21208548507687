import axios from "axios";


export const HistoryTrackRecordDash = async (userId, limit, OFFSET, ChapterID, ExamType = "IIT") => {
    const apiUrl = `https://kigqkh7zi0.execute-api.ap-northeast-1.amazonaws.com/HistoryQuestionTrack?UserID=${userId}&ChapterID=${ChapterID}&limit=${limit}&OFFSET=${OFFSET}&ExamType=${ExamType}`
    // console.log(apiUrl)
    const res = await axios.get(apiUrl)

    const data = res.data
    // console.log(data)

    return data
}

export default HistoryTrackRecordDash