// userId - user is, this is only for logged in users
// action - 'button click' 'search' 'login' 'singup' 'logout' 
// target - element name or element id
// timestamp - time stamp
// location - pathname

// HOW TO USE?
// call the custom function by passing userId
// the custom hook return a function - recordActivity, which can be called by
// passing two arguments - action, target

// action - 'click' 'search' 'write' 'login' 'singup' 'logout' 
// the target - event.target.value

const useRecordActivity = (userId) => {

    if (!userId) return () => { }

    const localPath = location.pathname
    // const timestamp = new Date();

    const recordActivity = (action, target, position = '') => {

        const activityRes = {
            userId, action, target,
            // timestamp, 
            localPath, position
        }

        // console.log("%c user act", 'color: yellow', activityRes);
    };

    return recordActivity;
};

export default useRecordActivity;

// import { getFbAuth } from "../../context/FirebaseContext";
// import useRecordActivity from '../../hooks/useRecordActivity';

// const authUser = getFbAuth().currentUser;
// const recordActivity = useRecordActivity(authUser?.phoneNumber)

// recordActivity('click', e.target.name, 'chapter search box')