import { configureStore } from "@reduxjs/toolkit";

import currentChapterReducer from "../slice/currentChapter";
import currentUserReducer from "../slice/currentUser";
import tutorialStepReducer from "../slice/tutorialSlice";
import selectedQuestionIDsReducer from "../slice/selectedQuestionIDs";
import timePauseReducer from "../slice/timePause";
import allChapterListReducer from "../slice/allChapterList";
import addActivitySymbolize from "../slice/addActivitySymbolize";
import userCredit from "../slice/userCreditSlice";
import signinPopup from "../slice/signinPopup";

const store = configureStore({
  reducer: {
    allChapterListStore: allChapterListReducer,
    currentChapterStore: currentChapterReducer,
    currentUserStore: currentUserReducer,
    tutorialStepStore: tutorialStepReducer,
    selectedQuestionIDsStore: selectedQuestionIDsReducer,
    timePauseStore: timePauseReducer,
    ActivityStore: addActivitySymbolize,
    userCreditStore: userCredit,
    signinModalOpenStore: signinPopup,
  },
});

export default store;
