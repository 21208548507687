import React, { useEffect } from 'react'
import { PopupContext } from '../../context/PopupStatusContext';
import { useContext } from 'react'
// import { setTime } from '../../redux/slice/timePause';

const NotesAdded = () => {
    const { notesAdded, setNotesAdded } = useContext(PopupContext);
    useEffect(() => {
        if (!notesAdded) return
        const timeout = setTimeout(() => {
            setNotesAdded(false);
        },3000);
        return () => clearTimeout(timeout);
    }, [notesAdded])
    return (
        <>
            {
                (notesAdded) && <div className='ntt'>
                    <div className='notifypopup'>
                        <svg width="32px" height="52px" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path d="M7.29417 12.9577L10.5048 16.1681L17.6729 9" stroke="#fff" strokeWidth="2.5" strokeLinecap="round" strokeLinejoin="round" />
                            <circle cx="12" cy="12" r="10" stroke="#fff" strokeWidth="2" />
                        </svg>
                        <div style={{ margin: "10px" }}>
                            <h1>Notes Added</h1>
                        </div>
                    </div>
                </div>
            }
        </>
    )
}

export default NotesAdded