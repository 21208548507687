import React from "react";
import useCredit from "../../redux/control/useCredit";
import "./styles/datapopupNavbar.css";
function DataPopupNavbar({
  handleCreditBtn,
  UserCredits,
  name,
  phoneNumber,
  handleNavClick,
}) {
  // const name = "NioUser"
  // const phoneNumber = 919876543210
  const { userCredit } = useCredit();
  return (
    <div className="dataPopupNavbarContainer">
      {/* <div className='circularNameData'>
                {name[0]}
            </div> */}
      <button
        className="profile__pic-container dataPopupNavbarCircular"
        name="btn-start-practicing"
      >
        <div className="profile__pic">{name[0]}</div>
      </button>
      <div className="profilePicDataPopup">
        <div className="namePopupProfile">{name}</div>
        <div className="phoneNumberPopupProfile">{phoneNumber}</div>
      </div>
      <div className="conatinerMAsterProfilePopup">
        <div
          className="boxContainainerProfileConatiner"
          onClick={(e) => handleNavClick("/my-profile", e)}
          style={{ borderRadius: " 6px 6px 0px 0px" }}
        >
          <div className="boxIcon">
            <svg
              width="24"
              height="24"
              viewBox="0 0 24 24"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M22 12C22 6.49 17.51 2 12 2C6.49 2 2 6.49 2 12C2 14.9 3.25 17.51 5.23 19.34C5.23 19.35 5.23 19.35 5.22 19.36C5.32 19.46 5.44 19.54 5.54 19.63C5.6 19.68 5.65 19.73 5.71 19.77C5.89 19.92 6.09 20.06 6.28 20.2C6.35 20.25 6.41 20.29 6.48 20.34C6.67 20.47 6.87 20.59 7.08 20.7C7.15 20.74 7.23 20.79 7.3 20.83C7.5 20.94 7.71 21.04 7.93 21.13C8.01 21.17 8.09 21.21 8.17 21.24C8.39 21.33 8.61 21.41 8.83 21.48C8.91 21.51 8.99 21.54 9.07 21.56C9.31 21.63 9.55 21.69 9.79 21.75C9.86 21.77 9.93 21.79 10.01 21.8C10.29 21.86 10.57 21.9 10.86 21.93C10.9 21.93 10.94 21.94 10.98 21.95C11.32 21.98 11.66 22 12 22C12.34 22 12.68 21.98 13.01 21.95C13.05 21.95 13.09 21.94 13.13 21.93C13.42 21.9 13.7 21.86 13.98 21.8C14.05 21.79 14.12 21.76 14.2 21.75C14.44 21.69 14.69 21.64 14.92 21.56C15 21.53 15.08 21.5 15.16 21.48C15.38 21.4 15.61 21.33 15.82 21.24C15.9 21.21 15.98 21.17 16.06 21.13C16.27 21.04 16.48 20.94 16.69 20.83C16.77 20.79 16.84 20.74 16.91 20.7C17.11 20.58 17.31 20.47 17.51 20.34C17.58 20.3 17.64 20.25 17.71 20.2C17.91 20.06 18.1 19.92 18.28 19.77C18.34 19.72 18.39 19.67 18.45 19.63C18.56 19.54 18.67 19.45 18.77 19.36C18.77 19.35 18.77 19.35 18.76 19.34C20.75 17.51 22 14.9 22 12ZM16.94 16.97C14.23 15.15 9.79 15.15 7.06 16.97C6.62 17.26 6.26 17.6 5.96 17.97C4.44 16.43 3.5 14.32 3.5 12C3.5 7.31 7.31 3.5 12 3.5C16.69 3.5 20.5 7.31 20.5 12C20.5 14.32 19.56 16.43 18.04 17.97C17.75 17.6 17.38 17.26 16.94 16.97Z"
                fill="#2F99D7"
              />
              <path
                d="M12 6.92969C9.93 6.92969 8.25 8.60969 8.25 10.6797C8.25 12.7097 9.84 14.3597 11.95 14.4197H12.04H12.11H12.13C14.15 14.3497 15.74 12.7097 15.75 10.6797C15.75 8.60969 14.07 6.92969 12 6.92969Z"
                fill="#2F99D7"
              />
            </svg>
          </div>
          <div className="boxIconContent">My Profile</div>
        </div>
        <div
          className="boxContainainerProfileConatiner"
          onClick={handleCreditBtn}
        >
          <div className="boxIcon">
            <svg
              width="24"
              height="24"
              viewBox="0 0 24 24"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M15.39 5.21125L16.8 8.03125C16.99 8.42125 17.5 8.79125 17.93 8.87125L20.48 9.29125C22.11 9.56125 22.49 10.7413 21.32 11.9213L19.33 13.9113C19 14.2413 18.81 14.8913 18.92 15.3613L19.49 17.8213C19.94 19.7613 18.9 20.5213 17.19 19.5013L14.8 18.0813C14.37 17.8213 13.65 17.8213 13.22 18.0813L10.83 19.5013C9.12 20.5113 8.08 19.7613 8.53 17.8213L9.1 15.3613C9.19 14.8813 9 14.2313 8.67 13.9013L6.68 11.9113C5.51 10.7413 5.89 9.56125 7.52 9.28125L10.07 8.86125C10.5 8.79125 11.01 8.41125 11.2 8.02125L12.61 5.20125C13.38 3.68125 14.62 3.68125 15.39 5.21125ZM8 5.75025H2C1.59 5.75025 1.25 5.41025 1.25 5.00025C1.25 4.59025 1.59 4.25025 2 4.25025H8C8.41 4.25025 8.75 4.59025 8.75 5.00025C8.75 5.41025 8.41 5.75025 8 5.75025ZM5 19.7503H2C1.59 19.7503 1.25 19.4103 1.25 19.0003C1.25 18.5903 1.59 18.2503 2 18.2503H5C5.41 18.2503 5.75 18.5903 5.75 19.0003C5.75 19.4103 5.41 19.7503 5 19.7503ZM3 12.7503H2C1.59 12.7503 1.25 12.4103 1.25 12.0003C1.25 11.5903 1.59 11.2503 2 11.2503H3C3.41 11.2503 3.75 11.5903 3.75 12.0003C3.75 12.4103 3.41 12.7503 3 12.7503Z"
                fill="#2F99D7"
              />
            </svg>
          </div>
          <div className="boxIconContent">
            Credits:
            <span style={{ color: "var(--accent-primary-clr)" }}>
              &nbsp; {userCredit < 0 ? 0 : userCredit}
            </span>
          </div>
        </div>
        <div
          className="boxContainainerProfileConatiner"
          onClick={(e) => handleNavClick("/dashboard", e)}
        >
          <div className="boxIcon">
            <svg
              width="24"
              height="24"
              viewBox="0 0 24 24"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M13 21V11H21V21H13ZM3 13V3H11V13H3ZM9 11V5H5V11H9ZM3 21V15H11V21H3ZM5 19H9V17H5V19ZM15 19H19V13H15V19ZM13 3H21V9H13V3ZM15 5V7H19V5H15Z"
                fill="#2F99D7"
              />
            </svg>
          </div>
          <div className="boxIconContent">View Dashboard</div>
        </div>
        <div
          className="boxContainainerProfileConatiner"
          onClick={(e) => handleNavClick("/contact-us", e)}
        >
          <div className="boxIcon">
            <svg
              width="24"
              height="24"
              viewBox="0 0 24 24"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M11.05 14.95L9.2 16.8C8.81 17.19 8.19 17.19 7.79 16.81C7.68 16.7 7.57 16.6 7.46 16.49C6.44877 15.472 5.5161 14.3789 4.67 13.22C3.85 12.08 3.19 10.94 2.71 9.81C2.24 8.67 2 7.58 2 6.54C2 5.86 2.12 5.21 2.36 4.61C2.6 4 2.98 3.44 3.51 2.94C4.15 2.31 4.85 2 5.59 2C5.87 2 6.15 2.06 6.4 2.18C6.66 2.3 6.89 2.48 7.07 2.74L9.39 6.01C9.57 6.26 9.7 6.49 9.79 6.71C9.88 6.92 9.93 7.13 9.93 7.32C9.93 7.56 9.86 7.8 9.72 8.03C9.59 8.26 9.4 8.5 9.16 8.74L8.4 9.53C8.29 9.64 8.24 9.77 8.24 9.93C8.24 10.01 8.25 10.08 8.27 10.16C8.3 10.24 8.33 10.3 8.35 10.36C8.53 10.69 8.84 11.12 9.28 11.64C9.73 12.16 10.21 12.69 10.73 13.22C10.83 13.32 10.94 13.42 11.04 13.52C11.44 13.91 11.45 14.55 11.05 14.95ZM21.97 18.33C21.9687 18.7074 21.8833 19.0798 21.72 19.42C21.55 19.78 21.33 20.12 21.04 20.44C20.55 20.98 20.01 21.37 19.4 21.62C19.39 21.62 19.38 21.63 19.37 21.63C18.78 21.87 18.14 22 17.45 22C16.43 22 15.34 21.76 14.19 21.27C13.04 20.78 11.89 20.12 10.75 19.29C10.36 19 9.97 18.71 9.6 18.4L12.87 15.13C13.15 15.34 13.4 15.5 13.61 15.61C13.66 15.63 13.72 15.66 13.79 15.69C13.87 15.72 13.95 15.73 14.04 15.73C14.21 15.73 14.34 15.67 14.45 15.56L15.21 14.81C15.46 14.56 15.7 14.37 15.93 14.25C16.16 14.11 16.39 14.04 16.64 14.04C16.83 14.04 17.03 14.08 17.25 14.17C17.47 14.26 17.7 14.39 17.95 14.56L21.26 16.91C21.52 17.09 21.7 17.3 21.81 17.55C21.91 17.8 21.97 18.05 21.97 18.33Z"
                fill="#2F99D7"
              />
            </svg>
          </div>
          <div className="boxIconContent">Contact Us</div>
        </div>
        <div
          className="boxContainainerProfileConatiner"
          onClick={(e) => handleNavClick("/website", e)}
          style={{ borderRadius: " 0px 0px 6px 6px" }}
        >
          <div className="boxIcon">
            <svg
              width="24"
              height="24"
              viewBox="0 0 24 24"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M21.3023 19.7984H20.3721V9.31463C20.3721 8.73788 20.1116 8.19835 19.6558 7.84486L18.5116 6.95183L18.493 4.67277C18.493 4.16114 18.0744 3.75183 17.5628 3.75183H14.3907L13.1442 2.78439C12.4744 2.25416 11.5256 2.25416 10.8558 2.78439L4.34419 7.84486C3.88837 8.19835 3.62791 8.73788 3.62791 9.30532L3.5814 19.7984H2.69767C2.31628 19.7984 2 20.1147 2 20.4961C2 20.8775 2.31628 21.1937 2.69767 21.1937H21.3023C21.6837 21.1937 22 20.8775 22 20.4961C22 20.1147 21.6837 19.7984 21.3023 19.7984ZM6.88372 11.8913V10.496C6.88372 9.98435 7.30233 9.56574 7.81395 9.56574H9.67442C10.186 9.56574 10.6047 9.98435 10.6047 10.496V11.8913C10.6047 12.403 10.186 12.8216 9.67442 12.8216H7.81395C7.30233 12.8216 6.88372 12.403 6.88372 11.8913ZM14.3256 19.7984H9.67442V17.2402C9.67442 16.4681 10.2977 15.8448 11.0698 15.8448H12.9302C13.7023 15.8448 14.3256 16.4681 14.3256 17.2402V19.7984ZM17.1163 11.8913C17.1163 12.403 16.6977 12.8216 16.186 12.8216H14.3256C13.814 12.8216 13.3953 12.403 13.3953 11.8913V10.496C13.3953 9.98435 13.814 9.56574 14.3256 9.56574H16.186C16.6977 9.56574 17.1163 9.98435 17.1163 10.496V11.8913Z"
                fill="#2F99D7"
              />
            </svg>
          </div>
          <div className="boxIconContent">
            Go to
            <span style={{ color: "#2F99D7" }}>&nbsp; Nio</span>
            <span style={{ color: "var(--accent-primary-clr)" }}>
              Practice
            </span>{" "}
            site
          </div>
        </div>
      </div>
      {/* DataPopupNavbar */}
    </div>
  );
}

export default DataPopupNavbar;
