import React from 'react'

import useToast from "../../components/toast/useToastNew"
import { getFbAuth } from "../../context/FirebaseContext";
import useRecordActivity from '../../hooks/useRecordActivity';
import useCredit from '../../redux/control/useCredit';

import { underScoreIcon, closeBtnIcon, copyIcon, copysuccess } from '../../assets/icons';

const TotalCredits = ({ userid, UserCredits, setUseCredits, setOpenCreditScore }) => {

	const authUser = getFbAuth().currentUser;
	const recordActivity = useRecordActivity(authUser?.phoneNumber)

	const {userCredit} = useCredit()
	const { Toast, setToast } = useToast()

	const handleCopyReferal = (e) => {
		navigator.clipboard.writeText(`${window.location.origin}/sign-in/${userid}`);
		recordActivity('click', e.target.name, 'credit modal')
		// console.log('Hit')
		setToast('Copied', { color: 'pink', iconUrl: copysuccess, timer: 1 })
	}

	const handleUnlock = (e) => {
		setOpenCreditScore(false);
		setUseCredits(true);

		const target = e.target.name
		recordActivity('click', target, 'credit modal')
	}

	return (
		<>
			<Toast />

			<div className="buyPremiumModalContainer credit">
				<div className="chapterPremiumPricingModal credit">
					<div className="selectedChapterPremiumInformationDiv credit">
						<div
							className="closebuttonForPremiumModal"
							onClick={() => setOpenCreditScore(false)}
						>
							<img src={closeBtnIcon} alt="" />
						</div>
						<div className="singleChapterTitleForPremium credit">
							Total Credits
						</div>
						<div className="gradiantLineForPremiumModal">
							{" "}
							<img src={underScoreIcon} alt="separatorIcon" />
						</div>
						<div className="chapterIconForPremiumModal credit">
							<p>{userCredit === -1 ? 0 : userCredit}</p>
						</div>
						<div className="PricingInformationForPremiumModal credit">
							<div className="">
								Referring gets you 1 Point. <br />
								1 Point can be redeemed to unlock a new chapter.
							</div>
						</div>
						<div className="creditScore__buttons">
							<button
								className="creditScore app-btn-1-reverse"
								name='btn-copy-refferal-link'
								onClick={handleCopyReferal}
							>
								Copy your referral Link
								<img src={copyIcon}></img>
							</button>
							<button
								className="creditScore app-btn-1"
								name='btn-unlock-new-chapter'
								onClick={handleUnlock}
							>
								Unlock a New Chapter

							</button>
						</div>
					</div>
				</div>
			</div>
		</>

	)
}

export default TotalCredits