import { useEffect, useRef } from 'react';

function useDelayedStateToggle(stateSetter, delay = 0.8) {

    const timeoutRef = useRef(null);

    useEffect(() => {
        return () => {
            clearTimeout(timeoutRef.current);
        };
    }, []);

    const toggleStateDelay = () => {
        if (window.innerWidth < 900) return

        clearTimeout(timeoutRef.current);
        timeoutRef.current = setTimeout(() => {
            stateSetter(prevState => !prevState);
        }, delay * 1000);
    };

    const toggleStateFalse = () => {
        if (window.innerWidth < 900) return

        clearTimeout(timeoutRef.current);
        stateSetter(false);
    };

    //auto false
    useEffect(() => {
        const timeout = setTimeout(() => {
            toggleStateFalse();
        }, 5000);

        return () => {
            clearTimeout(timeout);
        };
    }, [toggleStateFalse]);

    return [toggleStateDelay, toggleStateFalse];
}

export default useDelayedStateToggle;
