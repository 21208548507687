import { createSlice } from "@reduxjs/toolkit";

const initialState = {
    timerPause: false
    // selectedChapterId: ''
}

const timerPauseSlice = createSlice({
    name: 'currentChapter',
    initialState: initialState,
    reducers: {
        set: (state, action) => {
            state.timerPause = action.payload;
        },
        setTime: (state, action) => {
            // //console.log("___________________________", action)
            state.timerPause = action.payload;
        },
    }
})

export default timerPauseSlice.reducer
export const { set, setTime } = timerPauseSlice.actions